import React from "react";
import _ from "lodash";
import PlayIcon from "../../../assets/images/icon-circle-light-play-small@3x.png";
import { ItemWrapper, CategoryWrapper, CityWrapper } from "./shared";

const ListItem = ({ item }) => {
  const { title, name } = item;
  const imageUrl = _.get(item, "image_data.web_image");
  const videoData = _.get(item, "video_data");
  const city = _.chain(item)
    .get("area_data")
    .keys()
    .map(id => ({ id }))
    .first()
    .value();
  const category = _.chain(item)
    .get("category_data")
    .keys()
    .map(id => ({ id }))
    .first()
    .value();
  const styles = {
    container: {
      height: 80,
      width: 350,
      display: "flex",
      justifyContent: "flex-start",
      marginBottom: 10
    },
    imageContainer: {
      width: 80,
      height: 80,
      flexShrink: 0
    },
    image: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: 4,
      overflow: "hidden"
    },
    playIconContainer: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      height: "100%",
      position: "absolute",
      left: 0,
      top: 0
    },
    playIcon: {
      width: 36,
      height: 36,
      objectFit: "cover",
      alignSelf: "center"
    },
    rightSideContainer: {
      marginLeft: 10,
      width: 500,
      display: "flex",
      flexDirection: "column"
    },
    areaContainer: {
      height: 16,
      minWidth: 30
    },
    area: {
      color: "#9F9FA0",
      fontFamily: "Asap, sans-serif",
      fontSize: 11,
      fontWeight: "bold"
    },
    titleContainer: {
      marginTop: 5,
      flexGrow: 1,
      display: "flex",
      alignItems: "center"
    },
    title: {
      color: "#0F0E11",
      fontFamily: "Asap, sans-serif",
      fontSize: "16px",
      fontWeight: "bold",
      lineHeight: "18px"
    },
    categoryContainer: {
      height: 16,
      minWidth: 30
    },
    category: {
      color: "#9F9FA0",
      fontFamily: "Asap, sans-serif",
      fontSize: 11,
      fontWeight: "bold"
    }
  };
  return (
    <div style={styles.container}>
      <div style={styles.imageContainer}>
        <img src={imageUrl} style={styles.image} alt="cover" />
        {videoData && (
          <div style={styles.playIconContainer}>
            <img src={PlayIcon} style={styles.playIcon} alt="play" />
          </div>
        )}
      </div>
      <div style={styles.rightSideContainer}>
        <div style={styles.areaContainer}>
          <CityWrapper
            city={city}
            render={record => <div style={styles.area}>{record.name}</div>}
          />
        </div>
        <div style={styles.titleContainer}>
          <div style={styles.title}>{title || name}</div>
        </div>
        <div style={styles.categoryContainer}>
          <CategoryWrapper
            category={category}
            render={record => <div style={styles.category}>{record.name}</div>}
          />
        </div>
      </div>
    </div>
  );
};

const ListItemBlock = props => {
  const { title, items } = props;
  const titleStyle = {
    color: "#0F0E11",
    fontFamily: "PingFang TC, sans-serif",
    fontSize: 18,
    fontWeight: 600,
    letterSpacing: "0.9px",
    lineHeight: "18px",
    marginBottom: 10
  };
  const itemContainerStyle = {
    display: "flex",
    flexDirection: "column",
    flexWrap: "norap"
  };
  const style = {
    width: "375px",
    // height: "576px",
    boxSizing: "border-box",
    paddingBottom: 10,
    overflow: "auto"
  };
  return (
    <div style={style}>
      <div style={titleStyle}>{title}</div>
      <div style={itemContainerStyle}>
        {items.map(item => (
          <ItemWrapper
            key={item.id}
            item={item}
            render={record => <ListItem item={record} />}
          />
        ))}
      </div>
    </div>
  );
};

export default ListItemBlock;
