import { GET_LIST } from "react-admin";

const VideoSearchProvider = opt => {
  const { config } = opt;
  if (!config) {
    throw new Error("Missing config");
  }
  const client = config.algoliaClient();
  const index = client.initIndex("videos");
  const provider = {
    config,
    client,
    index,
    handle(type, params) {
      switch (type) {
        case GET_LIST:
          return provider.list(params);
        default:
          throw new Error(`Unsupported action type: ${type}`);
      }
    },
    list(params) {
      if (!params.filter.q) {
        throw new Error(`Missing search filter param: ${params.filter}`);
      }
      const searchPromise = new Promise((resolve, reject) => {
        provider.index.search({ query: params.filter.q }, (err, content) => {
          if (err) {
            reject(err);
            return;
          }
          const videos = content.hits;

          const response = {
            data: videos,
            total: videos.length
          };
          resolve(response);
        });
      });
      return searchPromise;
    }
  };
  return provider;
};

export default VideoSearchProvider;
