import React from "react";
import Icon from "@material-ui/icons/Notifications";
import { Resource } from "react-admin";
import { NotificationList } from "./notifications";
import { NotificationShow } from "./notification";

const NotificationResource = props => {
  return (
    <Resource
      {...props}
      list={NotificationList}
      show={NotificationShow}
      edit={NotificationShow}
      icon={Icon}
    />
  );
};

export default NotificationResource;
