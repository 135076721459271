import React, { Component } from "react";
import _ from "lodash";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";
import { withStyles } from "@material-ui/core/styles";
import { auth } from "../../services/firebase";
import { httpClient } from "../../services/data-provider/qa";
import { Link } from "react-admin";

const DataSource = {
  data: {
    user: {}
  },
  internal: {
    listeners: [],
    setUser(user) {
      DataSource.data.user = user;
      DataSource.internal.notifyListeners();
    },
    notifyListeners() {
      _.forEach(DataSource.internal.listeners, fn => fn());
    }
  },
  loadUser(userId) {
    httpClient
      .get(`/api/user/${userId}`)
      .then(res => res.data)
      .then(result => {
        if (result.status !== "success") {
          throw new Error(`Error get user ${userId}: ${result.error.message}`);
        }
        DataSource.internal.setUser(result.user);
      });
  },
  getUser() {
    return DataSource.data.user;
  },
  refresh(userId) {
    DataSource.loadUser(userId);
  },
  addChangeListener(listener) {
    DataSource.internal.listeners.push(listener);
  },
  removeChangeListener(listener) {
    _.remove(DataSource.internal.listeners, item => item === listener);
  }
};

const styles = {
  media: {
    height: "26em"
  },
  resetPassword: {
    marginLeft: "auto",
    marginRight: -8
  }
};

class WelcomeUser extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      email: "讀取中",
      displayName: "",
      photoUrl: ""
    };
  }
  componentDidMount() {
    DataSource.addChangeListener(this.handleChange);
    if (auth.currentUser) {
      DataSource.loadUser(auth.currentUser.uid);
    }
    this.unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        DataSource.loadUser(user.uid);
      }
    });
  }
  componentWillUnmount() {
    this.unsubscribe();
    DataSource.removeChangeListener(this.handleChange);
  }
  handleChange() {
    const user = DataSource.getUser();
    const email = _.get(user, "email.primary", "讀取中");
    const displayName = _.get(user, "displayName", "");
    const photoUrl = _.get(user, "photoUrl", "");
    this.setState({
      email,
      displayName,
      photoUrl
    });
  }
  render() {
    return (
      <Card>
        <CardHeader
          title={`歡迎，${this.state.displayName}`}
          action={
            <IconButton>
              <RefreshIcon
                onClick={() => {
                  DataSource.refresh(auth.currentUser.uid);
                }}
              />
            </IconButton>
          }
        />
        <CardMedia
          image={this.state.photoUrl}
          className={this.props.classes.media}
        />
        <CardContent>
          <Typography variant="headline" component="h2">
            {this.state.email}
          </Typography>
        </CardContent>
        <CardActions>
          <Link to={`/password`} className={this.props.classes.resetPassword}>
            <Button size="medium" color="primary">
              重設密碼
            </Button>
          </Link>
        </CardActions>
      </Card>
    );
  }
}

export default withStyles(styles)(WelcomeUser);
