import React from "react";
import Icon from "@material-ui/icons/ViewQuilt";
import { Resource } from "react-admin";
import { TemplateList } from "./templates";
import { TemplateShow, TemplateEdit, TemplateCreate } from "./template";

const TemplateResource = props => {
  return (
    <Resource
      {...props}
      list={TemplateList}
      show={TemplateShow}
      create={TemplateCreate}
      edit={TemplateEdit}
      icon={Icon}
    />
  );
};

export default TemplateResource;
