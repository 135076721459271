import React from "react";
import {
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  Link
} from "react-admin";
import { List } from "../../../components/customize-react-admin";
import Pagination from "../../../components/qa/Pagination";
import ReportTargetField from "../../../components/qa/ReportTargetField";
import { ReporterLinkField } from "../../../components/qa/LinkFields";

const IDLinkField = props => (
  <Link to={`/reports/${props.record.id}/show`}>
    <TextField {...props} />
  </Link>
);

export const ReportList = props => {
  const perPage = 50;
  const defaultSort = {
    field: "time.created",
    order: "DESC"
  };
  return (
    <List
      {...props}
      title="檢舉列表"
      perPage={perPage}
      sort={defaultSort}
      pagination={<Pagination />}
    >
      <Datagrid>
        <IDLinkField source="id" label="ID" sortable={false} />
        <ReporterLinkField label="檢舉者" sortable={false} />
        <TextField source="target.type" label="檢舉目標類型" sortable={false} />
        <ReportTargetField label="檢舉目標" sortable={false} />
        <TextField source="content.text" label="原因" sortable={false} />
        <TextField source="status.code" label="狀態" sortable={false} />
        <BooleanField source="status.deleted" label="刪除" sortable={false} />
        <DateField showTime source="time.created" label="建立時間" />
      </Datagrid>
    </List>
  );
};
