import React from "react";
import { Resource } from "react-admin";
import { UserList } from "./users";
import { UserShow, UserCreate, UserEdit } from "./user";
import UserIcon from "@material-ui/icons/Group";

const UserResource = props => {
  return (
    <Resource
      {...props}
      list={UserList}
      show={UserShow}
      edit={UserEdit}
      create={UserCreate}
      icon={UserIcon}
    />
  );
};

export default UserResource;
