import React from "react";
import Icon from "@material-ui/icons/FindReplace";
import { Resource } from "react-admin";
import { KkblogList } from "./kkblogs";
import { KkblogShow } from "./kkblog";

const KkblogKoResource = props => {
  return (
    <Resource
      {...props}
      list={KkblogList}
      show={KkblogShow}
      // edit={KkblogEdit}
      // create={KkblogCreate}
      icon={Icon}
    />
  );
};

export default KkblogKoResource;
