import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import UpIcon from "@material-ui/icons/ArrowUpward";
import DownIcon from "@material-ui/icons/ArrowDownward";
import { Field } from "redux-form";
import { TextField, ReferenceField } from "react-admin";
import AddTemplateBlockButton from "./AddTemplateBlockButton";

const styles = {
  paper: {
    backgroundColor: "rgb(250, 250, 250)",
    padding: 10,
    marginTop: 10,
    display: "flex"
  },
  paperAdd: {
    backgroundColor: "rgb(250, 250, 250)",
    padding: 10,
    marginTop: 10,
    display: "flex",
    justifyContent: "center"
  },
  blockOrder: {
    display: "inline-block",
    width: 30,
    alignSelf: "center"
  },
  blockName: {
    display: "inline-flex",
    alignItems: "center",
    height: "100%",
    width: "500px"
  }
};

const TemplateBlock = ({
  block,
  record,
  options,
  moveUp,
  moveDown,
  remove,
  ...props
}) => {
  let optionUI = null;
  if (options) {
    optionUI = (
      <div>
        {options.move && (
          <React.Fragment>
            <IconButton aria-label="Move up" onClick={() => moveUp(block)}>
              <UpIcon />
            </IconButton>
            <IconButton aria-label="Move down" onClick={() => moveDown(block)}>
              <DownIcon />
            </IconButton>
          </React.Fragment>
        )}
        {options.remove && (
          <IconButton aria-label="Remove" onClick={() => remove(block)}>
            <DeleteIcon />
          </IconButton>
        )}
      </div>
    );
  }
  return (
    <Paper style={styles.paper}>
      <span style={styles.blockOrder}>{block.sort}.</span>
      <ReferenceField
        record={block}
        source="id"
        linkType="show"
        reference="template-blocks"
        basePath="/template-blocks"
        style={styles.blockName}
      >
        <TextField source="title" style={styles.blockName} />
      </ReferenceField>
      {optionUI}
    </Paper>
  );
};

const TemplateBlockAdd = ({ blocksMap, onAddSelected, ...props }) => {
  return (
    <Paper style={styles.paperAdd}>
      <AddTemplateBlockButton
        blocksMap={blocksMap}
        onAddSelected={onAddSelected}
      />
    </Paper>
  );
};

TemplateBlockAdd.propTypes = {
  blocksMap: PropTypes.object,
  onAddSelected: PropTypes.func
};

const TemplateBlocksField = props => {
  const blocksList = _.orderBy(
    Object.values(props.record.blocks),
    ["sort"],
    ["asc"]
  );
  const templateBlocks = blocksList.map((block, index) => (
    <TemplateBlock block={block} key={index} {...props} />
  ));
  return <div>{templateBlocks}</div>;
};

TemplateBlocksField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object
};

TemplateBlocksField.defaultProps = {
  addLabel: true
};

class TemplateBlocksInput extends React.Component {
  constructor(props) {
    super(props);
    this.add = this.add.bind(this);
    this.remove = this.remove.bind(this);
    this.moveUp = this.moveUp.bind(this);
    this.moveDown = this.moveDown.bind(this);
  }
  moveUp(block) {
    console.log("move up block: ", block);
    const {
      input: { value, onChange }
    } = this.props;
    const newblocksList = value.slice();
    const curIndex = _.findIndex(newblocksList, i => i.id === block.id);
    const newIndex = _.max([curIndex - 1, 0]);
    console.log(`move up index ${curIndex} -> ${newIndex}`);
    newblocksList.splice(newIndex, 0, newblocksList.splice(curIndex, 1)[0]);
    console.log("move up newblocksList: ", newblocksList);
    onChange(newblocksList);
  }
  moveDown(block) {
    console.log("move down block: ", block);
    const {
      input: { value, onChange }
    } = this.props;
    const newblocksList = value.slice();
    const curIndex = _.findIndex(newblocksList, i => i.id === block.id);
    const newIndex = _.min([curIndex + 1, newblocksList.length - 1]);
    console.log(`move down index ${curIndex} -> ${newIndex}`);
    newblocksList.splice(newIndex, 0, newblocksList.splice(curIndex, 1)[0]);
    console.log("move down newblocksList: ", newblocksList);
    onChange(newblocksList);
  }
  remove(block) {
    console.log("remove block: ", block);
    const {
      input: { value, onChange }
    } = this.props;
    const newblocksList = [];
    value.forEach(i => {
      if (i.id !== block.id) {
        newblocksList.push(i);
        i.sort = newblocksList.length;
      }
    });
    console.log("move down newblocksList: ", newblocksList);
    onChange(newblocksList);
  }
  add({ id }) {
    console.log(`add new block id:${id}`);
    const {
      input: { value, onChange }
    } = this.props;
    const newblocksList = value.slice();
    const newblock = {
      id,
      sort: newblocksList.length
    };
    const notInList =
      _.findIndex(newblocksList, i => i.id === newblock.id) === -1;
    if (notInList) {
      newblocksList.push(newblock);
    }
    onChange(newblocksList);
  }
  addBulk = blocks => {
    const {
      input: { value, onChange }
    } = this.props;
    const newblocksList = value.slice();
    blocks.forEach(({ id }) => {
      console.log(`add new block id:${id}`);
      const newblock = {
        id,
        sort: newblocksList.length
      };
      const notInList =
        _.findIndex(newblocksList, i => i.id === newblock.id) === -1;
      if (notInList) {
        newblocksList.push(newblock);
      }
    });
    onChange(newblocksList);
  };
  handleAddSelected = selectedblocks => {
    this.addBulk(selectedblocks);
  };
  render() {
    console.log("TemplateBlocksInput props: ", this.props);
    const options = { move: true, remove: true };
    const blocksList = this.props.input.value;
    const blocksMap = blocksList.reduce((accu, block) => {
      accu[block.id] = block;
      return accu;
    }, {});
    const templateBlocks = blocksList.map((block, index) => (
      <TemplateBlock
        block={block}
        key={index}
        options={options}
        moveUp={this.moveUp}
        moveDown={this.moveDown}
        remove={this.remove}
        {...this.props}
      />
    ));
    templateBlocks.push(
      <TemplateBlockAdd
        blocksMap={blocksMap}
        onAddSelected={this.handleAddSelected}
        key="add"
      />
    );
    return <div>{templateBlocks}</div>;
  }
}

TemplateBlocksInput.defaultProps = {
  onChange: () => {}
};

const TemplateBlocksInputField = props => (
  <Field
    name={props.source}
    component={TemplateBlocksInput}
    label={props.label}
    format={(blocks = []) => {
      const blocksList = _.orderBy(Object.values(blocks), ["sort"], ["asc"]);
      return blocksList;
    }}
    parse={blocksList => {
      const blocks = blocksList.reduce((accu, cur, idx) => {
        cur.sort = idx + 1;
        accu[cur.id] = cur;
        return accu;
      }, {});
      return blocks;
    }}
  />
);

TemplateBlocksInputField.defaultProps = {
  addLabel: true
};

export { TemplateBlocksField, TemplateBlocksInputField };
