import React from "react";
import { FunctionField, SelectField, SelectInput } from "react-admin";

// | post_status | string | The publish status of article | {00: published, 10: unpublished} |
const arrayList = [
  { id: "00", name: "on shelf" },
  { id: "10", name: "off shelf" }
];

const PostStatusField = props => (
  <FunctionField
    {...props}
    render={() => <SelectField choices={arrayList} {...props} />}
  />
);
PostStatusField.defaultProps = {
  addLabel: true
};

const PostStatusInput = props => (
  <FunctionField
    {...props}
    render={() => <SelectInput choices={arrayList} {...props} />}
  />
);
PostStatusInput.defaultProps = {};

export { PostStatusField, PostStatusInput };
