import _ from "lodash";
import { stringify } from "query-string";
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  UPDATE,
  CREATE,
  DELETE
} from "react-admin";
// import { auth } from "../../firebase";
import {
  QA_API_ERROR,
  objectKey2Array,
  arrayInput2Object,
  getSourceData,
  isValidUrl
} from "../../util";

const ArticleProvider = opt => {
  const { config, httpClient } = opt;
  if (!config) {
    throw new Error("Missing config");
  }
  if (!httpClient) {
    throw new Error("Missing httpClient");
  }

  const responseFromat = inResponse => {
    inResponse.data.category_data = objectKey2Array(
      inResponse.data.category_data,
      "category"
    );
    inResponse.data.area_data = objectKey2Array(
      inResponse.data.area_data,
      "city"
    );
    return inResponse;
  };

  const provider = {
    handle(type, params) {
      console.log(type);
      switch (type) {
        case GET_LIST:
          return provider.list(params);
        // case GET_MANY_REFERENCE:
        //   return provider.list(params);
        case GET_ONE:
          return provider.get(params);
        case GET_MANY:
          return provider.getMany(params);
        case CREATE:
          return provider.create(params);
        case UPDATE:
          return provider.update(params);
        case DELETE:
          return provider.delete(params);
        // case DELETE_MANY:
        //   return provider.deleteMany(params);
        default:
          throw new Error(`Unsupported action type: ${type}`);
      }
    },
    get(params) {
      return httpClient
        .get(`/api/articles/${params.id}`)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("KkblogProvider.get Error", result, params);
          }
          let response = {
            data: result.article
          };
          return responseFromat(response);
        });
    },
    getMany(params) {
      const query = {
        ids: params.ids
      };
      return httpClient
        .get(`/api/articles/many?${stringify(query)}`)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("ArticleProvider.getMany Error", result, params);
          }
          const response = {
            data: result.articles || null
          };
          return response;
        });
    },
    list(params) {
      let pageNumber = 1;
      const perPage = params.pagination.perPage || 50;
      const query = {
        order: params.sort.field,
        sort: params.sort.order.toLowerCase(),
        limit: perPage
      };
      // filter post_status
      if (params.filter.post_status) {
        query.post_status = params.filter.post_status;
      }
      // filter lang
      if (params.filter.lang) {
        query.lang = params.filter.lang;
      }
      // filter area_data
      if (params.filter.area_data) {
        const tmpArr = [];
        params.filter.area_data.forEach(item => {
          if (item.city) {
            tmpArr.push(item.city);
          }
        });
        query.area_data = tmpArr.join(",");
      }
      // filter q
      if (params.filter.q) {
        query.q = params.filter.q;
      }
      // pagination
      if (typeof params.pagination.page === "string") {
        const [thePageNumber, afterOrBefore, id] = params.pagination.page.split(
          "_"
        );
        if (afterOrBefore === "after") {
          query.afterId = id;
        } else if (afterOrBefore === "before") {
          query.beforeId = id;
        }
        pageNumber = thePageNumber;
      }
      // default
      return httpClient
        .get(`/api/articles?${stringify(query)}`)
        .then(res => res.data)
        .then(result => {
          const countArticles = _.size(result.articles);
          if (result.status !== "success") {
            throw QA_API_ERROR("KkblogProvider.list Error", result, params);
          }
          let response = {
            data: result.articles || null,
            total:
              perPage === countArticles
                ? perPage * pageNumber + 1
                : countArticles
          };
          return response;
        });
    },
    update(params) {
      const payload = {
        source_link: isValidUrl(params.data.source_link)
          ? params.data.source_link
          : "",
        apply_status: params.data.apply_status || "0",
        post_status: params.data.post_status,
        title: params.data.title,
        sub_title: params.data.sub_title,
        content: params.data.content,
        lang: params.data.lang,
        area_data: arrayInput2Object(params.data.area_data, "city"),
        category_data: arrayInput2Object(params.data.category_data, "category"),
        visibility: {
          guide: params.data.visibility.guide || false,
          kkday: params.data.visibility.kkday || false,
          kkhome: params.data.visibility.kkhome || false,
          kkorder: params.data.visibility.kkorder || false
        },
        begin_date: params.data.begin_date,
        end_date: params.data.end_date,
        seo_data: {
          open_graph_title: params.data.seo_data.open_graph_title || "",
          meta_desc: params.data.seo_data.meta_desc || "",
          html_title: params.data.seo_data.html_title || "",
          open_graph_desc: params.data.seo_data.open_graph_desc || "",
          open_graph_image: params.data.seo_data.open_graph_image || ""
        },
        image_data: {
          android_image: params.data.image_data.android_image || "",
          mobile_image: params.data.image_data.mobile_image || "",
          web_image: params.data.image_data.web_image || "",
          ios_image: params.data.image_data.ios_image || ""
        }
      };

      const sourceData = getSourceData(params.data);

      if (sourceData.source !== "") {
        payload.source = sourceData.source;
        payload.source_id = sourceData.source_id;
      }

      return httpClient
        .put(`/api/articles/${params.id}`, payload)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("ArticleProvider.update Error", result, params);
          }
          const response = {
            data: result.article
          };
          return responseFromat(response);
        });
    },
    create(params) {
      const payload = {
        title: params.data.title,
        sub_title: params.data.sub_title,
        content: params.data.content,
        visibility: {
          guide: params.data.visibility_guide || false,
          kkday: params.data.visibility_kkday || false,
          kkhome: params.data.visibility_kkhome || false,
          kkorder: params.data.visibility_kkorder || false
        }
      };

      return httpClient
        .post(`/api/articles`, payload)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("ArticleProvider.create Error", result, params);
          }
          const response = {
            data: result.article
          };
          return responseFromat(response);
        });
    },
    delete(params) {
      return httpClient
        .delete(`/api/articles/${params.id}`)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("ArticlesProvider.delete Error", result, params);
          }
          const response = {
            data: { id: result.id }
          };
          return response;
        });
    }
  };
  return provider;
};

export default ArticleProvider;
