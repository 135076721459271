import React from "react";
import Icon from "@material-ui/icons/QuestionAnswer";
import { Resource } from "react-admin";
import { QuestionList } from "./questions";
import { QuestionShow, QuestionCreate, QuestionEdit } from "./question";

const QuestionResource = props => {
  return (
    <Resource
      {...props}
      list={QuestionList}
      show={QuestionShow}
      edit={QuestionEdit}
      create={QuestionCreate}
      icon={Icon}
    />
  );
};

export default QuestionResource;
