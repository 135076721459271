import React from "react";
import * as _ from "lodash";
import UsersCount from "./widgets/UsersCount";
import QuestionsCount from "./widgets/QuestionsCount";
import AnswersCount from "./widgets/AnswersCount";
import WelcomeUser from "./widgets/WelcomeUser";
import LatestQuestions from "./widgets/LatestQuestions";
import LatestAnswers from "./widgets/LatestAnswers";
import LatestReports from "./widgets/LatestReports";
import LatestEvents from "./widgets/LatestEvents";
import { httpClient } from "../services/data-provider/qa";

const DataSource = {
  data: {
    stats: {
      users: null,
      questions: null,
      answers: null
    }
  },
  internal: {
    listeners: [],
    setStats(type, stats) {
      DataSource.data.stats[type] = stats;
      DataSource.internal.notifyListeners();
    },
    loadStats(type) {
      httpClient
        .get(`/api/admin/stats/${type}/count`)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw new Error(`Error get ${type} stats: ${result.error.message}`);
          }
          DataSource.internal.setStats(type, result.stats);
        });
    },
    notifyListeners() {
      _.forEach(DataSource.internal.listeners, fn => fn());
    }
  },
  getStats(type) {
    if (!DataSource.data.stats[type]) {
      DataSource.internal.loadStats(type);
    }
    return DataSource.data.stats[type];
  },
  addChangeListener(listener) {
    DataSource.internal.listeners.push(listener);
  },
  removeChangeListener(listener) {
    _.remove(DataSource.internal.listeners, item => item === listener);
  }
};

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      stats: {
        users: DataSource.getStats("users"),
        questions: DataSource.getStats("questions"),
        answers: DataSource.getStats("answers")
      }
    };
  }
  componentDidMount() {
    DataSource.addChangeListener(this.handleChange);
  }
  componentWillUnmount() {
    DataSource.removeChangeListener(this.handleChange);
  }
  handleChange() {
    this.setState({
      stats: {
        users: DataSource.getStats("users"),
        questions: DataSource.getStats("questions"),
        answers: DataSource.getStats("answers")
      }
    });
  }
  render() {
    const styles = {
      flex: { display: "flex" },
      flexColumn: { display: "flex", flexDirection: "column" },
      leftCol: { flex: 1, marginRight: "1em" },
      rightCol: { flex: 1, marginLeft: "1em" },
      singleCol: { marginTop: "2em", marginBottom: "2em" }
    };
    const { stats } = this.state;
    return (
      <div style={styles.flex}>
        <div style={styles.leftCol}>
          <div style={styles.flex}>
            <QuestionsCount stats={stats.questions} />
            <AnswersCount stats={stats.answers} />
            <UsersCount stats={stats.users} />
          </div>
          <div style={styles.singleCol}>
            <WelcomeUser />
          </div>
          <div style={styles.singleCol}>
            <LatestReports />
          </div>
        </div>
        <div style={styles.rightCol}>
          <div style={styles.singleCol}>
            <LatestQuestions />
          </div>
          <div style={styles.singleCol}>
            <LatestAnswers />
          </div>
          <div style={styles.singleCol}>
            <LatestEvents />
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
