import React from "react";
import {
  Datagrid,
  TextField,
  DateField,
  Link,
  CardActions,
  RefreshButton,
  SimpleForm,
  Toolbar,
  SaveButton
} from "react-admin";
import { List } from "../../../components/customize-react-admin";
import Pagination from "../../../components/qa/Pagination";

import { KKdayPostLoadButton } from "../../../components/guide/KKdayPostLoadButton";
import { Card } from "@material-ui/core";

const IDLinkField = props => (
  <Link to={`/kkblogs-vi/${props.record.id}/show`}>
    <TextField {...props} />
  </Link>
);

const PostCreateToolbar = props => (
  <Toolbar {...props}>
    <SaveButton
      label="Import other KKday Blog Id"
      redirect={false}
      submitOnEnter={false}
      style={{ display: "none" }}
    />
  </Toolbar>
);

const ScheduleActions = () => (
  <CardActions>
    <RefreshButton />
  </CardActions>
);

export const KkblogList = props => {
  const perPage = 100;
  const defaultSort = {
    field: "date",
    order: "DESC"
  };
  return (
    <div>
      <List
        {...props}
        title="KKDay Blogs Tiếng Việt"
        perPage={perPage}
        sort={defaultSort}
        pagination={<Pagination />}
        actions={<ScheduleActions />}
      >
        <Datagrid>
          <IDLinkField source="id" lang="lang" label="ID" sortable={false} />
          <IDLinkField
            source="title"
            lang="lang"
            label="Title"
            sortable={false}
          />
          <DateField showTime source="date" label="Create time" />
        </Datagrid>
      </List>

      <Card>
        <SimpleForm toolbar={<PostCreateToolbar />} submitOnEnter={false}>
          <KKdayPostLoadButton
            source="KKdayBlogPostId"
            label="KKday Blog post ID"
            lang="vi"
          />
        </SimpleForm>
      </Card>
    </div>
  );
};
