import React from "react";
import {
  Datagrid,
  TextField,
  DateField,
  Link,
  Filter,
  TextInput,
  BooleanField
} from "react-admin";
import { List } from "../../../components/customize-react-admin";
import Pagination from "../../../components/qa/Pagination";

const IDLinkField = props => (
  <Link to={`/cities/${props.record.id}/show`}>
    <TextField {...props} />
  </Link>
);

const CityFilter = props => (
  <Filter {...props}>
    <TextInput label="Search City (A01-001)" source="q" alwaysOn />
  </Filter>
);

export const CityList = props => {
  const perPage = 1000;
  const defaultSort = {
    field: "id",
    order: "ASC"
  };
  return (
    <List
      {...props}
      title="攻略城市列表"
      perPage={perPage}
      sort={defaultSort}
      pagination={<Pagination />}
      filters={<CityFilter />}
    >
      <Datagrid>
        <IDLinkField source="name" label="Name" sortable={false} />
        <TextField source="openweatherMeta.name" label="English Name" sortable={false} />
        <TextField source="openweatherMeta.country" label="Country Code" sortable={false} />
        <BooleanField source="openweatherMeta.isSync" label="Open Weather Sync" sortable={false} />

        <DateField showTime source="modify_date" label="Last Modify" />
      </Datagrid>
    </List>
  );
};
