import { stringify } from "query-string";
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  CREATE,
  UPDATE,
  DELETE
} from "react-admin";
import { auth } from "../../firebase";
import { QA_API_ERROR } from "../../util";

const UserProvider = opt => {
  const { config, httpClient } = opt;
  if (!config) {
    throw new Error("Missing config");
  }
  if (!httpClient) {
    throw new Error("Missing httpClient");
  }
  const provider = {
    handle(type, params) {
      switch (type) {
        case GET_LIST:
          return provider.list(params);
        case GET_ONE:
          return provider.get(params);
        case GET_MANY:
          return provider.getMany(params);
        case CREATE:
          return provider.create(params);
        case UPDATE:
          return provider.update(params);
        case DELETE:
          return provider.delete(params);
        default:
          throw new Error(`Unsupported action type: ${type}`);
      }
    },
    get(params) {
      return httpClient
        .get(`/api/user/${params.id}`)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("UserProvider.get Error", result, params);
          }
          let response = {
            data: result.user
          };
          return response;
        });
    },
    getMany(params) {
      const query = {
        ids: params.ids
      };
      return httpClient
        .get(`/api/admin/users?${stringify(query)}`)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("UserProvider.getMany Error", result, params);
          }
          const response = {
            data: result.users || null
          };
          return response;
        });
    },
    list(params) {
      const query = {
        order: params.sort.field,
        sort: params.sort.order.toLowerCase(),
        limit: params.pagination.perPage
      };
      // filter
      if (params.filter.internalUser) {
        query.internalUser = true;
      }
      if (params.filter.validUser) {
        query.validUser = params.filter.validUser;
      }
      // pagination
      if (typeof params.pagination.page === "string") {
        const [pageNumber, afterOrBefore, id] = params.pagination.page.split(
          "_"
        );
        if (afterOrBefore === "after") {
          query.after = id;
        } else if (afterOrBefore === "before") {
          query.before = id;
        }
        console.log("pageNumber: ", pageNumber);
      }
      return httpClient
        .get(`/api/admin/users?${stringify(query)}`)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("UserProvider.list Error", result, params);
          }
          const users = result.users;
          const response = {
            data: users || null,
            total: 1000
          };
          return response;
        });
    },
    create(params) {
      return auth.currentUser
        .getIdToken(false)
        .then(token => {
          const payload = { ...params.data, token };
          return httpClient.post(`/api/admin/user/add`, payload);
        })
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("UserProvider.create Error", result, params);
          }
          const response = {
            data: result.user
          };
          return response;
        });
    },
    update(params) {
      return auth.currentUser
        .getIdToken(false)
        .then(token => {
          const payload = {
            ...params.data,
            token,
            targetUserId: params.id
          };
          return httpClient.post(`/api/admin/user/update`, payload);
        })
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("UserProvider.update Error", result, params);
          }
          const response = {
            data: result.user
          };
          return response;
        });
    },
    delete(params) {
      return auth.currentUser
        .getIdToken(false)
        .then(token => {
          const payload = {
            token,
            targetUserId: params.id
          };
          return httpClient.post(`/api/admin/user/delete`, payload);
        })
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("UserProvider.delete Error", result, params);
          }
          const response = {
            data: result.user
          };
          return response;
        });
    }
  };
  return provider;
};

export default UserProvider;
