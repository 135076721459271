import React from "react";
import Icon from "@material-ui/icons/LocationCity";
import { Resource } from "react-admin";
import { CityList } from "./cities";
import { CityShow, CityEdit } from "./city";

const CityResource = props => {
  return (
    <Resource
      {...props}
      list={CityList}
      show={CityShow}
      edit={CityEdit}
      // create={CityCreate}
      icon={Icon}
    />
  );
};

export default CityResource;
