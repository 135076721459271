import React from "react";
import { AutocompleteInput } from "react-admin";
import { UserPhotoDisplayNameField } from "./UserFields";

export const AuthorAutoCompleteInput = props => (
  <AutocompleteInput
    {...props}
    options={{
      fullWidth: true
    }}
    resettable
    optionText={record => record.displayName}
    suggestionComponent={({ suggestion, query, isHighlighted, ...props }) => {
      return <UserPhotoDisplayNameField record={suggestion} size={20} />;
    }}
  />
);
