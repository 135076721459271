import React from "react";
import Icon from "@material-ui/icons/Category";
import { Resource } from "react-admin";
import { CategoryList } from "./categories";
import { CategoryShow, CategoryEdit, CategoryCreate } from "./category";

const CategoryResource = props => {
  return (
    <Resource
      {...props}
      list={CategoryList}
      show={CategoryShow}
      edit={CategoryEdit}
      create={CategoryCreate}
      icon={Icon}
    />
  );
};

export default CategoryResource;
