import { stringify } from "query-string";
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  UPDATE,
  DELETE
} from "react-admin";
import { auth } from "../../firebase";
import { QA_API_ERROR } from "../../util";

const ReportProvider = opt => {
  const { config, httpClient } = opt;
  if (!config) {
    throw new Error("Missing config");
  }
  if (!httpClient) {
    throw new Error("Missing httpClient");
  }
  const provider = {
    handle(type, params) {
      switch (type) {
        case GET_LIST:
          return provider.list(params);
        case GET_ONE:
          return provider.get(params);
        case GET_MANY:
          return provider.getMany(params);
        case GET_MANY_REFERENCE:
          return provider.list(params);
        case UPDATE:
          return provider.update(params);
        case DELETE:
          return provider.delete(params);
        default:
          throw new Error(`ReportProvider unsupported action type: ${type}`);
      }
    },
    get(params) {
      return httpClient
        .get(`/api/report/${params.id}`)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("ReportProvider.get Error", result, params);
          }
          let response = {
            data: result.report
          };
          return response;
        });
    },
    getMany(params) {
      const query = {
        ids: params.ids
      };
      return httpClient
        .get(`/api/admin/reports/list?${stringify(query)}`)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("ReportProvider.getMany Error", result, params);
          }
          const response = {
            data: result.reports || null
          };
          return response;
        });
    },
    list(params) {
      const query = {
        order: params.sort.field,
        sort: params.sort.order.toLowerCase(),
        limit: params.pagination.perPage
      };
      // target
      if (params.target === "targetId") {
        query.targetId = params.id;
      }
      // filter
      if (params.filter.status) {
        if (params.filter.status.code) {
          query.status = params.filter.status.code;
        }
      }
      if (params.filter.target) {
        if (params.filter.target.type) {
          query.target = params.filter.target.type;
        }
      }
      if (params.filter.reporterId) {
        query.reporterId = params.filter.reporterId;
      }
      // pagination
      if (typeof params.pagination.page === "string") {
        const [pageNumber, afterOrBefore, id] = params.pagination.page.split(
          "_"
        );
        if (afterOrBefore === "after") {
          query.after = id;
        } else if (afterOrBefore === "before") {
          query.before = id;
        }
        console.log("pageNumber: ", pageNumber);
      }
      return httpClient
        .get(`/api/reports?${stringify(query)}`)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("ReportProvider.list Error", result, params);
          }
          let response = {
            data: result.reports || null,
            total: 1000
          };
          return response;
        });
    },
    update(params) {
      return auth.currentUser
        .getIdToken(false)
        .then(token => {
          const payload = {
            token,
            status: params.data.status.code
          };
          return httpClient.put(`/api/admin/report/${params.id}`, payload);
        })
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("ReportProvider.update Error", result, params);
          }
          const response = {
            data: result.report
          };
          return response;
        });
    },
    delete(params) {
      return auth.currentUser
        .getIdToken(false)
        .then(token => {
          const payload = {
            token
          };
          return httpClient.delete(`/api/admin/report/${params.id}`, {
            data: payload
          });
        })
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("ReportProvider.delete Error", result, params);
          }
          const response = {
            data: result.report
          };
          return response;
        });
    }
  };
  return provider;
};

export default ReportProvider;
