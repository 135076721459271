import _ from "lodash";
import React from "react";

import { Field } from "redux-form";
import { FunctionField } from "react-admin";
import { CategoryComponentInput, CategoryComponent } from "./CategoryComponent";

const CategoryVideoInput = props => {
  return (
    <Field
      name={props.source}
      component={CategoryComponentInput}
      label={props.label}
      categoryType="video"
      format={category_data => {
        const values = _.map(category_data, item => {
          return item.category;
        });
        return values;
      }}
      parse={selectedOptions => {
        return _.map(selectedOptions, category => {
          return { category: category };
        });
      }}
    />
  );
};
CategoryVideoInput.defaultProps = {
  addLabel: true
};

const CategoryVideoField = props => (
  <FunctionField
    {...props}
    render={() => {
      return (
        <CategoryComponent
          value={props.record[props.source]}
          categoryType="video"
        />
      );
    }}
  />
);
CategoryVideoField.defaultProps = { addLabel: true };

export { CategoryVideoField, CategoryVideoInput };
