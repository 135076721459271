import { stringify } from "query-string";
import _ from "lodash";
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  CREATE,
  UPDATE,
  DELETE
} from "react-admin";
import { auth } from "../../firebase";
import { QA_API_ERROR } from "../../util";

const TemplateBlockProvider = opt => {
  const { config, httpClient } = opt;
  if (!config) {
    throw new Error("Missing config");
  }
  if (!httpClient) {
    throw new Error("Missing httpClient");
  }
  const provider = {
    handle(type, params) {
      switch (type) {
        case GET_LIST:
          return provider.list(params);
        case GET_ONE:
          return provider.get(params);
        case GET_MANY:
          return provider.getMany(params);
        case CREATE:
          return provider.create(params);
        case UPDATE:
          return provider.update(params);
        case DELETE:
          return provider.delete(params);
        default:
          throw new Error(`Unsupported action type: ${type}`);
      }
    },
    get(params) {
      return httpClient
        .get(`/api/template-blocks/${params.id}`)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR(
              "TemplateBlockProvider.get Error",
              result,
              params
            );
          }
          let response = {
            data: result.templateBlock
          };
          return response;
        });
    },
    getMany(params) {
      const query = {
        ids: params.ids
      };
      return httpClient
        .get(`/api/template-blocks?${stringify(query)}`)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR(
              "TemplateBlockProvider.getMany Error",
              result,
              params
            );
          }
          const response = {
            data: result.templateBlocks || null
          };
          return response;
        });
    },
    list(params) {
      const query = {
        order: params.sort.field,
        sort: params.sort.order.toLowerCase(),
        limit: params.pagination.perPage
      };
      if (typeof params.pagination.page === "string") {
        const [pageNumber, afterOrBefore, id] = params.pagination.page.split(
          "_"
        );
        if (afterOrBefore === "after") {
          query.after = id;
        } else if (afterOrBefore === "before") {
          query.before = id;
        }
        console.log("pageNumber: ", pageNumber);
      }
      return httpClient
        .get(`/api/template-blocks?${stringify(query)}`)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR(
              "TemplateBlockProvider.list Error",
              result,
              params
            );
          }
          let response = {
            data: result.templateBlocks || null,
            total: 1000
          };
          return response;
        });
    },
    create(params) {
      return auth.currentUser
        .getIdToken(false)
        .then(token => {
          const itemsList = _.orderBy(
            Object.values(params.data.items),
            ["sort"],
            ["asc"]
          );
          const payload = {
            token,
            title: params.data.title,
            layout: params.data.layout,
            items: itemsList
          };
          return httpClient.post(`/api/template-blocks`, payload);
        })
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR(
              "TemplateBlockProvider.create Error",
              result,
              params
            );
          }
          const response = {
            data: result.templateBlock
          };
          return response;
        });
    },
    update(params) {
      return auth.currentUser
        .getIdToken(false)
        .then(token => {
          const itemsList = _.orderBy(
            Object.values(params.data.items),
            ["sort"],
            ["asc"]
          );
          const payload = {
            token,
            title: params.data.title,
            layout: params.data.layout,
            items: itemsList
          };
          return httpClient.put(`/api/template-blocks/${params.id}`, payload);
        })
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR(
              "TemplateBlockProvider.update Error",
              result,
              params
            );
          }
          const response = {
            data: result.templateBlock
          };
          return response;
        });
    },
    delete(params) {
      return auth.currentUser
        .getIdToken(false)
        .then(token => {
          const payload = {
            token
          };
          return httpClient.delete(`/api/template-blocks/${params.id}`, {
            data: payload
          });
        })
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR(
              "TemplateBlockProvider.delete Error",
              result,
              params
            );
          }
          const response = {
            data: result.deleted
          };
          return response;
        });
    }
  };
  return provider;
};

export default TemplateBlockProvider;
