import React from "react";
import { Datagrid, TextField, DateField, Link } from "react-admin";
import { List } from "../../../components/customize-react-admin";
import Pagination from "../../../components/qa/Pagination";
import { TemplateBlockLayoutField } from "../../../components/guide/TemplateBlockLayoutFields";

const IDLinkField = props => (
  <Link to={`/template-blocks/${props.record.id}/show`}>
    <TextField {...props} />
  </Link>
);

export const TemplateBlockList = props => {
  const perPage = 50;
  const defaultSort = {
    field: "create_date",
    order: "DESC"
  };
  return (
    <List
      {...props}
      title="Template-block 列表"
      perPage={perPage}
      sort={defaultSort}
      pagination={<Pagination />}
    >
      <Datagrid>
        <IDLinkField source="title" label="標題" sortable={false} />
        <TemplateBlockLayoutField />
        <DateField showTime source="create_date" label="建立時間" />
      </Datagrid>
    </List>
  );
};
