import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  ImageField,
  RichTextField,
  Edit,
  TabbedForm,
  FormTab,
  BooleanInput,
  TextInput,
  BooleanField,
  SimpleShowLayout,
  Create,
  SimpleForm,
  required,
  minLength,
  FunctionField
} from "react-admin";

import { DateTimeInput } from "react-admin-date-inputs";
import { UserPhotoUrlInputField } from "../../../components/qa/ImagesInputField";
import RichTextInput from "../../../components/ra-input-rich-text";

import {
  PostStatusField,
  PostStatusInput
} from "../../../components/guide/PostStatus";
import {
  LanguageFamilyField,
  LanguageFamilyInput
} from "../../../components/guide/LanguageFamily";
import {
  CategoryArticleInput,
  CategoryArticleField
} from "../../../components/guide/CategoryArticle";
import { FlightQuotesToCityField } from "../../../components/guide/FlightsField";
import {
  CountryCitySelectInput,
  CountryCitySelectField
} from "../../../components/guide/CountryCitySelect";
import {
  ApplyStatusField,
  ApplyStatusInput
} from "../../../components/guide/ApplyStatus";

import {
  visibilityLabel,
  richTextInputToolbarOptions
} from "../../../config/guide/consts";
import ExportGuideTwToCn from "../../../components/guide/ExportGuideTwToCnButton";

const style = {
  info: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "2em",
    minWidth: "8em"
  },
  booleanInfo: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "2em"
  },
  richInput: {
    width: "900px",
    marginLeft: "140px",
    marginRight: "140px"
  }
};

export const ArticleShow = withStyles(style)(({ classes, ...props }) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="id" label="ID" className={classes.info} />
        <DateField
          source="create_date"
          label="Create date"
          className={classes.info}
          showTime
        />
        <DateField
          source="modify_date"
          label="Last Modify"
          className={classes.info}
          showTime
        />
        <ApplyStatusField label="Apply Status" source="apply_status" />
        <PostStatusField label="Post status" source="post_status" />
        <TextField source="title" label="Title" />
        <TextField source="sub_title" label="Sub Title" />
        <SimpleShowLayout label="Visibility">
          <BooleanField
            source="visibility.guide"
            label={visibilityLabel.guide}
            className={classes.booleanInfo}
          />
          <BooleanField
            source="visibility.kkday"
            label={visibilityLabel.kkday}
            className={classes.booleanInfo}
          />
          <BooleanField
            source="visibility.kkhome"
            label={visibilityLabel.kkhome}
            className={classes.booleanInfo}
          />
          <BooleanField
            source="visibility.kkorder"
            label={visibilityLabel.kkorder}
            className={classes.booleanInfo}
          />
        </SimpleShowLayout>
        <CountryCitySelectField source="area_data" label="Area data" />
        <CategoryArticleField source="category_data" label="Categories" />
        <LanguageFamilyField source="lang" label="Language family" />

        <SimpleShowLayout>
          <DateField
            showTime
            source="begin_date"
            label="Start date"
            className={classes.info}
          />
          <DateField
            showTime
            source="end_date"
            label="End date"
            className={classes.info}
          />
        </SimpleShowLayout>
      </Tab>
      <Tab label="content" path="content">
        <TextField source="title" label="Title" />
        <RichTextField
          source="content"
          label="內容"
          className={classes.richInput}
        />
      </Tab>
      <Tab label="images" path="images">
        <TextField source="title" label="Title" />
        <TextField source="image_data.ios_image" label="iOS image" />
        <ImageField source="image_data.ios_image" label="iOS mage" />
        <TextField source="image_data.android_image" label="Android image" />
        <ImageField source="image_data.android_image" label="Android image" />
        <TextField source="image_data.mobile_image" label="Mobile image" />
        <ImageField source="image_data.mobile_image" label="Mobile image" />
        <TextField source="image_data.web_image" label="Web image" />
        <ImageField source="image_data.web_image" label="Web image" />
      </Tab>
      <Tab label="seo meta" path="seo">
        <FlightQuotesToCityField label="Flight quotes" />
        <TextField source="source_id" label="Source ID" />
        <TextField source="source_link" label="Source Link" />
        <TextField
          source="seo_data.open_graph_title"
          label="Open Graph Title"
        />
        <TextField source="seo_data.meta_desc" label="Meta Desc" />
        <TextField source="seo_data.html_title" label="Html Title" />
        <TextField source="seo_data.open_graph_desc" label="Open Graph Desc" />
        <TextField
          source="seo_data.open_graph_image"
          label="Open Graph Image"
        />
      </Tab>
      <Tab label="Export" path="export">
        <TextField source="title" label="Title" />
        <FunctionField
          label="Export action"
          addLabel={false}
          render={record => <ExportGuideTwToCn articleId={record.id} />}
        />
      </Tab>
    </TabbedShowLayout>
  </Show>
));

const validateArticleEdit = values => {
  const errors = {};

  // title check
  if (!values.title) {
    errors.title = ["This is required"];
  }

  // publish check
  if (values.post_status === "00") {
    let errorMessage = [];
    // language family check
    if (!values.lang) {
      errors.lang = ["Language Family is required"];
    }
    // begin date check
    if (!values.begin_date) {
      errors.begin_date = ["This is required"];
    }
    // end date check
    if (!values.end_date) {
      errors.end_date = ["This is required"];
    }
    // apply status check
    if (values.apply_status !== "1") {
      errors.apply_status = ["You should approved it"];
    }

    // *** custom component validateion
    // area check
    if (!(values.area_data || []).length) {
      errorMessage.push("Area Data is required");
      errors.area_data = ["This is required"];
    }
    // category check
    if (!(values.category_data || []).length) {
      errorMessage.push("Category is required");
      errors.area_data = ["This is required"];
    }
    // images check
    if (values.image_data) {
      if (!errors.image_data) {
        errors.image_data = {};
      }
      if (!values.image_data.ios_image) {
        errorMessage.push("iOS image is required");
        errors.image_data.ios_image = ["This is required"];
      }
      if (!values.image_data.android_image) {
        errorMessage.push("Android image is required");
        errors.image_data.android_image = ["This is required"];
      }
      if (!values.image_data.mobile_image) {
        errorMessage.push("Mobile image is required");
        errors.image_data.mobile_image = ["This is required"];
      }
      if (!values.image_data.web_image) {
        errorMessage.push("Web image is required");
        errors.image_data.web_image = ["This is required"];
      }
    }
    // custom component error message show on post_status
    if (errorMessage.length) {
      errors.post_status = ["** " + errorMessage.join(" ** ")];
    }
  }
  return errors;
};

export const ArticleEdit = withStyles(style)(({ classes, ...props }) => (
  <Edit {...props}>
    <TabbedForm
      redirect="show"
      submitOnEnter={false}
      validate={validateArticleEdit}
    >
      <FormTab label="summary">
        <ApplyStatusInput label="Apply Status" source="apply_status" />
        <PostStatusInput label="Post status" source="post_status" />
        <TextInput label="Title" source="title" style={{ width: 800 }} />
        <TextInput
          label="Sub Title"
          source="sub_title"
          style={{ width: 800 }}
        />
        <BooleanInput
          source="visibility.guide"
          label={visibilityLabel.guide}
          formClassName={classes.booleanInfo}
        />
        <BooleanInput
          source="visibility.kkday"
          label={visibilityLabel.kkday}
          formClassName={classes.booleanInfo}
        />
        <BooleanInput
          source="visibility.kkhome"
          label={visibilityLabel.kkhome}
          formClassName={classes.booleanInfo}
        />
        <BooleanInput
          source="visibility.kkorder"
          label={visibilityLabel.kkorder}
          formClassName={classes.booleanInfo}
        />
        <CountryCitySelectInput source="area_data" label="Area data" />
        <CategoryArticleInput source="category_data" label="Categories" />
        <LanguageFamilyInput source="lang" label="Language family" />

        <DateTimeInput
          source="begin_date"
          label="Start date"
          options={{
            format: "yyyy/MM/dd, HH:mm:ss",
            minDate: new Date("2017-04-01"),
            showTodayButton: true
          }}
        />
        <DateTimeInput
          source="end_date"
          label="End date"
          options={{
            format: "yyyy/MM/dd, HH:mm:ss",
            minDate: new Date("2038-04-01")
          }}
        />
      </FormTab>
      <FormTab label="Content" path="content">
        <RichTextInput
          source="content"
          toolbar={richTextInputToolbarOptions}
          className={classes.richInput}
        />
      </FormTab>
      <FormTab label="images" path="images">
        <UserPhotoUrlInputField
          source="image_data.ios_image"
          label="iOS image"
        />
        <UserPhotoUrlInputField
          source="image_data.android_image"
          label="Android image"
        />
        <UserPhotoUrlInputField
          source="image_data.mobile_image"
          label="Mobile image"
        />
        <UserPhotoUrlInputField
          source="image_data.web_image"
          label="Web image"
        />
      </FormTab>
      <FormTab label="seo meta" path="seo">
        <TextInput label="Source ID" source="source_id" />
        <TextInput
          label="Source Link"
          source="source_link"
          style={{ width: 800 }}
        />
        <TextInput
          source="seo_data.open_graph_title"
          label="Open Graph Title"
          style={{ width: 800 }}
        />
        <TextInput
          source="seo_data.meta_desc"
          label="Meta Desc"
          style={{ width: 800 }}
        />
        <TextInput
          source="seo_data.html_title"
          label="Html Title"
          style={{ width: 800 }}
        />
        <TextInput
          source="seo_data.open_graph_desc"
          label="Open Graph Desc"
          style={{ width: 800 }}
        />
        <TextInput
          source="seo_data.open_graph_image"
          label="Open Graph Image"
          style={{ width: 800 }}
        />
      </FormTab>
    </TabbedForm>
  </Edit>
));

const validateContent = [required(), minLength(10)];
const createStyle = {
  inline: {
    display: "inline-block"
    // verticalAlign: "top",
    // marginRight: "2em",
    // minWidth: "8em"
  },
  booleanInfo: {
    display: "inline-block"
    // verticalAlign: "top",
    // marginRight: "2em"
  },
  richInput: {
    width: "900px"
  }
};
export const ArticleCreate = withStyles(createStyle)(
  ({ classes, ...props }) => {
    return (
      <Create {...props}>
        <SimpleForm redirect="show" submitOnEnter={false}>
          <TextInput label="Title" source="title" style={{ width: 800 }} />
          <TextInput
            label="Sub Title"
            source="sub_title"
            style={{ width: 800 }}
          />
          <BooleanInput
            source="visibility_guide"
            label={visibilityLabel.guide}
            formClassName={classes.booleanInfo}
          />
          <BooleanInput
            source="visibility_kkday"
            label={visibilityLabel.kkday}
            formClassName={classes.booleanInfo}
          />
          <BooleanInput
            source="visibility_kkhome"
            label={visibilityLabel.kkhome}
            formClassName={classes.booleanInfo}
          />
          <BooleanInput
            source="visibility_kkorder"
            label={visibilityLabel.kkorder}
            formClassName={classes.booleanInfo}
          />
          <RichTextInput
            source="content"
            validate={validateContent}
            toolbar={richTextInputToolbarOptions}
            className={classes.richInput}
          />
        </SimpleForm>
      </Create>
    );
  }
);
