import React from "react";
import { Route } from "react-router-dom";
import Settings from "./settings";
import { ChangeSelfPassword, ChangeOtherPassword } from "./password";

export default [
  <Route exact path="/settings" component={Settings} />,
  <Route exact path="/password" component={ChangeSelfPassword} />,
  <Route exact path="/otherpassword" component={ChangeOtherPassword} />
];
