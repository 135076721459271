import * as algoliasearch from "algoliasearch";

const LOCAL = "LOCAL";
const DEVELOP = "DEVELOP";
const PRODUCTION = "PRODUCTION";

const Config = opt => {
  const { mode = LOCAL, version, build } = opt;
  const manager = {
    mode,
    version,
    build,
    GUIDE_API_HOST() {
      switch (mode) {
        case LOCAL:
          return "http://localhost:5050/test-ca5d4/us-central1";
        case DEVELOP:
          return "https://us-central1-test-ca5d4.cloudfunctions.net";
        case PRODUCTION:
          return "https://us-central1-kkday-guide-app-production.cloudfunctions.net";
        default:
          throw new Error(`Unsupported mode:${mode}`);
      }
    },
    clientKey() {
      switch (mode) {
        case LOCAL:
          return "admin-dev-6DD9-4200-97E0-6D9485F78983";
        case DEVELOP:
          return "admin-dev-6DD9-4200-97E0-6D9485F78983";
        case PRODUCTION:
          return "admin-prod-58F1-47CE-B11A-B46816671968";
        default:
          throw new Error(`Unsupported mode:${mode}`);
      }
    },
    algoliaClient() {
      switch (mode) {
        case LOCAL:
          return algoliasearch(
            "R2S6SULBK6",
            "4038587e7fdbb0c640e7135c815dfa0f"
          );
        case DEVELOP:
          return algoliasearch(
            "R2S6SULBK6",
            "4038587e7fdbb0c640e7135c815dfa0f"
          );
        case PRODUCTION:
          return algoliasearch(
            "TJ3Y4NH1O3",
            "53595b3e0899b10c17731daa9a7ae753"
          );
        default:
          throw new Error(`Unsupported mode:${mode}`);
      }
    },
    resourceEnabled(resourceName) {
      const disableProduction = [];
      switch (mode) {
        case PRODUCTION:
          return disableProduction.indexOf(resourceName) > -1 ? false : true;
        default:
          return true;
      }
    }
  };
  return manager;
};

let mode = LOCAL;

switch (process.env.REACT_APP_ENV) {
  case "local":
    mode = LOCAL;
    break;
  case "dev":
    mode = DEVELOP;
    break;
  case "prod":
    mode = PRODUCTION;
    break;
  default:
    break;
}
const version = "0.5";
const build = "3";
const config = Config({ mode, version, build });

export default config;
