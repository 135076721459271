import React from "react";
// import withStyles from '@material-ui/core/styles/withStyles';
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  ArrayField,
  FunctionField
} from "react-admin";
import { SimpleList } from "../../../components/customize-react-admin";
import {
  UserReferenceField,
  QuestionReferenceField,
  AnswerReferenceField,
  BoardReferenceField,
  LikeReferenceField
} from "../../../components/qa/ReferenceFields";

const actorItemStyle = {
  display: "flex",
  alignItems: "center",
  marginTop: 5,
  marginBottom: 10
};

const actorLabelStyle = { marginRight: 10 };

const ActorsField = props => {
  return (
    <div>
      {props.record.actors.changeByAdmin && (
        <div style={actorItemStyle}>
          <div style={actorLabelStyle}>changeByAdmin: </div>
          <UserReferenceField source="actors.changeByAdmin" {...props} />
        </div>
      )}
      {props.record.actors.admin && (
        <div style={actorItemStyle}>
          <div style={actorLabelStyle}>admin: </div>
          <UserReferenceField source="actors.admin" {...props} />
        </div>
      )}
      {props.record.actors.user && (
        <div style={actorItemStyle}>
          <div style={actorLabelStyle}>user: </div>
          <UserReferenceField source="actors.user" {...props} />
        </div>
      )}
      {props.record.actors.question && (
        <div style={actorItemStyle}>
          <div style={actorLabelStyle}>question: </div>
          <QuestionReferenceField source="actors.question" {...props} />
        </div>
      )}
      {props.record.actors.answer && (
        <div style={actorItemStyle}>
          <div style={actorLabelStyle}>answer: </div>
          <AnswerReferenceField source="actors.answer" {...props} />
        </div>
      )}
      {props.record.actors.board && (
        <div style={actorItemStyle}>
          <div style={actorLabelStyle}>board: </div>
          <BoardReferenceField source="actors.board" {...props} />
        </div>
      )}
      {props.record.actors.like && (
        <div style={actorItemStyle}>
          <div style={actorLabelStyle}>like: </div>
          <LikeReferenceField source="actors.like" {...props} />
        </div>
      )}
    </div>

    // <ul>
    //   {Object.keys(record.actors).map(key => (
    //     <li key={key}>{key}</li>
    //   ))}
    // </ul>
  );
};

ActorsField.defaultProps = {
  addLabel: true
};

const TraceEventField = ({ record }) => {
  const style = {
    wordBreak: "break-all",
    whiteSpace: "pre"
  };
  return <span style={style}>{JSON.stringify(record, null, 2)}</span>;
};

export const EventShow = ({ classes, ...props }) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="summary">
          <TextField source="id" label="ID" />
          <DateField showTime source="time.created" label="建立時間" />
          <TextField source="name" label="事件名稱" />
          <ActorsField label="相關物件" />
          <FunctionField
            label="REQUEST"
            render={record => JSON.stringify(record.request, null, 2)}
          />
        </Tab>
        <Tab label="trace" path="trace">
          <ArrayField source="trace">
            <SimpleList
              primaryText={record => record.name || "n/a"}
              secondaryText={record => <TraceEventField record={record} />}
              linkType={false}
            />
          </ArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
