import React from "react";
import Icon from "@material-ui/icons/PhotoFilter";
import { Resource } from "react-admin";
import { CouponList } from "./coupon-stores";
import { CouponShow, CouponEdit, CouponCreate } from "./coupon-store";

const CouponStoreResource = props => {
  return (
    <Resource
      {...props}
      list={CouponList}
      show={CouponShow}
      edit={CouponEdit}
      create={CouponCreate}
      icon={Icon}
    />
  );
};

export default CouponStoreResource;
