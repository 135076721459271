import React from "react";
import {
  Datagrid,
  TextField,
  DateField,
  Filter,
  TextInput,
  ReferenceInput,
  Link
} from "react-admin";
import { List } from "../../../components/customize-react-admin";
import Pagination from "../../../components/qa/Pagination";
import { AuthorLinkField } from "../../../components/qa/LinkFields";
import { QuestionReferenceField } from "../../../components/qa/ReferenceFields";
import { AuthorAutoCompleteInput } from "../../../components/qa/SearchFields";

const IDLinkField = props => (
  <Link to={`/answers/${props.record.id}/show`}>
    <TextField {...props} />
  </Link>
);

const AnswerFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn style={{ width: 300 }} />
    <ReferenceInput
      label="作者"
      source="author.id"
      reference="users"
      allowEmpty
      alwaysOn
      resettable
    >
      <AuthorAutoCompleteInput />
    </ReferenceInput>
  </Filter>
);

export const AnswerList = props => {
  const perPage = 50;
  const defaultSort = {
    field: "time.created",
    order: "DESC"
  };
  return (
    <List
      {...props}
      title="回答列表"
      perPage={perPage}
      sort={defaultSort}
      pagination={<Pagination />}
      filters={<AnswerFilter />}
    >
      <Datagrid>
        <IDLinkField source="content.text" label="內容" sortable={false} />
        <QuestionReferenceField label="所屬問題" sortable={false} />
        <AuthorLinkField label="作者" size={30} sortable={false} />
        <DateField showTime source="time.created" label="建立時間" />
      </Datagrid>
    </List>
  );
};
