import React from "react";
import Icon from "@material-ui/icons/Subscriptions";
import { Resource } from "react-admin";
import { VideoList } from "./videos";
import { VideoShow, VideoEdit, VideoCreate } from "./video";

const VideoResource = props => {
  return (
    <Resource
      {...props}
      list={VideoList}
      show={VideoShow}
      edit={VideoEdit}
      create={VideoCreate}
      icon={Icon}
    />
  );
};

export default VideoResource;
