import React from "react";
import _ from "lodash";
import { ItemWrapper } from "./shared";

const CardItem = ({ item }) => {
  const { title, name } = item;
  const imageUrl = _.get(item, "image_data.web_image");
  const containerStyle = {
    height: 164,
    width: 125,
    borderRadius: 4,
    overflow: "hidden",
    position: "relative",
    marginRight: 10
  };
  const imageStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    left: 0,
    top: 0
  };
  const overlayStyle = {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(216,216,216,1)",
    background:
      "linear-gradient(180deg, rgba(16,16,18,0) 0%, rgba(16,16,18,0.5) 100%)"
  };
  const titleStyle = {
    color: "rgba(255,255,255,1)",
    fontFamily: "PingFang TC, sans-serif",
    fontSize: "16px",
    fontWeight: "600",
    letterSpacing: "0.67px",
    lineHeight: "16px",
    textAlign: "center",
    width: "100%",
    paddingLeft: 10,
    paddingRight: 10,
    boxSizing: "border-box",
    position: "absolute",
    left: 0,
    bottom: 20
  };
  return (
    <div style={containerStyle}>
      <img src={imageUrl} style={imageStyle} alt="cover" />
      <div style={overlayStyle} />
      <div style={titleStyle}>{title || name}</div>
    </div>
  );
};

const CardTemplateBlock = props => {
  const { title, items } = props;
  const titleStyle = {
    color: "#0F0E11",
    fontFamily: "PingFang TC, sans-serif",
    fontSize: 18,
    fontWeight: 600,
    letterSpacing: "0.9px",
    lineHeight: "18px",
    marginBottom: 10
  };
  const itemContainerStyle = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "norap"
  };
  const style = {
    width: "375px",
    boxSizing: "border-box",
    paddingBottom: 10,
    overflow: "auto"
  };
  return (
    <div style={style}>
      <div style={titleStyle}>{title}</div>
      <div style={itemContainerStyle}>
        {items.map(item => (
          <ItemWrapper
            key={item.id}
            item={item}
            render={record => <CardItem item={record} />}
          />
        ))}
      </div>
    </div>
  );
};

export default CardTemplateBlock;
