import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { auth } from "../../services/firebase";
import { httpClient } from "../../services/data-provider/guide";

class ExportGuideTwToCnButton extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    return (
      <Button variant="contained" color="primary" onClick={this.handleClick}>
        Guide zh-tw Export to zh-cn
      </Button>
    );
  }
}

ExportGuideTwToCnButton.propTypes = {
  onClick: PropTypes.func,
  articleId: PropTypes.string
};

class ExportGuideTwToCn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: ""
    };
  }

  import = () => {
    const { articleId } = this.props;

    this.setState({ result: `processing...` });
    auth.currentUser
      .getIdToken(false)
      .then(token => {
        return httpClient
          .get(`/api/articles/translate/tw-to-cn/${articleId}`, {
            token
          })
          .catch(function(error) {
            let message = "api error !!";
            throw message;
          });
      })
      .then(res => {
        const result = res.data;
        if (result.status === "success") {
          this.setState({
            result: `zh-cn imported : ${result.article.title}`
          });
        } else {
          this.setState({
            result: `failure: ${result.error}`
          });
        }
      })
      .catch(error => {
        this.setState({
          result: `failure: ${error}`
        });
      });
  };

  render() {
    return (
      <div style={{ paddingTop: 10 }}>
        <ExportGuideTwToCnButton onClick={this.import} {...this.props} />
        <span style={{ marginLeft: 10 }}>{this.state.result}</span>
      </div>
    );
  }
}

ExportGuideTwToCn.propTypes = {
  articleId: PropTypes.string
};

export default ExportGuideTwToCn;
