import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { auth } from "../../../services/firebase";
import { httpClient } from "../../../services/data-provider/qa";

class InitializeCountButton extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    return (
      <Button variant="contained" color="primary" onClick={this.handleClick}>
        Count {this.props.resource}
      </Button>
    );
  }
}

InitializeCountButton.propTypes = {
  onClick: PropTypes.func,
  resource: PropTypes.string
};

class InitializeCount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: ""
    };
  }

  count = () => {
    this.setState({ result: "counting..." });
    auth.currentUser
      .getIdToken(false)
      .then(token =>
        httpClient.post(
          `/api/admin/stats/${this.props.resource}/count/initialize`,
          {
            token
          }
        )
      )
      .then(res => {
        const result = res.data;
        if (result.status === "success") {
          this.setState({
            result: `total ${this.props.resource}: ${
              result.stats.content.total
            }`
          });
        } else {
          this.setState({
            result: `failure: ${result.error.message}:`
          });
        }
      });
  };

  render() {
    return (
      <div style={{ paddingTop: 10 }}>
        <InitializeCountButton onClick={this.count} {...this.props} />
        <span style={{ marginLeft: 10 }}>{this.state.result}</span>
      </div>
    );
  }
}

InitializeCount.propTypes = {
  resource: PropTypes.string
};

export default InitializeCount;
