import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  Edit,
  TabbedForm,
  FormTab,
  LongTextInput,
  Create,
  SimpleForm
} from "react-admin";
import {
  LanguageFamilyField,
  LanguageFamilyInput
} from "../../../components/guide/LanguageFamily";
import { UserPhotoUrlInputField } from "../../../components/qa/ImagesInputField";
import {
  ActiveStatusField,
  ActiveStatusInput
} from "../../../components/guide/ActiveStatus";
import { AutocompleteInput, ReferenceInput, ReferenceField } from "react-admin";
import { DateTimeInput } from "react-admin-date-inputs";
import {
  CountryCitySelectInput,
  CountryCitySelectField
} from "../../../components/guide/CountryCitySelect";

const ImageW600 = props => (
  <img src={`${props.record.image}`} className={`${props.className}`} alt="" />
);
const LanguageWarningText = props => (
  <span className={props.className}>
    (若店家提供不同語言優惠券，請依語言建立優惠券)
  </span>
);
const style = {
  info: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "2em",
    minWidth: "8em"
  },
  booleanInfo: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "2em"
  },
  width100pa: {
    width: "100%"
  },
  w90percent: {
    maxWidth: "90%"
  },
  warningText: {
    color: "#f0ad4e",
    "font-size": "0.8rem"
  }
};

export const CouponShow = withStyles(style)(({ classes, ...props }) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="id" label="ID" />
        <ReferenceField
          source="storeId"
          reference="coupon-stores"
          linkType="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <LanguageWarningText className={classes.warningText} />
        <LanguageFamilyField source="lang" label="Language family" />
        <TextField source="title" label="Title" />
        <TextField source="description" label="Description (純文字)" />
        <ActiveStatusField source="status.active" label="Active" />
        <CountryCitySelectField source="cities" label="Destination City" />
        <DateField
          showTime
          source="beginDate"
          label="Begin date"
          className={classes.info}
        />
        <DateField
          showTime
          source="endDate"
          label="End date"
          className={classes.info}
        />
        <ImageW600
          source="image"
          label="Coupon image"
          className={classes.w90percent}
        />
        <DateField
          source="created"
          label="Create date"
          className={classes.info}
          showTime
        />
        <DateField
          source="updated"
          label="Last Modify"
          className={classes.info}
          showTime
        />
      </Tab>
    </TabbedShowLayout>
  </Show>
));

const validateCouponEdit = values => {
  const errors = { status: {} };

  if (!values.storeId) {
    errors.storeId = ["This is required"];
  }
  // title check
  if (!values.title) {
    errors.title = ["This is required"];
  }
  // publish check
  if (values.status && values.status.active === true) {
    let errorMessage = [];
    // start date check
    if (!values.beginDate) {
      errors.beginDate = ["This is required"];
    }
    // end date check
    if (!values.endDate) {
      errors.endDate = ["This is required"];
    }
    // language family check
    if (!values.lang) {
      errors.lang = ["This is required"];
    }

    // area check
    if (!(values.cities || []).length) {
      errorMessage.push("Destination is required");
      errors.cities = ["This is required"];
    }
    if (!values.image) {
      errorMessage.push("Coupon image required");
      errors.image = ["This is required"];
    }

    if (errorMessage.length) {
      errors.active = ["** " + errorMessage.join(" ** ")];
    }
  }

  return errors;
};

export const CouponEdit = withStyles(style)(({ classes, ...props }) => (
  <Edit {...props}>
    <TabbedForm
      redirect="show"
      submitOnEnter={false}
      validate={validateCouponEdit}
    >
      <FormTab label="summary">
        <TextField source="id" label="ID" />
        <ReferenceInput
          label="Coupon Store"
          source="storeId"
          reference="coupon-stores"
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <LanguageWarningText className={classes.warningText} />
        <LanguageFamilyInput source="lang" label="Language family" />
        <LongTextInput source="title" label="Title" />
        <LongTextInput source="description" label="Description (純文字)" />
        <ActiveStatusInput source="status.active" label="Active" />
        <CountryCitySelectInput source="cities" label="Destination City" />
        <DateTimeInput
          source="beginDate"
          label="Start date"
          options={{
            format: "yyyy/MM/dd, HH:mm:ss",
            minDate: new Date(),
            showTodayButton: true
          }}
        />
        <DateTimeInput
          source="endDate"
          label="End date"
          options={{
            format: "yyyy/MM/dd, HH:mm:ss",
            minDate: new Date()
          }}
        />
        <UserPhotoUrlInputField source="image" label="Coupon Image" />
      </FormTab>
    </TabbedForm>
  </Edit>
));

const createStyle = {
  ...style,
  inline: {
    display: "inline-block"
    // verticalAlign: "top",
    // marginRight: "2em",
    // minWidth: "8em"
  },
  booleanInfo: {
    display: "inline-block"
    // verticalAlign: "top",
    // marginRight: "2em"
  }
};

const validateCouponCreate = values => {
  const errors = { status: {} };
  if (!values.storeId) {
    errors.storeId = ["This is required"];
  }
  // title check
  if (!values.title) {
    errors.title = ["This is required"];
  }

  // publish check
  if (values.status && values.status.active === true) {
    // start date check
    if (!values.beginDate) {
      errors.beginDate = ["This is required"];
    }
    // end date check
    if (!values.endDate) {
      errors.endDate = ["This is required"];
    }
    // language family check
    if (!values.lang) {
      errors.lang = ["This is required"];
    }
    if (!values.image) {
      errors.active = ["Coupon image required"];
    }
  }

  return errors;
};

export const CouponCreate = withStyles(createStyle)(({ classes, ...props }) => {
  return (
    <Create {...props}>
      <SimpleForm
        redirect="show"
        submitOnEnter={false}
        validate={validateCouponCreate}
      >
        <ReferenceInput
          label="Coupon Store"
          source="storeId"
          reference="coupon-stores"
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <LanguageWarningText className={classes.warningText} />
        <LanguageFamilyInput source="lang" label="Language family" />
        <LongTextInput source="title" label="Title" />
        <LongTextInput source="description" label="Description (純文字)" />
        <ActiveStatusInput source="status.active" label="Active" />
        <CountryCitySelectInput source="cities" label="Destination City" />
        <DateTimeInput
          source="beginDate"
          label="Start date"
          options={{
            format: "yyyy/MM/dd, HH:mm:ss",
            minDate: new Date(),
            showTodayButton: true
          }}
        />
        <DateTimeInput
          source="endDate"
          label="End date"
          options={{
            format: "yyyy/MM/dd, HH:mm:ss",
            minDate: new Date()
          }}
        />
        <UserPhotoUrlInputField source="image" label="Coupon Image" />
      </SimpleForm>
    </Create>
  );
});
