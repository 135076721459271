import { GET_LIST } from "react-admin";

const QuestionSearchProvider = opt => {
  const { config } = opt;
  if (!config) {
    throw new Error("Missing config");
  }
  const client = config.algoliaClient();
  const index = client.initIndex("questions");
  const provider = {
    config,
    client,
    index,
    handle(type, params) {
      switch (type) {
        case GET_LIST:
          return provider.list(params);
        default:
          throw new Error(`Unsupported action type: ${type}`);
      }
    },
    list(params) {
      if (!params.filter.q) {
        throw new Error(`Missing search filter param: ${params.filter}`);
      }
      const searchPromise = new Promise((resolve, reject) => {
        provider.index.search({ query: params.filter.q }, (err, content) => {
          if (err) {
            reject(err);
            return;
          }
          let questions = content.hits;
          if (params.filter) {
            if (params.filter.board && params.filter.board.id) {
              questions = questions.filter(
                q => q.board.id === params.filter.board.id
              );
            }
          }
          const response = {
            data: questions,
            total: questions.length
          };
          resolve(response);
        });
      });
      return searchPromise;
    }
  };
  return provider;
};

export default QuestionSearchProvider;
