import React from "react";
import Avatar from "@material-ui/core/Avatar";

const UserPhotoField = ({ record, size }) => (
  <Avatar
    src={`${record.photoUrl}?size=${size}x${size}`}
    size={size}
    style={{ width: size, height: size }}
  />
);

UserPhotoField.defaultProps = {
  size: 25
};

const UserPhotoDisplayNameField = ({ record = {}, size }) => (
  <div style={{ display: "flex", flexWrap: "nowrap", alignItems: "center" }}>
    <UserPhotoField record={record} size={size} />
    <div style={{ marginLeft: 10 }}>{record.displayName}</div>
  </div>
);

UserPhotoDisplayNameField.defaultProps = {
  source: "displayName",
  label: "用戶"
};

export { UserPhotoField, UserPhotoDisplayNameField };
