import _ from "lodash";
import { stringify } from "query-string";
import { GET_LIST, GET_ONE, GET_MANY, UPDATE } from "react-admin";
// import { auth } from "../../firebase";
import { QA_API_ERROR } from "../../util";

const CityProvider = opt => {
  const { config, httpClient } = opt;
  if (!config) {
    throw new Error("Missing config");
  }
  if (!httpClient) {
    throw new Error("Missing httpClient");
  }
  const provider = {
    handle(type, params) {
      console.log(type);
      switch (type) {
        case GET_LIST:
          return provider.list(params);
        // case GET_MANY_REFERENCE:
        //   return provider.list(params);
        case GET_ONE:
          return provider.get(params);
        case GET_MANY:
          return provider.getMany(params);
        // case CREATE:
        //   return provider.create(params);
        case UPDATE:
          return provider.update(params);
        // case DELETE:
        //   return provider.delete(params);
        // case DELETE_MANY:
        //   return provider.deleteMany(params);
        default:
          throw new Error(`Unsupported action type: ${type}`);
      }
    },
    get(params) {
      return httpClient
        .get(`/api/cities/${params.id}`)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("KkblogProvider.get Error", result, params);
          }
          // something happen
          // object array convert
          const tmpLang = [];
          _.forEach(result.city.lang_setting, (eValue, eKey) => {
            if (eValue) tmpLang.push(eKey);
          });
          result.city.lang_setting = _.get(tmpLang, 0, "");

          let response = {
            data: result.city
          };
          return response;
        });
    },
    list(params) {
      const query = {
        order: params.sort.field,
        sort: params.sort.order.toLowerCase(),
        limit: params.pagination.perPage
      };
      // filter q
      if (params.filter.q) {
        query.q = params.filter.q.trim();
      } // default
      return httpClient
        .get(`/api/cities?${stringify(query)}`)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("KkblogProvider.list Error", result, params);
          }
          let response = {
            data: result.cities || null,
            total: result.cities.length || 0
          };
          return response;
        });
    },
    getMany(params) {
      const query = {
        ids: params.ids
      };
      return httpClient
        .get(`/api/cities/many?${stringify(query)}`)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("CitiesProvider.getMany Error", result, params);
          }
          const response = {
            data: result.cities || null
          };
          return response;
        });
    },
    update(params) {
      const payload = {
        brief_guide: params.data.brief_guide,
        lang_setting: params.data.lang_setting
          ? { [params.data.lang_setting]: true }
          : {},
        religion: params.data.religion,
        voltage: params.data.voltage,
        currency: params.data.currency,
        time_zone: params.data.time_zone,
        sort: parseInt(params.data.sort, 10) || 0,
        seo_data: {
          open_graph_title: params.data.seo_data.open_graph_title || "",
          meta_desc: params.data.seo_data.meta_desc || "",
          html_title: params.data.seo_data.html_title || "",
          open_graph_desc: params.data.seo_data.open_graph_desc || "",
          open_graph_image: params.data.seo_data.open_graph_image || ""
        },
        image_data: {
          android_image: params.data.image_data.android_image || "",
          mobile_image: params.data.image_data.mobile_image || "",
          web_image: params.data.image_data.web_image || "",
          ios_image: params.data.image_data.ios_image || ""
        },
        openweatherMeta: {
          isSync: _.get(params.data, "openweatherMeta.isSync", false),
          name: _.get(params.data, "openweatherMeta.name", ""),
          id: _.get(params.data, "openweatherMeta.id", ""),
          coordinate: {
            lon: _.get(params.data, "openweatherMeta.coordinate.lon", ""),
            lat: _.get(params.data, "openweatherMeta.coordinate.lat", "")
          },
          country: _.get(params.data, "openweatherMeta.country", "")
        }
      };

      return httpClient
        .put(`/api/cities/${params.id}`, payload)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("CitiesProvider.update Error", result, params);
          }
          const response = {
            data: result.city
          };
          return response;
        });
    }
  };
  return provider;
};

export default CityProvider;
