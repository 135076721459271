import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
// import App from './components/App';
import ReactAdminApp from "./react-admin/App";
// import { unregister } from "./registerServiceWorker";
import registerServiceWorker from "./registerServiceWorker";

ReactDOM.render(<ReactAdminApp />, document.getElementById("root"));
// Opting Out of Caching
// unregister();
registerServiceWorker();
