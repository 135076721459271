import _ from "lodash";
import { stringify } from "query-string";
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  UPDATE,
  CREATE,
  DELETE
} from "react-admin";
// import { auth } from "../../firebase";
import { QA_API_ERROR, objectKey2Array, arrayInput2Object } from "../../util";

const CouponProvider = opt => {
  const { config, httpClient } = opt;
  if (!config) {
    throw new Error("Missing config");
  }
  if (!httpClient) {
    throw new Error("Missing httpClient");
  }

  const responseFromat = inResponse => {
    inResponse.data.cities = objectKey2Array(inResponse.data.cities, "city");
    return inResponse;
  };

  const provider = {
    handle(type, params) {
      console.log(type);
      switch (type) {
        case GET_LIST:
          return provider.list(params);
        // case GET_MANY_REFERENCE:
        //   return provider.list(params);
        case GET_ONE:
          return provider.get(params);
        case GET_MANY:
          return provider.getMany(params);
        case CREATE:
          return provider.create(params);
        case UPDATE:
          return provider.update(params);
        case DELETE:
          return provider.delete(params);
        // case DELETE_MANY:
        //   return provider.deleteMany(params);
        default:
          throw new Error(`Unsupported action type: ${type}`);
      }
    },
    get(params) {
      return httpClient
        .get(`/api/coupons/${params.id}`)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("CouponProvider.get Error", result, params);
          }
          let response = {
            data: result.coupon
          };

          return responseFromat(response);
        });
    },
    list(params) {
      const query = {
        order: params.sort.field,
        sort: params.sort.order.toLowerCase(),
        limit: params.pagination.perPage
      };
      // filter active
      if (params.filter.active || params.filter.active === false) {
        console.log(params.filter.active);
        query.active = params.filter.active;
        query.sort = "active";
      }
      // filter Language family
      if (params.filter.lang) {
        query.lang = params.filter.lang;
        query.sort = "lang";
      }
      // filter cities
      if (params.filter.cities) {
        const tmpArr = [];
        params.filter.cities.forEach(item => {
          if (item.city) {
            tmpArr.push(item.city);
          }
        });
        query.cities = tmpArr.join(",");
      }
      // default
      return httpClient
        .get(`/api/coupons?${stringify(query)}`)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("CouponProvider.list Error", result, params);
          }
          result.coupons = _.chain(result.coupons)
            .map(coupon => {
              return coupon;
            })
            .orderBy(["updated", "title"], ["desc", "asc"])
            .value();
          return result;
        })
        .then(result => {
          let response = {
            data: result.coupons || null,
            total: result.coupons.length || 0
          };
          return response;
        });
    },
    getMany(params) {
      const query = {
        ids: params.ids
      };
      return httpClient
        .get(`/api/coupons/many?${stringify(query)}`)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("CouponProvider.getMany Error", result, params);
          }
          return _.chain(_.get(result, "coupons", null))
            .map(coupon => {
              return responseFromat(coupon);
            })
            .value();
        })
        .then(coupons => {
          return {
            data: coupons || null
          };
        });
    },
    update(params) {
      const payload = {
        storeId: params.data.storeId,
        title: params.data.title,
        description: params.data.description,
        image: params.data.image,
        lang: params.data.lang,
        cities: arrayInput2Object(params.data.cities, "city"),
        status: {
          active: params.data.status.active
        },
        beginDate: params.data.beginDate,
        endDate: params.data.endDate
      };

      return httpClient
        .put(`/api/coupons/${params.id}`, payload)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("CouponProvider.update Error", result, params);
          }
          const response = {
            data: result.coupon
          };
          return responseFromat(response);
        });
    },
    create(params) {
      const payload = {
        storeId: params.data.storeId,
        title: params.data.title,
        description: params.data.description,
        image: params.data.image,
        lang: params.data.lang,
        cities: arrayInput2Object(params.data.cities, "city"),
        status: {
          active: params.data.status.active
        },
        beginDate: params.data.beginDate,
        endDate: params.data.endDate
      };

      return httpClient
        .post(`/api/coupons`, payload)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("CouponProvider.create Error", result, params);
          }
          const response = {
            data: result.coupon
          };
          return responseFromat(response);
        });
    },
    delete(params) {
      return httpClient
        .delete(`/api/coupons/${params.id}`)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("CouponProvider.delete Error", result, params);
          }
          const response = {
            data: { id: result.id }
          };
          return response;
        });
    }
  };
  return provider;
};

export default CouponProvider;
