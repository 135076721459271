import React from "react";
import {
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  Link
} from "react-admin";
import { List } from "../../../components/customize-react-admin";
import Pagination from "../../../components/qa/Pagination";

const IDLinkField = props => (
  <Link to={`/boards/${props.record.id}/show`}>
    <TextField {...props} />
  </Link>
);

export const BoardList = props => {
  const perPage = 50;
  const defaultSort = {
    field: "time.created",
    order: "DESC"
  };
  return (
    <List
      {...props}
      title="討論版列表"
      perPage={perPage}
      sort={defaultSort}
      pagination={<Pagination />}
    >
      <Datagrid>
        <IDLinkField source="id" label="ID" sortable={false} />
        <TextField source="name" label="名稱" sortable={false} />
        <TextField source="question.count" label="問題數" sortable={false} />
        <BooleanField source="status.open" label="開放" sortable={false} />
        <DateField source="time.created" label="建立時間" />
      </Datagrid>
    </List>
  );
};
