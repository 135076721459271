import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  ImageField,
  RichTextField,
  FunctionField,
  SimpleForm,
  Toolbar,
  SaveButton
} from "react-admin";
import ImportKKblog from "../../../components/guide/ImportKKblogButton";

import { KKdayPostLoadButton } from "../../../components/guide/KKdayPostLoadButton";

const style = {
  info: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "2em",
    minWidth: "8em"
  },
  booleanInfo: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "2em"
  }
};

const PostCreateToolbar = props => (
  <Toolbar {...props}>
    <SaveButton
      label="Import other KKday Blog Id"
      redirect={false}
      submitOnEnter={false}
      style={{ display: "none" }}
    />
  </Toolbar>
);

export const KkblogShow = withStyles(style)(({ classes, ...props }) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="id" label="ID" />
        <DateField
          showTime
          source="date"
          label="建立時間"
          className={classes.info}
        />
        <DateField
          showTime
          source="modified"
          label="編輯時間"
          className={classes.info}
        />
        <RichTextField source="content" label="內容" />
      </Tab>
      <Tab label="images" path="images">
        <ImageField source="image" />
      </Tab>
      <Tab label="Import" path="import">
        <TextField source="title" label="Title" />

        <FunctionField
          label="Import action"
          addLabel={false}
          render={record => <ImportKKblog postId={record.id} lang="en" />}
        />
      </Tab>
      <Tab label="Import Other" path="importOther">
        <SimpleForm toolbar={<PostCreateToolbar />} submitOnEnter={false}>
          <KKdayPostLoadButton
            source="KKdayBlogPostId"
            label="KKday Blog post ID"
            lang="en"
          />
        </SimpleForm>
      </Tab>
    </TabbedShowLayout>
  </Show>
));
