import React from "react";
import { Datagrid, TextField, DateField, Link, Filter } from "react-admin";
import { List } from "../../../components/customize-react-admin";
import Pagination from "../../../components/qa/Pagination";
import {
  CategoryTypesField,
  CategoryTypesInput
} from "../../../components/guide/CategoryTypes";
import {
  LanguageFamilyField,
  LanguageFamilyInput
} from "../../../components/guide/LanguageFamily";

const IDLinkField = props => (
  <Link to={`/categories/${props.record.id}/show`}>
    <TextField {...props} />
  </Link>
);

const CategoryFilter = props => (
  <Filter {...props}>
    <CategoryTypesInput source="type" label="Type" alwaysOn />
    <LanguageFamilyInput source="lang" label="Language family" alwaysOn />
  </Filter>
);

export const CategoryList = props => {
  const perPage = 1000;
  const defaultSort = {
    field: "type",
    order: "ASC"
  };
  return (
    <List
      {...props}
      title="Category list"
      perPage={perPage}
      sort={defaultSort}
      pagination={<Pagination />}
      filters={<CategoryFilter />}
    >
      <Datagrid>
        <IDLinkField source="name" label="Name" sortable={false} />
        <CategoryTypesField source="type" label="Type" sortable={false} />
        <LanguageFamilyField
          source="lang"
          label="Language family"
          sortable={false}
        />
        <DateField
          showTime
          source="modify_date"
          label="Last Modify"
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};
