import React from "react";
import { FunctionField, SelectField, SelectInput } from "react-admin";

// | apply_status | string | The status of verification. default is '0'| {0: not reviewed yet, 1: approved, 2: unapproved } |
const arrayList = [
  { id: "0", name: "not reviewed yet" },
  { id: "1", name: "approved" },
  { id: "2", name: "unapproved" }
];

const ApplyStatusField = props => (
  <FunctionField
    {...props}
    render={() => <SelectField choices={arrayList} {...props} />}
  />
);
ApplyStatusField.defaultProps = {
  addLabel: true
};

const ApplyStatusInput = props => (
  <FunctionField
    {...props}
    render={() => <SelectInput choices={arrayList} {...props} />}
  />
);
ApplyStatusInput.defaultProps = {};

export { ApplyStatusField, ApplyStatusInput };
