import React from "react";
import {
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  Link,
  CardActions,
  CreateButton,
  RefreshButton
} from "react-admin";
import { List } from "../../../components/customize-react-admin";
import Pagination from "../../../components/qa/Pagination";
import TaskContentField from "../../../components/qa/TaskContentField";
import { TaskAuthorReferenceField } from "../../../components/qa/ReferenceFields";
import { CreatorLinkField } from "../../../components/qa/LinkFields";
import { ProcessNowButton } from "./actions";

const ScheduleActions = ({
  basePath,
  displayedFilters,
  filters,
  filterValues,
  resource,
  showFilter
}) => (
  <CardActions>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button"
      })}
    <CreateButton basePath={basePath} />
    <RefreshButton />
    <ProcessNowButton />
  </CardActions>
);

const IDLinkField = props => (
  <Link to={`/schedules/${props.record.id}/show`}>
    <TextField {...props} />
  </Link>
);

export const ScheduleList = props => {
  const perPage = 50;
  const defaultSort = {
    field: "time.created",
    order: "DESC"
  };
  return (
    <List
      {...props}
      title="排程列表"
      perPage={perPage}
      sort={defaultSort}
      pagination={<Pagination />}
      actions={<ScheduleActions />}
    >
      <Datagrid>
        <IDLinkField source="id" label="ID" sortable={false} />
        <DateField
          showTime
          source="schedule.time.time"
          label="排程時間"
          sortable={false}
        />
        <CreatorLinkField label="建立者" size={30} sortable={false} />
        <TextField source="task.type" label="類型" sortable={false} />
        <TaskAuthorReferenceField label="發表者" sortable={false} />
        <TaskContentField label="內容" sortable={false} />
        <BooleanField
          source="status.executed"
          label="已執行"
          sortable={false}
        />
        <TextField
          source="result.lastRun.result.status"
          label="執行結果"
          sortable={false}
        />
        <DateField showTime source="time.created" label="建立時間" />
      </Datagrid>
    </List>
  );
};
