import React from "react";
import { ReferenceField, FunctionField, TextField } from "react-admin";

const LikeTargetField = props => (
  <FunctionField
    {...props}
    render={record =>
      record.target.type === "question" ? (
        <ReferenceField
          {...props}
          label="問題"
          source="target.id"
          reference="questions"
          sortable={false}
        >
          <TextField source="title" />
        </ReferenceField>
      ) : (
        <ReferenceField
          {...props}
          label="回答"
          source="target.id"
          reference="answers"
          sortable={false}
        >
          <TextField source="content.text" />
        </ReferenceField>
      )
    }
  />
);

LikeTargetField.defaultProps = {
  addLabel: true
};

export default LikeTargetField;
