import React from "react";
import _ from "lodash";
import { stringify } from "query-string";
import timeago from "timeago.js";
import compose from "recompose/compose";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import RefreshIcon from "@material-ui/icons/Refresh";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { translate } from "react-admin";
import { UserPhotoDisplayNameField } from "../../components/qa/UserFields";
import { httpClient } from "../../services/data-provider/qa";

const DataSource = {
  data: {
    questions: null
  },
  internal: {
    listeners: [],
    setQuestion(questions) {
      DataSource.data.questions = questions;
      DataSource.internal.notifyListeners();
    },
    loadQuestion() {
      const query = {
        limit: 5,
        order: "time.created",
        sort: "DESC"
      };
      httpClient
        .get(`/api/questions?${stringify(query)}`)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw new Error(
              `Error get latest questions: ${result.error.message}`
            );
          }
          DataSource.internal.setQuestion(result.questions);
        });
    },
    notifyListeners() {
      _.forEach(DataSource.internal.listeners, fn => fn());
    }
  },
  getQuestions() {
    if (!DataSource.data.questions) {
      DataSource.internal.loadQuestion();
    }
    return DataSource.data.questions;
  },
  refresh() {
    DataSource.internal.loadQuestion();
  },
  addChangeListener(listener) {
    DataSource.internal.listeners.push(listener);
  },
  removeChangeListener(listener) {
    _.remove(DataSource.internal.listeners, item => item === listener);
  }
};

const style = theme => ({
  root: {
    flex: 1
  },
  date: {
    marginRight: "1em",
    color: theme.palette.text.primary
  },
  board: {
    marginRight: "1em",
    textAlign: "right"
  }
});

const timeagoInstance = timeago();

const LatestQuestions = ({ questions, translate, classes }) => {
  let listItems = (
    <ListItem key="loading">
      <ListItemText primary="讀取中" />
    </ListItem>
  );
  if (questions) {
    listItems = questions.map(question => (
      <ListItem
        key={question.id}
        button
        component={Link}
        to={`/questions/${question.id}/show`}
      >
        <ListItemText
          disableTypography={true}
          primary={
            <Typography variant="subheading">{question.title}</Typography>
          }
          secondary={
            <UserPhotoDisplayNameField record={question.author} size={30} />
          }
        />
        <ListItemSecondaryAction>
          <span className={classes.date}>
            {timeagoInstance.format(question.time.created, "zh_TW")}
          </span>
        </ListItemSecondaryAction>
      </ListItem>
    ));
  }
  return (
    <Card className={classes.root}>
      <CardHeader
        title={translate("qaAdmin.dashboard.latestQuestions")}
        action={
          <IconButton>
            <RefreshIcon
              onClick={() => {
                DataSource.refresh();
              }}
            />
          </IconButton>
        }
      />
      <List dense={true}>{listItems}</List>
    </Card>
  );
};

const enhance = compose(
  withStyles(style),
  translate
);

const EnhancedLatestQuestions = enhance(LatestQuestions);

class LatestQuestionsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      questions: DataSource.getQuestions()
    };
  }
  componentDidMount() {
    DataSource.addChangeListener(this.handleChange);
  }
  componentWillUnmount() {
    DataSource.removeChangeListener(this.handleChange);
  }
  handleChange() {
    this.setState({
      questions: DataSource.getQuestions()
    });
  }
  render() {
    return <EnhancedLatestQuestions questions={this.state.questions} />;
  }
}

export default LatestQuestionsComponent;
