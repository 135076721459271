import { RUN_SCHEDULE, PROCESS_SCHEDULES } from "../../../../services/actions";

export const runSchedule = (id, data, basePath) => {
  return {
    type: RUN_SCHEDULE,
    payload: {
      id
    },
    meta: {
      resource: "schedules",
      fetch: RUN_SCHEDULE,
      refresh: true,
      onSuccess: {
        notification: {
          body: "schedule run success",
          level: "info"
        }
      },
      onFailure: {
        notification: {
          body: "schedule run failure",
          level: "warning"
        }
      }
    }
  };
};

export const processSchedules = () => {
  return {
    type: PROCESS_SCHEDULES,
    payload: {},
    meta: {
      resource: "schedules",
      fetch: PROCESS_SCHEDULES,
      refresh: true,
      onSuccess: {
        notification: {
          body: "schedules process success",
          level: "info"
        }
      },
      onFailure: {
        notification: {
          body: "schedules process failure",
          level: "warning"
        }
      }
    }
  };
};
