import React from "react";
import { Admin } from "react-admin";
import dataProvider from "./services/data-provider";
import authProvider from "./services/auth-provider";
import chtMessages from "./i18n/tw";
import Dashboard from "./dashboard";
import customRoutes from "./routes";
import customLayout from "./layout";
import { LogoutButton } from "./components/customize-react-admin";
import {
  QuestionResource,
  AnswerResource,
  UserResource,
  BoardResource,
  EventResource,
  LikeResource,
  ReportResource,
  ScheduleResource,
  NotificationResource,
  AdminResource
} from "./resources/qa";
import {
  KkblogResource,
  KkblogKoResource,
  KkblogJaResource,
  KkblogEnResource,
  KkblogMyResource,
  KkblogThResource,
  KkblogViResource,
  ArticleResource,
  VideoResource,
  CityResource,
  TemplateBlockResource,
  TemplateResource,
  CategoryResource,
  SettingResource,
  CouponStoreResource,
  CouponResource,
  FeedbackResource
} from "./resources/guide";
import { GuideConfig } from "./config";

// initialize error reporting services
import "./services/error-reporting";

const i18nProvider = locale => {
  // fallback / default
  return chtMessages;
};

const App = () => {
  const qaResources = [
    <QuestionResource name="questions" />,
    <AnswerResource name="answers" />,
    <BoardResource name="boards" />,
    <UserResource name="users" />,
    <NotificationResource name="notifications" />,
    <LikeResource name="likes" />,
    <ReportResource name="reports" />,
    <ScheduleResource name="schedules" />,
    <EventResource name="events" />,
    <AdminResource name="admins" />
  ];
  const guideResources = [
    <ArticleResource name="articles" />,
    <VideoResource name="videos" />,
    <CityResource name="cities" />,
    <CategoryResource name="categories" />,
    <KkblogResource name="kkblogs" options={{ label: "KKDay Blog" }} />,
    <KkblogKoResource name="kkblogs-ko" options={{ label: "Blog Korea" }} />,
    <KkblogJaResource name="kkblogs-ja" options={{ label: "Blog Japan" }} />,
    <KkblogEnResource name="kkblogs-en" options={{ label: "Blog English" }} />,
    <KkblogMyResource name="kkblogs-my" options={{ label: "Blog Malaysia" }} />,
    <KkblogThResource name="kkblogs-th" options={{ label: "Blog ไทย" }} />,
    <KkblogViResource
      name="kkblogs-vi"
      options={{ label: "Blog Tiếng Việt" }}
    />,

    <CouponResource name="coupons" />,
    <CouponStoreResource name="coupon-stores" />,

    <TemplateBlockResource
      name="template-blocks"
      options={{ label: "Template Blocks" }}
    />,
    <TemplateResource name="templates" />,
    <FeedbackResource name="feedbacks" />,
    <SettingResource
      name="guide-settings"
      options={{ label: "Guide Settings" }}
    />
  ].filter(r => GuideConfig.resourceEnabled(r.props.name));
  const resources = qaResources.concat(guideResources);
  return (
    <Admin
      title={`QA Backend (${process.env.REACT_APP_ENV})`}
      dashboard={Dashboard}
      dataProvider={dataProvider}
      authProvider={authProvider}
      locale="tw"
      i18nProvider={i18nProvider}
      appLayout={customLayout}
      customRoutes={customRoutes}
      logoutButton={LogoutButton}
    >
      {/* give each resource a key props so react doesnt complain */}
      {resources.map(r => React.cloneElement(r, { key: r.props.name }))}
    </Admin>
  );
};

// const App = () => (
//   <Admin
//     title={`QA Backend (${process.env.REACT_APP_ENV})`}
//     dashboard={Dashboard}
//     dataProvider={dataProvider}
//     authProvider={authProvider}
//     locale="tw"
//     i18nProvider={i18nProvider}
//     appLayout={customLayout}
//     customRoutes={customRoutes}
//     logoutButton={LogoutButton}
//   >
//     <QuestionResource name="questions" />
//     <AnswerResource name="answers" />
//     <BoardResource name="boards" />
//     <UserResource name="users" />
//     <NotificationResource name="notifications" />
//     <LikeResource name="likes" />
//     <ReportResource name="reports" />
//     <ScheduleResource name="schedules" />
//     <EventResource name="events" />
//     <AdminResource name="admins" />
//     <KkblogResource name="kkblogs" />
//   </Admin>
// );

export default App;
