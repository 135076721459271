import axios from "axios";
import platform from "platform";
import QuestionProvider from "./question";
import AnswerProvider from "./answer";
import UserProvider from "./user";
import BoardProvider from "./board";
import EventProvider from "./event";
import LikeProvider from "./like";
import ReportProvider from "./report";
import ScheduleProvider from "./schedule";
import AdminProvider from "./admin";
import NotificationProvider from "./notification";
import {
  QuestionSearchProvider,
  AnswerSearchProvider,
  UserSearchProvider
} from "./algolia";
import { QAConfig as config } from "../../../config";

const httpClient = axios.create({
  baseURL: config.ROOT_URL(),
  headers: {
    "client-key": config.clientKey(),
    "kkguide-platform": "web",
    "kkguide-appbuild": config.build,
    "kkguide-appversion": config.version,
    "kkguide-osversion": platform.description || "",
    "kkguide-systemlang":
      window.navigator.userLanguage || window.navigator.language || "n/a"
  }
});

const opt = {
  config,
  httpClient
};

const questionProvider = QuestionProvider(opt);
const answerProvider = AnswerProvider(opt);
const userProvider = UserProvider(opt);
const boardProvider = BoardProvider(opt);
const eventProvider = EventProvider(opt);
const likeProvider = LikeProvider(opt);
const reportProvider = ReportProvider(opt);
const scheduleProvider = ScheduleProvider(opt);
const adminProvider = AdminProvider(opt);
const notificationProvider = NotificationProvider(opt);

const questionSearchProvider = QuestionSearchProvider(opt);
const answerSearchProvider = AnswerSearchProvider(opt);
const userSearchProvider = UserSearchProvider(opt);

export {
  httpClient,
  questionProvider,
  answerProvider,
  userProvider,
  boardProvider,
  eventProvider,
  likeProvider,
  reportProvider,
  scheduleProvider,
  adminProvider,
  notificationProvider,
  questionSearchProvider,
  answerSearchProvider,
  userSearchProvider
};
