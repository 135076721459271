import React from "react";
import { Datagrid, TextField, DateField, Link } from "react-admin";
import { List } from "../../../components/customize-react-admin";
import Pagination from "../../../components/qa/Pagination";
import {
  UserLinkField,
  AddedByUserLinkField
} from "../../../components/qa/LinkFields";

const IDLinkField = props => (
  <Link to={`/admins/${props.record.id}/show`}>
    <TextField {...props} />
  </Link>
);

export const AdminList = props => {
  const perPage = 50;
  const defaultSort = {
    field: "time.created",
    order: "DESC"
  };
  return (
    <List
      {...props}
      title="Admin列表"
      perPage={perPage}
      sort={defaultSort}
      pagination={<Pagination />}
    >
      <Datagrid>
        <IDLinkField source="id" label="ID" sortable={false} />
        <UserLinkField label="User" sortable={false} />
        <AddedByUserLinkField label="添加者" sortable={false} />
        <DateField showTime source="time.created" label="建立時間" />
      </Datagrid>
    </List>
  );
};
