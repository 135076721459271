import React from "react";
import _ from "lodash";
import { FunctionField } from "react-admin";
import CardTemplateBlock from "./Card";
import LargeImageItemBlock from "./LargeImage";
import SmallImageItemBlock from "./SmallImage";
import ListItemBlock from "./List";

const TemplateBlockPreviewField = props => {
  const render = record => {
    const items = _.chain(record.items)
      .values()
      .orderBy(["sort"], ["asc"])
      .value();
    switch (record.layout) {
      case 1:
        return <LargeImageItemBlock title={record.title} items={items} />;
      case 2:
        return <SmallImageItemBlock title={record.title} items={items} />;
      case 3:
        return <CardTemplateBlock title={record.title} items={items} />;
      case 4:
        return <ListItemBlock title={record.title} items={items} />;
      default:
        return <div>not available</div>;
    }
  };
  return <FunctionField render={render} {...props} />;
};

TemplateBlockPreviewField.defaultProps = {
  addLabel: true,
  label: "preview"
};

export {
  LargeImageItemBlock,
  SmallImageItemBlock,
  CardTemplateBlock,
  ListItemBlock,
  TemplateBlockPreviewField
};
