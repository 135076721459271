import Raven from "raven-js";
import { auth, GetCurrentFireBaseUser, signIn } from "../firebase";
import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_CHECK,
  AUTH_GET_PERMISSIONS
} from "react-admin";

const authProviderFn = (type, params) => {
  console.log("authProviderFn: 112233", type, params);
  switch (type) {
    case AUTH_LOGIN:
      const { username, password } = params;
      return signIn(username, password);
    case AUTH_LOGOUT:
      return auth.signOut();
    case AUTH_ERROR:
      const error = params;
      Raven.captureException(error, {
        user: auth.currentUser
          ? { id: auth.currentUser.uid, email: auth.currentUser.email }
          : null,
        extra: {
          params: error.params || null
        }
      });
      return Promise.resolve();
    case AUTH_CHECK:
      return GetCurrentFireBaseUser().then(() => {
        if (auth.currentUser) {
          return Promise.resolve();
        } else {
          return Promise.reject();
        }
      });
    case AUTH_GET_PERMISSIONS:
      return Promise.resolve();
    default:
      return Promise.reject("Unkown method");
  }
};

export default authProviderFn;
