import React from "react";
import { FunctionField, SelectField, SelectInput } from "react-admin";

const arrayList = [
  { id: "zh-tw", name: "繁體中文(台灣)" },
  { id: "zh-my", name: "简体中文(Malaysia)" },
  { id: "ja", name: "日本語" },
  { id: "ko", name: "한국어" },
  { id: "en", name: "English" },
  { id: "th", name: "ไทย(泰國)" },
  { id: "vi", name: "Tiếng Việt(越南)" },
  { id: "zh-hk", name: "繁體中文(香港)" },
  { id: "zh-cn", name: "简体中文(中国)" }
];

const LanguageFamilyField = props => (
  <FunctionField
    {...props}
    render={() => <SelectField choices={arrayList} {...props} />}
  />
);
LanguageFamilyField.defaultProps = { addLabel: true };

const LanguageFamilyInput = props => (
  <FunctionField
    {...props}
    render={() => <SelectInput choices={arrayList} {...props} />}
  />
);
LanguageFamilyInput.defaultProps = {};

export { LanguageFamilyField, LanguageFamilyInput };
