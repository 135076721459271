import { stringify } from "query-string";
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  CREATE,
  UPDATE,
  DELETE,
  DELETE_MANY
} from "react-admin";
import { auth } from "../../firebase";
import { QA_API_ERROR } from "../../util";

const FeedbackProvider = opt => {
  const { config, httpClient } = opt;
  if (!config) {
    throw new Error("Missing config");
  }
  if (!httpClient) {
    throw new Error("Missing httpClient");
  }
  const provider = {
    handle(type, params) {
      switch (type) {
        case GET_LIST:
          return provider.list(params);
        case GET_ONE:
          return provider.get(params);
        case GET_MANY:
          return provider.getMany(params);
        case CREATE:
          return provider.create(params);
        case UPDATE:
          return provider.update(params);
        case DELETE:
          return provider.delete(params);
        case DELETE_MANY:
          return provider.deleteMany(params);
        default:
          throw new Error(`Unsupported action type: ${type}`);
      }
    },
    get(params) {
      return httpClient
        .get(`/api/feedbacks/${params.id}`)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("FeedbackProvider.get Error", result, params);
          }
          let response = {
            data: result.feedback
          };
          return response;
        });
    },
    getMany(params) {
      const query = {
        ids: params.ids
      };
      return httpClient
        .get(`/api/feedbacks?${stringify(query)}`)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR(
              "FeedbackProvider.getMany Error",
              result,
              params
            );
          }
          const response = {
            data: result.feedbacks || null
          };
          return response;
        });
    },
    list(params) {
      const query = {
        order: params.sort.field,
        sort: params.sort.order.toLowerCase(),
        limit: params.pagination.perPage
      };
      if (typeof params.pagination.page === "string") {
        const [pageNumber, afterOrBefore, id] = params.pagination.page.split(
          "_"
        );
        if (afterOrBefore === "after") {
          query.after = id;
        } else if (afterOrBefore === "before") {
          query.before = id;
        }
        console.log("pageNumber: ", pageNumber);
      }
      return httpClient
        .get(`/api/feedbacks?${stringify(query)}`)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("FeedbackProvider.list Error", result, params);
          }
          let response = {
            data: result.feedbacks || null,
            total: 1000
          };
          return response;
        });
    },
    create(params) {
      return auth.currentUser
        .getIdToken(false)
        .then(token => {
          const payload = {
            token,
            message: params.data.message,
            memo: params.data.memo,
            categoryId: params.data.categoryId
          };
          return httpClient.post(`/api/feedbacks`, payload);
        })
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("FeedbackProvider.create Error", result, params);
          }
          const response = {
            data: result.feedback
          };
          return response;
        });
    },
    update(params) {
      return auth.currentUser
        .getIdToken(false)
        .then(token => {
          const payload = {
            token,
            memo: params.data.memo
          };
          return httpClient.put(`/api/feedbacks/${params.id}`, payload);
        })
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("FeedbackProvider.update Error", result, params);
          }
          const response = {
            data: result.feedback
          };
          return response;
        });
    },
    delete(params) {
      return auth.currentUser
        .getIdToken(false)
        .then(token => {
          const payload = {
            token
          };
          return httpClient.delete(`/api/feedbacks/${params.id}`, {
            data: payload
          });
        })
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("FeedbackProvider.delete Error", result, params);
          }
          const response = {
            data: result.deleted
          };
          return response;
        });
    },
    deleteMany(params) {
      return auth.currentUser
        .getIdToken(false)
        .then(token => {
          const payload = {
            token,
            ids: params.ids
          };
          return httpClient.delete(`/api/feedbacks`, { data: payload });
        })
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR(
              "FeedbackProvider.deleteMany Error",
              result,
              params
            );
          }
          const response = {
            data: result.deleted
          };
          return response;
        });
    }
  };
  return provider;
};

export default FeedbackProvider;
