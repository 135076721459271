import React from "react";
import _ from "lodash";
import { ItemWrapper, CategoryWrapper } from "./shared";

const LargeImageItem = ({ item }) => {
  const { title, name } = item;
  const imageUrl = _.get(item, "image_data.web_image");
  const category = _.chain(item)
    .get("category_data")
    .keys()
    .map(id => ({ id }))
    .first()
    .value();
  const styles = {
    container: {
      height: 207,
      width: 260,
      marginRight: 10
    },
    imageContainer: {
      width: "100%",
      height: 160,
      backgroundColor: "#D8D8D8",
      position: "relative"
    },
    image: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: 4,
      overflow: "hidden",
      position: "absolute",
      left: 0,
      top: 0
    },
    categoryContainer: {
      height: 20,
      minWidth: 60,
      overflow: "hidden",
      borderRadius: 4,
      backgroundColor: "#FFFFFF",
      boxShadow: "0 6px 12px 0 rgba(15,14,17,0.05)",
      position: "absolute",
      left: 5,
      bottom: 5
    },
    category: {
      height: "100%",
      textAlign: "center",
      width: "100%",
      color: "#3F3E41",
      fontFamily: "PingFang TC, sans-serif",
      fontSize: 12,
      fontWeight: 600,
      letterSpacing: "0.43px",
      paddingLeft: 5,
      paddingRight: 5,
      boxSizing: "border-box"
    },
    titleContainer: {
      marginTop: 5,
      height: 45,
      width: "100%"
    },
    title: {
      color: "#6F6E70",
      fontFamily: "PingFang TC, sans-serif",
      fontSize: 15,
      fontWeight: 600,
      letterSpacing: 0.63,
      lineHeight: "21px"
    }
  };
  return (
    <div style={styles.container}>
      <div style={styles.imageContainer}>
        <img src={imageUrl} style={styles.image} alt="cover" />
        <div style={styles.categoryContainer}>
          <CategoryWrapper
            category={category}
            render={record => <div style={styles.category}>{record.name}</div>}
          />
        </div>
      </div>
      <div style={styles.titleContainer}>
        <div style={styles.title}>{title || name}</div>
      </div>
    </div>
  );
};

const LargeImageItemBlock = props => {
  const { title, items } = props;
  const titleStyle = {
    color: "#0F0E11",
    fontFamily: "PingFang TC, sans-serif",
    fontSize: 18,
    fontWeight: 600,
    letterSpacing: "0.9px",
    lineHeight: "18px",
    marginBottom: 10
  };
  const itemContainerStyle = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "norap"
  };
  const style = {
    width: "375px",
    boxSizing: "border-box",
    paddingBottom: 10,
    overflow: "auto"
  };
  return (
    <div style={style}>
      <div style={titleStyle}>{title}</div>
      <div style={itemContainerStyle}>
        {items.map(item => (
          <ItemWrapper
            key={item.id}
            item={item}
            render={record => <LargeImageItem item={record} />}
          />
        ))}
      </div>
    </div>
  );
};

export default LargeImageItemBlock;
