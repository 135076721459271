import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  ImageField,
  BooleanField,
  Edit,
  SimpleForm,
  SelectInput
} from "react-admin";
import { ReporterReferenceField } from "../../../components/qa/ReferenceFields";
import ReportTargetField from "../../../components/qa/ReportTargetField";

const style = {
  info: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "2em",
    minWidth: "8em"
  },
  booleanInfo: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "2em"
  }
};

export const ReportShow = withStyles(style)(({ classes, ...props }) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" label="ID" />
      <DateField
        showTime
        source="time.created"
        label="建立時間"
        className={classes.info}
      />
      <ReporterReferenceField label="檢舉者" size={30} />
      <TextField
        source="target.type"
        label="檢舉目標類型"
        className={classes.info}
      />
      <ReportTargetField label="檢舉目標" className={classes.info} />
      <TextField source="tag.name" label="檢舉分類" className={classes.info} />
      <TextField source="content.text" label="檢舉原因" />
      <ImageField
        source="content.images"
        src="content"
        title="content"
        label="圖片"
      />
      <TextField
        source="status.code"
        label="狀態"
        className={classes.booleanInfo}
      />
      <BooleanField
        source="status.deleted"
        label="已刪除"
        className={classes.booleanInfo}
      />
    </SimpleShowLayout>
  </Show>
));

export const ReportEdit = withStyles(style)(({ classes, ...props }) => {
  return (
    <Edit {...props}>
      <SimpleForm redirect="show" submitOnEnter={false}>
        <TextField source="id" label="ID" />
        <DateField
          showTime
          source="time.created"
          label="建立時間"
          formClassName={classes.info}
        />
        <ReporterReferenceField label="檢舉者" size={30} />
        <TextField
          source="target.type"
          label="檢舉目標類型"
          formClassName={classes.info}
        />
        <ReportTargetField label="檢舉目標" formClassName={classes.info} />
        <TextField
          source="tag.name"
          label="檢舉分類"
          formClassName={classes.info}
        />
        <TextField source="content.text" label="檢舉原因" />
        <ImageField
          source="content.images"
          src="content"
          title="content"
          label="圖片"
        />
        <SelectInput
          source="status.code"
          choices={[
            { id: "open", name: "open" },
            { id: "close", name: "close" }
          ]}
        />
      </SimpleForm>
    </Edit>
  );
});
