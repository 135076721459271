import React from "react";
import Icon from "@material-ui/icons/FontDownload";
import { Resource } from "react-admin";
import { AnswerList } from "./answers";
import { AnswerShow, AnswerCreate, AnswerEdit } from "./answer";

const AnswerResource = props => {
  return (
    <Resource
      {...props}
      list={AnswerList}
      show={AnswerShow}
      edit={AnswerEdit}
      create={AnswerCreate}
      icon={Icon}
    />
  );
};

export default AnswerResource;
