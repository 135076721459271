import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  Create,
  SimpleForm
} from "react-admin";
import {
  CategoryTypesField,
  CategoryTypesInput
} from "../../../components/guide/CategoryTypes";
import {
  LanguageFamilyField,
  LanguageFamilyInput
} from "../../../components/guide/LanguageFamily";

const style = {
  info: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "2em",
    minWidth: "8em"
  },
  booleanInfo: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "2em"
  },
  width100pa: {
    width: "100%"
  }
};

export const CategoryShow = withStyles(style)(({ classes, ...props }) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="id" label="ID" />
        <TextField source="name" label="Name" />
        <CategoryTypesField source="type" label="Type" />
        <LanguageFamilyField source="lang" label="Language family" />
        <DateField
          source="create_date"
          label="Create date"
          className={classes.info}
          showTime
        />
        <DateField
          source="modify_date"
          label="Last Modify"
          className={classes.info}
          showTime
        />
      </Tab>
    </TabbedShowLayout>
  </Show>
));

export const CategoryEdit = withStyles(style)(({ classes, ...props }) => (
  <Edit {...props}>
    <TabbedForm redirect="show" submitOnEnter={false}>
      <FormTab label="summary">
        <TextField source="id" label="ID" />
        <TextInput source="name" label="Name" />
        <CategoryTypesInput source="type" label="Type" />
        <LanguageFamilyInput source="lang" label="Language family" />
      </FormTab>
    </TabbedForm>
  </Edit>
));

const createStyle = {
  inline: {
    display: "inline-block"
    // verticalAlign: "top",
    // marginRight: "2em",
    // minWidth: "8em"
  },
  booleanInfo: {
    display: "inline-block"
    // verticalAlign: "top",
    // marginRight: "2em"
  }
};
export const CategoryCreate = withStyles(createStyle)(
  ({ classes, ...props }) => {
    return (
      <Create {...props}>
        <SimpleForm redirect="show" submitOnEnter={false}>
          <TextField source="id" label="ID" />
          <TextInput source="name" label="Name" />
          <CategoryTypesInput source="type" label="Type" />
          <LanguageFamilyInput source="lang" label="Language family" />
        </SimpleForm>
      </Create>
    );
  }
);
