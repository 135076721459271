import { stringify } from "query-string";
import { GET_LIST, GET_ONE } from "react-admin";
// import { auth } from "../../firebase";
import { QA_API_ERROR } from "../../util";

const KkblogProvider = opt => {
  const { config, httpClient, lang } = opt;
  if (!config) {
    throw new Error("Missing config");
  }
  if (!httpClient) {
    throw new Error("Missing httpClient");
  }
  const provider = {
    handle(type, params) {
      console.log(type);
      switch (type) {
        case GET_LIST:
          return provider.list(params);
        // case GET_MANY_REFERENCE:
        //   return provider.list(params);
        case GET_ONE:
          return provider.get(params);
        // case GET_MANY:
        //   return provider.getMany(params);
        // case CREATE:
        //   return provider.create(params);
        // case UPDATE:
        //   return provider.update(params);
        // case DELETE:
        //   return provider.delete(params);
        // case DELETE_MANY:
        //   return provider.deleteMany(params);
        default:
          throw new Error(`Unsupported action type: ${type}`);
      }
    },
    get(params) {
      return httpClient
        .get(`/api/thirdparty/kkblogs/${params.id}?lang=${lang}`)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("KkblogProvider.get Error", result, params);
          }
          let response = {
            data: result.post
          };
          return response;
        });
    },
    list(params) {
      const query = {
        order: params.sort.field,
        sort: params.sort.order.toLowerCase(),
        limit: params.pagination.perPage,
        lang
      };
      // default
      query.before_days = 90;

      return httpClient
        .get(`/api/thirdparty/kkblogs?${stringify(query)}`)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("KkblogProvider.list Error", result, params);
          }
          let response = {
            data: result.posts || null,
            total: result.posts.length || 0
          };
          return response;
        });
    }
  };
  return provider;
};

export default KkblogProvider;
