import React from "react";
import { stringify } from "query-string";
import timeago from "timeago.js";
import compose from "recompose/compose";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import RefreshIcon from "@material-ui/icons/Refresh";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { translate } from "react-admin";
import { httpClient } from "../../services/data-provider/qa";
import { DataSource } from "../util";

const LatestEventsDS = DataSource({
  loadDataFn: () => {
    const query = {
      limit: 5,
      order: "time.created",
      sort: "DESC"
    };
    return httpClient
      .get(`/api/events?${stringify(query)}`)
      .then(res => res.data)
      .then(result => {
        if (result.status !== "success") {
          throw new Error(`Error get latest events: ${result.error.message}`);
        }
        return result.events;
      });
  }
});

const style = theme => ({
  root: {
    flex: 1
  },
  date: {
    marginRight: "1em",
    color: theme.palette.text.primary
  },
  board: {
    marginRight: "1em",
    textAlign: "right"
  }
});

const timeagoInstance = timeago();

const LatestEvents = ({ events, translate, classes }) => {
  let listItems = (
    <ListItem key="loading">
      <ListItemText primary="讀取中" />
    </ListItem>
  );
  if (events) {
    listItems = events.map(event => (
      <ListItem
        key={event.id}
        button
        component={Link}
        to={`/events/${event.id}/show`}
      >
        <ListItemText
          disableTypography={true}
          primary={<Typography variant="subheading">{event.name}</Typography>}
        />
        <ListItemSecondaryAction>
          <span className={classes.date}>
            {timeagoInstance.format(event.time.created, "zh_TW")}
          </span>
        </ListItemSecondaryAction>
      </ListItem>
    ));
  }
  return (
    <Card className={classes.root}>
      <CardHeader
        title={translate("qaAdmin.dashboard.LatestEvents")}
        action={
          <IconButton>
            <RefreshIcon
              onClick={() => {
                LatestEventsDS.refresh();
              }}
            />
          </IconButton>
        }
      />
      <List dense={true}>{listItems}</List>
    </Card>
  );
};

const enhance = compose(
  withStyles(style),
  translate
);

const EnhancedLatestEvents = enhance(LatestEvents);

class LatestEventsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      events: LatestEventsDS.get()
    };
  }
  componentDidMount() {
    LatestEventsDS.subscription.add(this.handleChange);
  }
  componentWillUnmount() {
    LatestEventsDS.subscription.remove(this.handleChange);
  }
  handleChange() {
    this.setState({
      events: LatestEventsDS.get()
    });
  }
  render() {
    return <EnhancedLatestEvents events={this.state.events} />;
  }
}

export default LatestEventsComponent;
