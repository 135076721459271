import React from "react";
import { FunctionField, SelectField, SelectInput } from "react-admin";

const typeList = [
  { id: "article", name: "Article" },
  { id: "video", name: "Video" },
  { id: "feedback", name: "Feedback" },
  { id: "faq", name: "Faq" },
  { id: "coupon", name: "Coupon" }
];

const CategoryTypesField = props => (
  <FunctionField
    {...props}
    render={() => <SelectField choices={typeList} {...props} />}
  />
);
CategoryTypesField.defaultProps = {
  addLabel: true
};

const CategoryTypesInput = props => (
  <FunctionField
    {...props}
    render={() => <SelectInput choices={typeList} {...props} />}
  />
);
CategoryTypesInput.defaultProps = {};

export { CategoryTypesField, CategoryTypesInput };
