import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { change, submit, isSubmitting } from "redux-form";
import { fetchEnd, fetchStart, showNotification, Button } from "react-admin";
import IconContentAdd from "@material-ui/icons/Add";
import IconCancel from "@material-ui/icons/Cancel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";
import { stringify } from "query-string";
import { DataSource } from "../../dashboard/util";
import { httpClient } from "../../services/data-provider/guide";

const TemplateBlocksDS = DataSource({
  loadDataFn: () => {
    const query = {
      limit: 1000,
      order: "modify_date",
      sort: "DESC"
    };
    return httpClient
      .get(`/api/template-blocks?${stringify(query)}`)
      .then(res => res.data)
      .then(result => {
        if (result.status !== "success") {
          throw new Error(
            `Error get latest template blocks: ${result.error.message}`
          );
        }
        return result.templateBlocks;
      });
  }
});

const renderLayout = layout => {
  switch (layout) {
    case 0:
      return "0 - Invisible";
    case 1:
      return "1 - Large image";
    case 2:
      return "2 - Small image";
    case 3:
      return "3 - Card";
    case 4:
      return "4 - List";
    default:
      return layout;
  }
};

const renderDate = dateValue => {
  const date = new Date(dateValue);
  return date.toLocaleDateString();
};

class TemplateBlocksTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templateBlocks: TemplateBlocksDS.get()
    };
  }
  componentDidMount() {
    TemplateBlocksDS.subscription.add(this.handleChange);
  }
  componentWillUnmount() {
    TemplateBlocksDS.subscription.remove(this.handleChange);
  }
  handleChange = () => {
    this.setState({
      templateBlocks: TemplateBlocksDS.get()
    });
  };
  handleCheck = id => {
    this.props.onCheck({ id });
  };
  isSelected = id => (this.props.isSelected[id] ? true : false);
  render() {
    const rows = this.state.templateBlocks || [];
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Select</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Layout</TableCell>
            <TableCell>Modified</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length === 0 && (
            <TableRow key={0}>
              <TableCell padding="checkbox">讀取中</TableCell>
              <TableCell component="th" scope="row" />
              <TableCell />
              <TableCell />
            </TableRow>
          )}
          {rows.map(row => {
            let isSelected = this.isSelected(row.id);
            const isAdded = this.props.isAdded(row.id);
            if (isAdded) {
              isSelected = true;
            }
            return (
              <TableRow key={row.id}>
                <TableCell padding="checkbox">
                  <Checkbox
                    disabled={isAdded}
                    checked={isSelected}
                    onChange={() => this.handleCheck(row.id)}
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.title}
                </TableCell>
                <TableCell>{renderLayout(row.layout)}</TableCell>
                <TableCell>{renderDate(row.modify_date)}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }
}

TemplateBlocksTables.propTypes = {
  isAdded: PropTypes.func,
  isSelected: PropTypes.object,
  onCheck: PropTypes.func
};

class AddTemplateBlockButton extends Component {
  state = {
    error: false,
    showDialog: false,
    isSelected: {},
    currentTab: 0
  };
  handleClick = () => {
    this.setState({ showDialog: true });
  };
  handleCloseClick = () => {
    this.closeDialog();
  };
  handleAddClick = () => {
    const selectedItems = Object.values(this.state.isSelected).reduce(
      (accu, value) => {
        console.log("handleAddClick loop value: ", value);
        if (value) {
          accu.push(value);
        }
        return accu;
      },
      []
    );
    this.props.onAddSelected(selectedItems);
    this.closeDialog();
  };
  handleCheck = ({ id, format }) => {
    const copied = Object.assign({}, this.state.isSelected);
    copied[id] = this.isSelected(id) ? false : { id, format };
    this.setState({ isSelected: copied });
  };
  handleTabChange = (event, value) => {
    this.setState({ currentTab: value });
  };
  handleRefreshClick = () => {
    TemplateBlocksDS.refresh();
  };
  closeDialog = () => {
    this.setState({ showDialog: false, isSelected: {} });
  };
  isSelected = id => (this.state.isSelected[id] ? true : false);
  isAdded = id => (this.props.blocksMap[id] ? true : false);
  render() {
    const { showDialog } = this.state;
    return (
      <Fragment>
        <Button onClick={this.handleClick}>
          <IconContentAdd />
        </Button>
        <Dialog
          fullWidth
          open={showDialog}
          onClose={this.handleCloseClick}
          aria-label="選擇"
        >
          {showDialog && (
            <Fragment>
              <DialogTitle>選擇</DialogTitle>
              <DialogContent>
                <TemplateBlocksTables
                  isAdded={this.isAdded}
                  isSelected={this.state.isSelected}
                  onCheck={this.handleCheck}
                />
              </DialogContent>
              <DialogActions>
                <IconButton
                  color="primary"
                  aria-label="refresh"
                  onClick={this.handleRefreshClick}
                >
                  <RefreshIcon />
                </IconButton>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: 10 }}
                  onClick={this.handleAddClick}
                >
                  Add Selected
                </Button>
                <Button
                  label="ra.action.cancel"
                  onClick={this.handleCloseClick}
                >
                  <IconCancel />
                </Button>
              </DialogActions>
            </Fragment>
          )}
        </Dialog>
      </Fragment>
    );
  }
}

AddTemplateBlockButton.propTypes = {
  blocksMap: PropTypes.object,
  onAddSelected: PropTypes.func
};

const mapStateToProps = state => ({
  isSubmitting: isSubmitting("post-quick-create")(state)
});

const mapDispatchToProps = {
  change,
  fetchEnd,
  fetchStart,
  showNotification,
  submit
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddTemplateBlockButton);
