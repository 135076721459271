import React from "react";
import _ from "lodash";
import { TemplateBlockPreviewField } from "./TemplateBlockPreviewFields";
import { FunctionField, ReferenceField } from "react-admin";

const BlockWrapper = props => {
  const { block, render } = props;
  return (
    <div>
      <ReferenceField
        record={block}
        source="id"
        linkType={false}
        reference="template-blocks"
        basePath="/template-blocks"
      >
        <FunctionField render={render} />
      </ReferenceField>
    </div>
  );
};

const TemplatePreviewField = props => {
  const render = record => {
    console.log("TemplatePreviewField record: ", record);
    const blocks = _.chain(record.blocks)
      .values()
      .orderBy(["sort"], ["asc"])
      .value();
    return (
      <div style={{ display: "flex", flexDirection: "column", width: 375 }}>
        {blocks.map(block => (
          <BlockWrapper
            key={block.id}
            block={block}
            render={record => (
              <TemplateBlockPreviewField
                record={record}
                style={{ marginBottom: 20 }}
              />
            )}
          />
        ))}
      </div>
    );
  };
  return <FunctionField render={render} {...props} />;
};

TemplatePreviewField.defaultProps = {
  addLabel: true,
  label: "preview"
};

export default TemplatePreviewField;
