import {
  questionProvider,
  answerProvider,
  userProvider,
  boardProvider,
  eventProvider,
  likeProvider,
  reportProvider,
  scheduleProvider,
  adminProvider,
  notificationProvider,
  questionSearchProvider,
  answerSearchProvider,
  userSearchProvider
} from "./qa";

import {
  kkblogProvider,
  kkblogKoProvider,
  kkblogJaProvider,
  kkblogEnProvider,
  kkblogMyProvider,
  kkblogThProvider,
  kkblogViProvider,
  articleProvider,
  videoProvider,
  cityProvider,
  templateBlockProvider,
  categoryProvider,
  templateProvider,
  settingProvider,
  feedbackProvider,
  articleSearchProvider,
  couponStoreProvider,
  couponProvider,
  videoSearchProvider
} from "./guide";

const dataProvider = (type, resource, params) => {
  console.log(`type:${type} resource:${resource} param:`, params);
  switch (resource) {
    case "questions": {
      if (params.filter && params.filter.q) {
        return questionSearchProvider.handle(type, params);
      }
      return questionProvider.handle(type, params);
    }
    case "answers": {
      if (params.filter && params.filter.q) {
        return answerSearchProvider.handle(type, params);
      }
      return answerProvider.handle(type, params);
    }
    case "users": {
      if (params.filter && params.filter.q) {
        return userSearchProvider.handle(type, params);
      }
      return userProvider.handle(type, params);
    }
    case "boards":
      return boardProvider.handle(type, params);
    case "events":
      return eventProvider.handle(type, params);
    case "likes":
      return likeProvider.handle(type, params);
    case "reports":
      return reportProvider.handle(type, params);
    case "schedules":
      return scheduleProvider.handle(type, params);
    case "notifications":
      return notificationProvider.handle(type, params);
    case "admins":
      return adminProvider.handle(type, params);
    case "articles":
      return params.filter &&
        params.filter.q &&
        !params.filter.post_status &&
        false
        ? articleSearchProvider.handle(type, params)
        : articleProvider.handle(type, params);
    case "videos":
      return params.filter &&
        params.filter.q &&
        !params.filter.post_status &&
        false
        ? videoSearchProvider.handle(type, params)
        : videoProvider.handle(type, params);
    case "cities":
      return cityProvider.handle(type, params);
    case "categories":
      return categoryProvider.handle(type, params);
    case "kkblogs":
      return kkblogProvider.handle(type, params);
    case "kkblogs-ko":
      return kkblogKoProvider.handle(type, params);
    case "kkblogs-ja":
      return kkblogJaProvider.handle(type, params);
    case "kkblogs-en":
      return kkblogEnProvider.handle(type, params);
    case "kkblogs-my":
      return kkblogMyProvider.handle(type, params);
    case "kkblogs-th":
      return kkblogThProvider.handle(type, params);
    case "kkblogs-vi":
      return kkblogViProvider.handle(type, params);
    case "template-blocks":
      return templateBlockProvider.handle(type, params);
    case "templates":
      return templateProvider.handle(type, params);
    case "guide-settings":
      return settingProvider.handle(type, params);
    case "coupon-stores":
      return couponStoreProvider.handle(type, params);
    case "coupons":
      return couponProvider.handle(type, params);
    case "feedbacks":
      return feedbackProvider.handle(type, params);
    default:
      throw new Error(`Unsupported resource type: ${resource}`);
  }
};

export default dataProvider;
