import React from "react";
import { SelectInput } from "react-admin";

const choices = [
  { id: "", name: "" },
  { id: "7uy1gLTWMs78vc76zUte", name: "行前規劃" },
  { id: "JNTJ7Wjyde4oGBSB6uvR", name: "住宿" },
  { id: "QvjnrzBcMf6bwGT7ujJG", name: "景點" },
  { id: "ZjW06xFM7QFy8TI67Y2K", name: "季節限定" },
  { id: "hASTHanbI6UvMdTnTy7f", name: "必吃" },
  { id: "kY4rVOfLOKT9TI5mT4GZ", name: "伴手禮" },
  { id: "omdqmAX7vxyytHNaA1q7", name: "交通" }
];

const choiceLookup = choices.reduce((accu, v) => {
  accu[v.id] = v;
  return accu;
}, {});

const QuestionTagInputField = props => (
  <SelectInput
    {...props}
    choices={choices}
    parse={v => {
      return choiceLookup[v];
    }}
    format={v => {
      if (v) return v.id;
      return "";
    }}
  />
);

export default QuestionTagInputField;
