import React from "react";
import Card from "@material-ui/core/Card";
import AnswerIcon from "@material-ui/icons/FontDownload";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CardIcon from "../components/CardIcon";

const styles = {
  main: {
    flex: "1",
    marginRight: "1em",
    marginTop: 20
  },
  card: {
    overflow: "inherit",
    textAlign: "right",
    padding: 16,
    minHeight: 52
  }
};

const AnswersCount = ({ stats, classes }) => (
  <div className={classes.main}>
    <CardIcon Icon={AnswerIcon} bgColor="#f44336" />
    <Card className={classes.card}>
      <Typography className={classes.title} color="textSecondary">
        回答數量
      </Typography>
      <Typography variant="headline" component="h2">
        {stats ? stats.content.total : "讀取中"}
      </Typography>
    </Card>
  </div>
);

AnswersCount.defaultProps = {
  stats: null
};

export default withStyles(styles)(AnswersCount);
