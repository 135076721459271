import axios from "axios";
import platform from "platform";

import KkblogProvider from "./kkblog";
import ArticleProvider from "./article";
import VideoProvider from "./video";
import CityProvider from "./city";
import TemplateBlockProvider from "./template-block";
import TemplateProvider from "./template";
import { GuideConfig as config } from "../../../config";
import CategoryProvider from "./category";
import SettingProvider from "./setting";
import FeedbackProvider from "./feedback";
import { ArticleSearchProvider, VideoSearchProvider } from "./algolia";
import CouponStoreProvider from "./coupon-store";
import CouponProvider from "./coupon";

const httpClient = axios.create({
  baseURL: config.GUIDE_API_HOST(),
  headers: {
    "client-key": config.clientKey(),
    "kkguide-platform": "web",
    "kkguide-appbuild": config.build,
    "kkguide-appversion": config.version,
    "kkguide-osversion": platform.description || "",
    "kkguide-systemlang":
      window.navigator.userLanguage || window.navigator.language || "n/a"
  }
});

const opt = {
  config,
  httpClient
};

const kkblogProvider = KkblogProvider({ lang: "zh-tw", ...opt });
const kkblogKoProvider = KkblogProvider({ lang: "ko", ...opt });
const kkblogJaProvider = KkblogProvider({ lang: "ja", ...opt });
const kkblogEnProvider = KkblogProvider({ lang: "en", ...opt });
const kkblogMyProvider = KkblogProvider({ lang: "zh-my", ...opt });
const kkblogThProvider = KkblogProvider({ lang: "th", ...opt });
const kkblogViProvider = KkblogProvider({ lang: "vi", ...opt });
const articleProvider = ArticleProvider(opt);
const videoProvider = VideoProvider(opt);
const cityProvider = CityProvider(opt);
const templateBlockProvider = TemplateBlockProvider(opt);
const categoryProvider = CategoryProvider(opt);
const templateProvider = TemplateProvider(opt);
const settingProvider = SettingProvider(opt);
const feedbackProvider = FeedbackProvider(opt);
const couponStoreProvider = CouponStoreProvider(opt);
const couponProvider = CouponProvider(opt);

const articleSearchProvider = ArticleSearchProvider(opt);
const videoSearchProvider = VideoSearchProvider(opt);

export {
  httpClient,
  kkblogProvider,
  kkblogKoProvider,
  kkblogJaProvider,
  kkblogEnProvider,
  kkblogMyProvider,
  kkblogThProvider,
  kkblogViProvider,
  articleProvider,
  videoProvider,
  cityProvider,
  templateBlockProvider,
  categoryProvider,
  templateProvider,
  settingProvider,
  feedbackProvider,
  articleSearchProvider,
  couponStoreProvider,
  couponProvider,
  videoSearchProvider
};
