import React from "react";
import {
  Datagrid,
  TextField,
  DateField,
  Link,
  Filter,
  TextInput
} from "react-admin";
import { List } from "../../../components/customize-react-admin";
import Pagination from "../../../components/qa/Pagination";
import {
  DeletedField,
  InternalUserField
} from "../../../components/qa/BooleanStatusField";
import { UserPhotoDisplayNameField } from "../../../components/qa/UserFields";

const IDLinkField = props => (
  <Link to={`/users/${props.record.id}/show`}>
    <UserPhotoDisplayNameField
      {...props}
      label="顯示名稱"
      size={30}
      sortable={false}
    />
  </Link>
);

const UserFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const UserList = props => {
  const perPage = 50;
  const defaultSort = {
    field: "time.created",
    order: "DESC"
  };
  return (
    <List
      {...props}
      title="用戶列表"
      perPage={perPage}
      sort={defaultSort}
      pagination={<Pagination />}
      filters={<UserFilter />}
    >
      <Datagrid>
        <IDLinkField label="顯示名稱" sortable={false} />
        <TextField source="question.count" label="問題數" />
        <TextField source="answer.count" label="回答數" />
        <InternalUserField />
        <DeletedField />
        <DateField showTime source="time.created" label="建立時間" />
      </Datagrid>
    </List>
  );
};
