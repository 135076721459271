import React from "react";
import Icon from "@material-ui/icons/Report";
import { Resource } from "react-admin";
import { ReportList } from "./reports";
import { ReportShow, ReportEdit } from "./report";

const ReportResource = props => {
  return (
    <Resource
      {...props}
      list={ReportList}
      show={ReportShow}
      edit={ReportEdit}
      icon={Icon}
    />
  );
};

export default ReportResource;
