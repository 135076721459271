import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  Edit,
  SimpleForm,
  LongTextInput
} from "react-admin";
import CategoryField from "../../../components/guide/CategoryField";

const style = {
  info: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "2em",
    minWidth: "8em"
  },
  booleanInfo: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "2em"
  }
};

export const FeedbackShow = withStyles(style)(({ classes, ...props }) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" label="ID" />
      <DateField
        showTime
        source="create_date"
        label="建立時間"
        className={classes.info}
      />
      <TextField
        source="member_data.email"
        label="回報者Email"
        className={classes.info}
      />
      <TextField
        source="member_data.member_uuid"
        label="回報者ID"
        className={classes.info}
      />
      <CategoryField label="類型" />
      <TextField source="message" label="回報內文" />
      <TextField source="memo" label="memo" />
    </SimpleShowLayout>
  </Show>
));

export const FeedbackEdit = withStyles(style)(({ classes, ...props }) => {
  return (
    <Edit {...props}>
      <SimpleForm redirect="show" submitOnEnter={false}>
        <TextField source="id" label="ID" />
        <DateField
          showTime
          source="create_date"
          label="建立時間"
          className={classes.info}
        />
        <TextField
          source="member_data.email"
          label="回報者Email"
          className={classes.info}
        />
        <TextField
          source="member_data.member_uuid"
          label="回報者ID"
          className={classes.info}
        />
        <CategoryField label="類型" />
        <LongTextInput label="memo" source="memo" />
      </SimpleForm>
    </Edit>
  );
});
