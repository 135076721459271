import React, { Fragment } from "react";
import { Field } from "redux-form";
import { ReferenceInput } from "react-admin";
import QuestionPreviewButton from "./QuestionPreviewButton";
import { AutocompleteInput } from "react-admin";

const QuestionReferenceInput = props => (
  <Fragment>
    <ReferenceInput {...props}>
      <AutocompleteInput
        suggestionComponent={({
          suggestion,
          query,
          isHighlighted,
          ...props
        }) => {
          return (
            <div {...props}>
              {`[${suggestion.id}][${suggestion.title}][${
                suggestion.author.displayName
              }][${new Date(suggestion.time.created).toLocaleDateString()}]`}
            </div>
          );
        }}
        optionText={record => record.id}
      />
    </ReferenceInput>
    <Field
      name="questionId"
      component={({ input }) =>
        input.value && <QuestionPreviewButton id={input.value} />
      }
    />
  </Fragment>
);

export default QuestionReferenceInput;
