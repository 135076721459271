import React from "react";
import { last, first } from "lodash";
import Button from "@material-ui/core/Button";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Toolbar from "@material-ui/core/Toolbar";

const Pagination = params => {
  const { page, perPage, total, setPage, ids } = params;
  const nbPages = Math.ceil(total / perPage) || 1;
  let pageNumber;
  if (typeof page === "number") {
    pageNumber = page;
  } else {
    const number = page.split("_")[0];
    pageNumber = parseInt(number, 10);
  }
  const calculatePage = pageIncrement => {
    const param = {
      pageNumber: parseInt(pageNumber, 10) + pageIncrement,
      afterOrBefore: pageIncrement > 0 ? "after" : "before",
      id: pageIncrement > 0 ? last(ids) : first(ids)
    };
    const pagingParam = `${param.pageNumber}_${
      param.afterOrBefore
    }_${param.id || "noMore"}`;
    return pagingParam;
  };
  const nextPageParam = calculatePage(1);
  const prevPageParam = calculatePage(-1);
  return (
    nbPages > 1 && (
      <Toolbar>
        {pageNumber > 1 && (
          <Button
            primary="true"
            key="prev"
            icon={<ChevronLeft />}
            onClick={() => setPage(prevPageParam)}
          >
            Prev
          </Button>
        )}
        {ids.length === perPage && (
          <Button
            primary="true"
            key="next"
            icon={<ChevronRight />}
            onClick={() => setPage(nextPageParam)}
            labelposition="before"
          >
            Next
          </Button>
        )}
      </Toolbar>
    )
  );
};

export default Pagination;
