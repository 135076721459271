import React from "react";
import _ from "lodash";
import { parse } from "query-string";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Show,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  TextField,
  DateField,
  ImageField,
  RichTextField,
  BooleanField,
  Datagrid,
  Create,
  Edit,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  LongTextInput,
  BooleanInput,
  required,
  minLength,
  maxLength
} from "react-admin";
import { DateTimeInput } from "react-admin-date-inputs";
import {
  AuthorReferenceField,
  QuestionReferenceField
} from "../../../components/qa/ReferenceFields";
import {
  LikeLinkField,
  ReportLinkField,
  ReporterLinkField,
  UserLinkField
} from "../../../components/qa/LinkFields";
import { ContentImagesInputField } from "../../../components/qa/ImagesInputField";
import { SimpleList } from "../../../components/customize-react-admin";
import QuestionReferenceInput from "../../../components/qa/QuestionReferenceInput";

const style = {
  info: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "2em",
    minWidth: "8em"
  },
  booleanInfo: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "2em"
  }
};

export const AnswerShow = withStyles(style)(({ classes, ...props }) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="id" label="ID" />
        <DateField
          showTime
          source="time.created"
          label="建立時間"
          className={classes.info}
        />
        <DateField
          showTime
          source="time.updated"
          label="編輯時間"
          className={classes.info}
        />
        <QuestionReferenceField label="所屬問題" />
        <AuthorReferenceField label="作者" size={30} className={classes.info} />
        <TextField
          source="like.total"
          label="按讚數"
          className={classes.info}
        />
        <RichTextField source="content.text" label="內容" />
        <ImageField source="content.images" src="content" title="content" />
        <BooleanField
          source="status.valid"
          label="顯示"
          className={classes.booleanInfo}
        />
        <BooleanField
          source="status.deleted"
          label="刪除"
          className={classes.booleanInfo}
        />
      </Tab>
      <Tab label="likes" path="likes">
        <ReferenceManyField
          reference="likes"
          target="targetId"
          addLabel={false}
          perPage={25}
          sort={{
            field: "time.created",
            order: "ASC"
          }}
        >
          <Datagrid>
            <LikeLinkField source="id" label="ID" sortable={false} />
            <UserLinkField label="按讚者" sortable={false} />
            <TextField source="type" label="讚類型" sortable={false} />
            <DateField showTime source="time.created" label="建立時間" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="reports" path="reports">
        <ReferenceManyField
          reference="reports"
          target="targetId"
          addLabel={false}
          perPage={25}
          sort={{
            field: "time.created",
            order: "DESC"
          }}
        >
          <Datagrid>
            <ReportLinkField source="id" label="ID" sortable={false} />
            <ReporterLinkField label="檢舉者" sortable={false} />
            <TextField source="content.text" label="原因" sortable={false} />
            <TextField source="status.code" label="狀態" sortable={false} />
            <BooleanField
              source="status.deleted"
              label="刪除"
              sortable={false}
            />
            <DateField showTime source="time.created" label="建立時間" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="events" path="event">
        <ReferenceManyField
          reference="events"
          target="actorId"
          addLabel={false}
        >
          <SimpleList
            primaryText={record => record.name || "n/a"}
            secondaryText={record =>
              JSON.stringify(_.last(record.trace), null, 2)
            }
            tertiaryText={record =>
              new Date(record.time.created).toLocaleString()
            }
          />
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
));

const validateContent = [required(), minLength(2), maxLength(1500)];
const validateAuthor = required();
const validateQuestion = required();

const createStyle = {};

export const AnswerCreate = withStyles(createStyle)(({ classes, ...props }) => {
  const defaultValue = {};
  const { questionId } = parse(props.location.search);
  let redirect = "show";
  if (questionId) {
    defaultValue.questionId = questionId;
    redirect = `/questions/${questionId}/show/answers`;
  }
  return (
    <Create {...props}>
      <SimpleForm
        redirect={redirect}
        submitOnEnter={false}
        defaultValue={defaultValue}
      >
        <ReferenceInput
          label="作者"
          source="authorId"
          reference="users"
          perPage={1000}
          sort={{
            field: "displayName",
            order: "ASC"
          }}
          filter={{ internalUser: true }}
          validate={validateAuthor}
        >
          <SelectInput optionText="displayName" />
        </ReferenceInput>
        <QuestionReferenceInput
          label="問題"
          source="questionId"
          reference="questions"
          sort={{ field: "time.created", order: "DESC" }}
          validate={validateQuestion}
          allowEmpty
        />
        <DateTimeInput
          source="publishDate"
          label="發表時間"
          options={{
            format: "yyyy/MM/dd, HH:mm:ss",
            disableFuture: true,
            minDate: new Date("2017-04-01"),
            ampm: true,
            showTodayButton: true
          }}
        />
        <LongTextInput
          label="內容"
          source="content"
          validate={validateContent}
        />
        <ContentImagesInputField source="images" label="相關圖片" />
      </SimpleForm>
    </Create>
  );
});

export const AnswerEdit = withStyles(style)(({ classes, ...props }) => {
  return (
    <Edit {...props}>
      <SimpleForm redirect="show" submitOnEnter={false}>
        <TextField source="id" label="ID" className={classes.info} />
        <DateTimeInput
          source="time.created"
          label="建立時間"
          options={{
            format: "yyyy/MM/dd, HH:mm:ss",
            disableFuture: true,
            minDate: new Date("2017-04-01"),
            ampm: true,
            showTodayButton: true
          }}
        />
        <DateTimeInput
          source="time.updated"
          label="編輯時間"
          options={{
            format: "yyyy/MM/dd, HH:mm:ss",
            disableFuture: true,
            minDate: new Date("2017-04-01"),
            ampm: true,
            showTodayButton: true
          }}
        />
        <AuthorReferenceField
          label="作者"
          size={30}
          formClassName={classes.info}
        />
        <TextField
          source="like.total"
          label="按讚數"
          formClassName={classes.info}
        />
        <LongTextInput source="content.text" label="內容" />
        <ContentImagesInputField source="content.images" label="相關圖片" />
        <BooleanInput
          source="status.valid"
          label="顯示"
          formClassName={classes.booleanInfo}
        />
      </SimpleForm>
    </Edit>
  );
});
