import { httpClient } from "../data-provider/qa";

const convertFileToBase64 = file =>
  new Promise((resolve, reject) => {
    if (!file) {
      reject();
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

const uploadImage = () => {
  const fn = base64Content => {
    const [type, encodeString] = base64Content.split(",");
    const mimeType = type.split(";")[0].split(":")[1];
    return httpClient
      .post("/api/image/upload", {
        encodeString,
        mimeType
      })
      .then(res => res.data)
      .then(result => {
        const url = `https://img.kkday.com/v2/${result.imageUrl}`;
        console.log("upload image result: ", result);
        console.log("image url: ", url);
        return url;
      });
  };
  return fn;
};

export { convertFileToBase64, uploadImage };
