import React from "react";
import { Datagrid, TextField, DateField, Link, Filter } from "react-admin";
import { List } from "../../../components/customize-react-admin";
import Pagination from "../../../components/qa/Pagination";
import {
  LanguageFamilyField,
  LanguageFamilyInput
} from "../../../components/guide/LanguageFamily";
import {
  ActiveStatusField,
  ActiveStatusInput
} from "../../../components/guide/ActiveStatus";
import { CountryCitySelectInput } from "../../../components/guide/CountryCitySelect";

const IDLinkField = props => (
  <Link to={`/coupons/${props.record.id}/show`}>
    <TextField {...props} />
  </Link>
);

const CouponFilter = props => (
  <Filter {...props}>
    <LanguageFamilyInput source="lang" label="Language family" alwaysOn />
    <ActiveStatusInput source="active" label="Active" alwaysOn />
    <CountryCitySelectInput source="cities" label="Destination" />
  </Filter>
);

export const CouponList = props => {
  const perPage = 1000;
  const defaultSort = {
    field: "title",
    order: "ASC"
  };
  return (
    <List
      {...props}
      title="Coupon list"
      perPage={perPage}
      sort={defaultSort}
      pagination={<Pagination />}
      filters={<CouponFilter />}
    >
      <Datagrid>
        <IDLinkField source="title" label="Title" sortable={false} />
        <ActiveStatusField
          source="status.active"
          label="Active"
          sortable={false}
        />
        <LanguageFamilyField
          source="lang"
          label="Language family"
          sortable={false}
        />
        <DateField
          showTime
          source="updated"
          label="Last Modify"
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};
