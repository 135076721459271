import React from "react";
import { Datagrid, TextField, DateField, Link } from "react-admin";
import { List } from "../../../components/customize-react-admin";
import Pagination from "../../../components/qa/Pagination";
import { UserLinkField } from "../../../components/qa/LinkFields";

const IDLinkField = props => (
  <Link to={`/notifications/${props.record.id}/show`}>
    <TextField {...props} />
  </Link>
);

export const NotificationList = props => {
  const perPage = 50;
  const defaultSort = {
    field: "time.created",
    order: "DESC"
  };
  return (
    <List
      {...props}
      title="通知列表"
      perPage={perPage}
      sort={defaultSort}
      pagination={<Pagination />}
    >
      <Datagrid>
        <IDLinkField source="id" label="ID" sortable={false} />
        <UserLinkField label="使用者" size={30} sortable={false} />
        <DateField showTime source="time.created" label="建立時間" />
        <DateField
          showTime
          source="time.updated"
          label="編輯時間"
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};
