import React from "react";
import * as _ from "lodash";
import { stringify } from "query-string";
import timeago from "timeago.js";
import compose from "recompose/compose";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import RefreshIcon from "@material-ui/icons/Refresh";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { translate } from "react-admin";
import { UserPhotoDisplayNameField } from "../../components/qa/UserFields";
import { httpClient } from "../../services/data-provider/qa";

const DataSource = {
  data: {
    answers: null
  },
  internal: {
    listeners: [],
    setAnswers(answers) {
      DataSource.data.answers = answers;
      DataSource.internal.notifyListeners();
    },
    loadAnswers() {
      const query = {
        limit: 5,
        order: "time.created",
        sort: "DESC"
      };
      httpClient
        .get(`/api/answers?${stringify(query)}`)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw new Error(
              `Error get latest answers: ${result.error.message}`
            );
          }
          DataSource.internal.setAnswers(result.answers);
        });
    },
    notifyListeners() {
      _.forEach(DataSource.internal.listeners, fn => fn());
    }
  },
  getAnswers() {
    if (!DataSource.data.answers) {
      DataSource.internal.loadAnswers();
    }
    return DataSource.data.answers;
  },
  refresh() {
    DataSource.internal.loadAnswers();
  },
  addChangeListener(listener) {
    DataSource.internal.listeners.push(listener);
  },
  removeChangeListener(listener) {
    _.remove(DataSource.internal.listeners, item => item === listener);
  }
};

const style = theme => ({
  root: {
    flex: 1
  },
  date: {
    marginRight: "1em",
    color: theme.palette.text.primary
  },
  board: {
    marginRight: "1em",
    textAlign: "right"
  }
});

const timeagoInstance = timeago();

const LatestAnswers = ({ answers, translate, classes }) => {
  let listItems = (
    <ListItem key="loading">
      <ListItemText primary="讀取中" />
    </ListItem>
  );
  if (answers) {
    listItems = answers.map(answer => (
      <ListItem
        key={answer.id}
        button
        component={Link}
        to={`/answers/${answer.id}/show`}
      >
        <ListItemText
          disableTypography={true}
          primary={
            <Typography variant="subheading">{answer.content.text}</Typography>
          }
          secondary={
            <UserPhotoDisplayNameField record={answer.author} size={30} />
          }
        />
        <ListItemSecondaryAction>
          <span className={classes.date}>
            {timeagoInstance.format(answer.time.created, "zh_TW")}
          </span>
        </ListItemSecondaryAction>
      </ListItem>
    ));
  }
  return (
    <Card className={classes.root}>
      <CardHeader
        title={translate("qaAdmin.dashboard.latestAnswers")}
        action={
          <IconButton>
            <RefreshIcon
              onClick={() => {
                DataSource.refresh();
              }}
            />
          </IconButton>
        }
      />
      <List dense={true}>{listItems}</List>
    </Card>
  );
};

const enhance = compose(
  withStyles(style),
  translate
);

const EnhancedLatestAnswers = enhance(LatestAnswers);

class LatestAnswersComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      answers: DataSource.getAnswers()
    };
  }
  componentDidMount() {
    DataSource.addChangeListener(this.handleChange);
  }
  componentWillUnmount() {
    DataSource.removeChangeListener(this.handleChange);
  }
  handleChange() {
    this.setState({
      answers: DataSource.getAnswers()
    });
  }
  render() {
    return <EnhancedLatestAnswers answers={this.state.answers} />;
  }
}

export default LatestAnswersComponent;
