import React from "react";
import _ from "lodash";
import { UserPhotoDisplayNameField } from "./UserFields";
import { ReferenceField, TextField, FunctionField } from "react-admin";

const QuestionReferenceField = props => (
  <ReferenceField reference="questions" {...props} linkType="show">
    <TextField source="title" />
  </ReferenceField>
);

QuestionReferenceField.defaultProps = {
  source: "question.id",
  addLabel: true
};

const AnswerReferenceField = props => (
  <ReferenceField reference="answers" {...props} linkType="show">
    <FunctionField render={record => _.truncate(record.content.text)} />
  </ReferenceField>
);

AnswerReferenceField.defaultProps = {
  source: "answer.id",
  addLabel: true
};

const AuthorReferenceField = props => (
  <ReferenceField reference="users" {...props} linkType="show">
    <UserPhotoDisplayNameField />
  </ReferenceField>
);

AuthorReferenceField.defaultProps = {
  source: "author.id",
  addLabel: true
};

const UserReferenceField = props => (
  <ReferenceField reference="users" {...props} linkType="show">
    <UserPhotoDisplayNameField />
  </ReferenceField>
);

UserReferenceField.defaultProps = {
  source: "user.id",
  addLabel: true
};

const TaskAuthorReferenceField = props => (
  <ReferenceField
    source="task.payload.authorId"
    reference="users"
    {...props}
    linkType="show"
  >
    <UserPhotoDisplayNameField />
  </ReferenceField>
);

TaskAuthorReferenceField.defaultProps = {
  source: "task.payload.authorId",
  addLabel: true
};

const ReporterReferenceField = props => (
  <ReferenceField
    source="reporter.id"
    reference="users"
    {...props}
    linkType="show"
  >
    <UserPhotoDisplayNameField />
  </ReferenceField>
);

ReporterReferenceField.defaultProps = {
  source: "reporter.id",
  addLabel: true
};

const BoardReferenceField = props => (
  <ReferenceField reference="boards" {...props} linkType="show">
    <TextField source="name" />
  </ReferenceField>
);

BoardReferenceField.defaultProps = {
  source: "board.id",
  addLabel: true
};

const LikeReferenceField = props => (
  <ReferenceField reference="likes" {...props} linkType="show">
    <TextField source="type" />
  </ReferenceField>
);

LikeReferenceField.defaultProps = {
  source: "like.id",
  addLabel: true
};

export {
  QuestionReferenceField,
  AnswerReferenceField,
  AuthorReferenceField,
  UserReferenceField,
  ReporterReferenceField,
  BoardReferenceField,
  TaskAuthorReferenceField,
  LikeReferenceField
};
