import React from "react";
import * as _ from "lodash";
import { FunctionField, TextField } from "react-admin";

const createElement = (record, props) => {
  const type = _.get(record, "task.type");
  switch (type) {
    case "newQuestion":
      return <TextField {...props} source="task.payload.title" />;
    case "newAnswer":
      return <TextField {...props} source="task.payload.content" />;
    default:
      return null;
  }
};

const TaskContentField = props => (
  <FunctionField {...props} render={record => createElement(record, props)} />
);

TaskContentField.defaultProps = {
  addLabel: true
};

export default TaskContentField;
