import React from "react";
import Icon from "@material-ui/icons/Feedback";
import { Resource } from "react-admin";
import { FeedbackList } from "./feedbacks";
import { FeedbackShow, FeedbackEdit } from "./feedback";

const FeedbackResource = props => {
  return (
    <Resource
      {...props}
      list={FeedbackList}
      show={FeedbackShow}
      edit={FeedbackEdit}
      icon={Icon}
    />
  );
};

export default FeedbackResource;
