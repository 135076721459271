import React from "react";
import { FunctionField, ReferenceField } from "react-admin";

const ItemWrapper = props => {
  const { item, render } = props;
  let reference = null;
  let basePath = null;
  if (item.format === "article") {
    reference = "articles";
    basePath = "/articles";
  } else if (item.format === "video") {
    reference = "videos";
    basePath = "/videos";
  } else if (item.format === "city") {
    reference = "cities";
    basePath = "/cities";
  }
  if (!reference) {
    return <div>{`not supported format:${item.format}`}</div>;
  }
  return (
    <div>
      <ReferenceField
        record={item}
        source="id"
        linkType="show"
        reference={reference}
        basePath={basePath}
      >
        <FunctionField render={render} />
      </ReferenceField>
    </div>
  );
};

const CategoryWrapper = props => {
  const { category, render } = props;
  return (
    <div>
      <ReferenceField
        record={category}
        source="id"
        linkType={false}
        reference="categories"
        basePath="/"
      >
        <FunctionField render={render} />
      </ReferenceField>
    </div>
  );
};

const CityWrapper = props => {
  const { city, render } = props;
  return (
    <div>
      <ReferenceField
        record={city}
        source="id"
        linkType={false}
        reference="cities"
        basePath="/"
      >
        <FunctionField render={render} />
      </ReferenceField>
    </div>
  );
};

export { ItemWrapper, CategoryWrapper, CityWrapper };
