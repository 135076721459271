import React from "react";
import _ from "lodash";
import RadioButtonChecked from "@material-ui/icons/RadioButtonChecked";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import AssignmentIndOutlined from "@material-ui/icons/AssignmentIndOutlined";

const BooleanStatusField = props => {
  return _.get(props.record, props.source) ? props.icon : null;
};

BooleanStatusField.defaultProps = {
  icon: <RadioButtonChecked />
};

const DeletedField = props => <BooleanStatusField {...props} />;

DeletedField.defaultProps = {
  label: "已刪除",
  source: "status.deleted",
  icon: <DeleteOutlinedIcon />,
  sortable: true
};

const InternalUserField = props => <BooleanStatusField {...props} />;

InternalUserField.defaultProps = {
  label: "假帳號",
  source: "meta.internalUser",
  icon: <AssignmentIndOutlined />,
  sortable: true
};

export { BooleanStatusField, DeletedField, InternalUserField };
