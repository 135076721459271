import englishMessages from "ra-language-english";

export default {
  ...englishMessages,
  qaAdmin: {
    dashboard: {
      latestQuestions: "最新問題",
      latestAnswers: "最新回答",
      LatestReports: "最新檢舉",
      LatestEvents: "最新事件"
    },
    validation: {
      required: "必填"
    }
  }
};
