import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { ViewTitle } from "react-admin";
import { InitializeCount } from "./stats";

const Settings = () => (
  <Card>
    <ViewTitle title="Settings" />
    <CardContent>
      <InitializeCount resource="questions" />
      <InitializeCount resource="answers" />
      <InitializeCount resource="users" />
    </CardContent>
  </Card>
);

export default Settings;
