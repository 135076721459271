import { countryCityUtility } from "./cities";

const citiesLangOptions = [
  {
    id: "A01-003-00008",
    title: "三重",
    lang: "zh-tw"
  },
  {
    id: "A01-002-00008",
    title: "上海",
    lang: "zh-tw"
  },
  {
    id: "A01-011-00004",
    title: "下龍灣",
    lang: "zh-tw"
  },
  {
    id: "A01-017-00001",
    title: "不丹",
    lang: "zh-tw"
  },
  {
    id: "A03-004-00010",
    title: "不萊梅",
    lang: "zh-tw"
  },
  {
    id: "A05-001-00022",
    title: "丹佛",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00047",
    title: "九州",
    lang: "zh-tw"
  },
  {
    id: "A05-001-00047",
    title: "亞利桑那州",
    lang: "zh-tw"
  },
  {
    id: "A08-001-00003",
    title: "亞斯文",
    lang: "zh-tw"
  },
  {
    id: "A03-013-00005",
    title: "亞維儂",
    lang: "zh-tw"
  },
  {
    id: "A03-009-00013",
    title: "亞芬河畔史特拉福",
    lang: "zh-tw"
  },
  {
    id: "A01-004-00007",
    title: "京畿道",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00003",
    title: "京都",
    lang: "zh-tw"
  },
  {
    id: "A01-004-00015",
    title: "仁川",
    lang: "zh-tw"
  },
  {
    id: "A01-007-00001",
    title: "仰光",
    lang: "zh-tw"
  },
  {
    id: "A03-018-00009",
    title: "伊拉克利翁",
    lang: "zh-tw"
  },
  {
    id: "A03-008-00001",
    title: "伊斯坦堡",
    lang: "zh-tw"
  },
  {
    id: "A03-014-00005",
    title: "伊比薩島",
    lang: "zh-tw"
  },
  {
    id: "A04-001-00002",
    title: "伊瓜蘇",
    lang: "zh-tw"
  },
  {
    id: "A04-005-00004",
    title: "伊瓜蘇港",
    lang: "zh-tw"
  },
  {
    id: "A03-008-00006",
    title: "伊茲密爾",
    lang: "zh-tw"
  },
  {
    id: "A05-001-00020",
    title: "休士頓",
    lang: "zh-tw"
  },
  {
    id: "A06-001-00003",
    title: "伯斯",
    lang: "zh-tw"
  },
  {
    id: "A03-018-00004",
    title: "伯羅奔尼撒",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00051",
    title: "佐賀",
    lang: "zh-tw"
  },
  {
    id: "A03-012-00002",
    title: "佛羅倫斯",
    lang: "zh-tw"
  },
  {
    id: "A03-009-00001",
    title: "倫敦",
    lang: "zh-tw"
  },
  {
    id: "A03-025-00002",
    title: "克拉科夫",
    lang: "zh-tw"
  },
  {
    id: "A03-033-00002",
    title: "克盧日-納波卡",
    lang: "zh-tw"
  },
  {
    id: "A03-018-00005",
    title: "克里特",
    lang: "zh-tw"
  },
  {
    id: "A01-004-00016",
    title: "全州",
    lang: "zh-tw"
  },
  {
    id: "A01-004-00008",
    title: "全羅北道",
    lang: "zh-tw"
  },
  {
    id: "A01-004-00004",
    title: "全羅南道",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00022",
    title: "兵庫",
    lang: "zh-tw"
  },
  {
    id: "A06-001-00005",
    title: "凱恩斯",
    lang: "zh-tw"
  },
  {
    id: "A03-009-00003",
    title: "利物浦",
    lang: "zh-tw"
  },
  {
    id: "A04-008-00003",
    title: "利馬",
    lang: "zh-tw"
  },
  {
    id: "A01-018-00003",
    title: "加德滿都",
    lang: "zh-tw"
  },
  {
    id: "A01-002-00001",
    title: "北京",
    lang: "zh-tw"
  },
  {
    id: "A06-002-00007",
    title: "北島",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00005",
    title: "北海道",
    lang: "zh-tw"
  },
  {
    id: "A01-010-00012",
    title: "北碧",
    lang: "zh-tw"
  },
  {
    id: "A08-006-00001",
    title: "北馬塔貝萊蘭省",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00039",
    title: "千葉",
    lang: "zh-tw"
  },
  {
    id: "A01-002-00022",
    title: "南京",
    lang: "zh-tw"
  },
  {
    id: "A06-002-00006",
    title: "南島",
    lang: "zh-tw"
  },
  {
    id: "A01-001-00012",
    title: "南投",
    lang: "zh-tw"
  },
  {
    id: "A03-008-00004",
    title: "博德魯姆",
    lang: "zh-tw"
  },
  {
    id: "A03-013-00008",
    title: "博訥",
    lang: "zh-tw"
  },
  {
    id: "A03-015-00003",
    title: "卡地夫",
    lang: "zh-tw"
  },
  {
    id: "A03-008-00005",
    title: "卡帕多奇亞",
    lang: "zh-tw"
  },
  {
    id: "A05-003-00005",
    title: "卡波聖盧卡斯",
    lang: "zh-tw"
  },
  {
    id: "A03-009-00007",
    title: "印威內斯",
    lang: "zh-tw"
  },
  {
    id: "A01-015-00001",
    title: "印度",
    lang: "zh-tw"
  },
  {
    id: "A01-009-00003",
    title: "古晉",
    lang: "zh-tw"
  },
  {
    id: "A01-001-00002",
    title: "台中",
    lang: "zh-tw"
  },
  {
    id: "A01-001-00001",
    title: "台北",
    lang: "zh-tw"
  },
  {
    id: "A01-001-00003",
    title: "台南",
    lang: "zh-tw"
  },
  {
    id: "A01-001-00018",
    title: "台東",
    lang: "zh-tw"
  },
  {
    id: "A03-006-00002",
    title: "史普利特",
    lang: "zh-tw"
  },
  {
    id: "A08-001-00006",
    title: "吉薩",
    lang: "zh-tw"
  },
  {
    id: "A01-009-00004",
    title: "吉隆坡",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00027",
    title: "名古屋",
    lang: "zh-tw"
  },
  {
    id: "A01-014-00001",
    title: "吳哥窟",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00036",
    title: "和歌山",
    lang: "zh-tw"
  },
  {
    id: "A03-005-00005",
    title: "哈爾施塔特",
    lang: "zh-tw"
  },
  {
    id: "A05-005-00001",
    title: "哈瓦那",
    lang: "zh-tw"
  },
  {
    id: "A03-016-00001",
    title: "哥本哈根",
    lang: "zh-tw"
  },
  {
    id: "A03-021-00009",
    title: "哥爾威",
    lang: "zh-tw"
  },
  {
    id: "A01-010-00006",
    title: "喀比",
    lang: "zh-tw"
  },
  {
    id: "A03-027-00003",
    title: "喬治敦",
    lang: "zh-tw"
  },
  {
    id: "A01-001-00014",
    title: "嘉義",
    lang: "zh-tw"
  },
  {
    id: "A01-002-00047",
    title: "四川",
    lang: "zh-tw"
  },
  {
    id: "A03-005-00002",
    title: "因斯布魯克",
    lang: "zh-tw"
  },
  {
    id: "A03-019-00005",
    title: "因特拉肯",
    lang: "zh-tw"
  },
  {
    id: "A03-013-00027",
    title: "土魯斯",
    lang: "zh-tw"
  },
  {
    id: "A03-030-00001",
    title: "地拉那",
    lang: "zh-tw"
  },
  {
    id: "A06-001-00021",
    title: "坎加魯島",
    lang: "zh-tw"
  },
  {
    id: "A06-001-00004",
    title: "坎培拉",
    lang: "zh-tw"
  },
  {
    id: "A05-003-00004",
    title: "坎昆",
    lang: "zh-tw"
  },
  {
    id: "A04-005-00002",
    title: "埃爾卡拉法特",
    lang: "zh-tw"
  },
  {
    id: "A03-021-00002",
    title: "基拉尼",
    lang: "zh-tw"
  },
  {
    id: "A06-002-00005",
    title: "基督城",
    lang: "zh-tw"
  },
  {
    id: "A03-028-00001",
    title: "基輔",
    lang: "zh-tw"
  },
  {
    id: "A01-001-00026",
    title: "基隆",
    lang: "zh-tw"
  },
  {
    id: "A05-001-00039",
    title: "基韋斯特",
    lang: "zh-tw"
  },
  {
    id: "A03-014-00013",
    title: "塔拉哥納",
    lang: "zh-tw"
  },
  {
    id: "A06-001-00010",
    title: "塔斯馬尼亞",
    lang: "zh-tw"
  },
  {
    id: "A03-002-00001",
    title: "塔林",
    lang: "zh-tw"
  },
  {
    id: "A08-001-00011",
    title: "塞法傑",
    lang: "zh-tw"
  },
  {
    id: "A06-001-00001",
    title: "墨爾本",
    lang: "zh-tw"
  },
  {
    id: "A05-003-00002",
    title: "墨西哥市",
    lang: "zh-tw"
  },
  {
    id: "A01-001-00016",
    title: "墾丁",
    lang: "zh-tw"
  },
  {
    id: "A05-001-00011",
    title: "夏威夷",
    lang: "zh-tw"
  },
  {
    id: "A05-001-00045",
    title: "夏洛特阿馬利亞",
    lang: "zh-tw"
  },
  {
    id: "A05-002-00002",
    title: "多倫多",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00018",
    title: "大分",
    lang: "zh-tw"
  },
  {
    id: "A01-011-00010",
    title: "大叻",
    lang: "zh-tw"
  },
  {
    id: "A01-010-00048",
    title: "大城府",
    lang: "zh-tw"
  },
  {
    id: "A01-004-00019",
    title: "大邱",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00002",
    title: "大阪",
    lang: "zh-tw"
  },
  {
    id: "A08-005-00001",
    title: "奈洛比",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00006",
    title: "奈良",
    lang: "zh-tw"
  },
  {
    id: "A05-003-00006",
    title: "契琴伊薩",
    lang: "zh-tw"
  },
  {
    id: "A06-002-00002",
    title: "奧克蘭",
    lang: "zh-tw"
  },
  {
    id: "A01-008-00010",
    title: "奧斯陸",
    lang: "zh-tw"
  },
  {
    id: "A03-024-00001",
    title: "奧斯陸",
    lang: "zh-tw"
  },
  {
    id: "A03-010-00003",
    title: "奧洛穆克",
    lang: "zh-tw"
  },
  {
    id: "A03-023-00004",
    title: "奧良",
    lang: "zh-tw"
  },
  {
    id: "A05-001-00003",
    title: "奧蘭多",
    lang: "zh-tw"
  },
  {
    id: "A03-027-00004",
    title: "姆迪納",
    lang: "zh-tw"
  },
  {
    id: "A03-012-00003",
    title: "威尼斯",
    lang: "zh-tw"
  },
  {
    id: "A01-007-00004",
    title: "娘水镇",
    lang: "zh-tw"
  },
  {
    id: "A03-008-00002",
    title: "安卡拉",
    lang: "zh-tw"
  },
  {
    id: "A03-008-00003",
    title: "安塔利亞",
    lang: "zh-tw"
  },
  {
    id: "A01-001-00017",
    title: "宜蘭",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00032",
    title: "宮城",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00045",
    title: "宮崎",
    lang: "zh-tw"
  },
  {
    id: "A01-008-00002",
    title: "宿霧",
    lang: "zh-tw"
  },
  {
    id: "A01-011-00011",
    title: "富國島",
    lang: "zh-tw"
  },
  {
    id: "A03-014-00019",
    title: "富埃特文圖拉島",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00014",
    title: "富山",
    lang: "zh-tw"
  },
  {
    id: "A05-002-00009",
    title: "尼加拉瀑布城",
    lang: "zh-tw"
  },
  {
    id: "A03-013-00022",
    title: "尼姆",
    lang: "zh-tw"
  },
  {
    id: "A03-013-00003",
    title: "尼斯",
    lang: "zh-tw"
  },
  {
    id: "A03-013-00014",
    title: "尼斯",
    lang: "zh-tw"
  },
  {
    id: "A01-001-00015",
    title: "屏東",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00043",
    title: "山形",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00010",
    title: "山梨",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00015",
    title: "岐阜",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00035",
    title: "岡山",
    lang: "zh-tw"
  },
  {
    id: "A01-012-00001",
    title: "峇里島",
    lang: "zh-tw"
  },
  {
    id: "A01-011-00007",
    title: "峴港",
    lang: "zh-tw"
  },
  {
    id: "A03-014-00001",
    title: "巴塞隆納",
    lang: "zh-tw"
  },
  {
    id: "A05-005-00002",
    title: "巴拉德羅",
    lang: "zh-tw"
  },
  {
    id: "A01-008-00006",
    title: "巴拉望",
    lang: "zh-tw"
  },
  {
    id: "A09-004-00001",
    title: "巴拿馬城",
    lang: "zh-tw"
  },
  {
    id: "A03-013-00001",
    title: "巴黎",
    lang: "zh-tw"
  },
  {
    id: "A03-033-00001",
    title: "布加勒斯特",
    lang: "zh-tw"
  },
  {
    id: "A04-005-00001",
    title: "布宜諾斯艾利斯",
    lang: "zh-tw"
  },
  {
    id: "A03-010-00001",
    title: "布拉格",
    lang: "zh-tw"
  },
  {
    id: "A03-010-00004",
    title: "布爾諾",
    lang: "zh-tw"
  },
  {
    id: "A03-007-00001",
    title: "布達佩斯",
    lang: "zh-tw"
  },
  {
    id: "A06-001-00008",
    title: "布里斯本",
    lang: "zh-tw"
  },
  {
    id: "A03-015-00001",
    title: "布魯塞爾",
    lang: "zh-tw"
  },
  {
    id: "A06-001-00013",
    title: "布魯姆",
    lang: "zh-tw"
  },
  {
    id: "A05-001-00044",
    title: "帕索羅布斯",
    lang: "zh-tw"
  },
  {
    id: "A05-001-00036",
    title: "底特律",
    lang: "zh-tw"
  },
  {
    id: "A04-008-00002",
    title: "庫斯科",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00011",
    title: "廣島",
    lang: "zh-tw"
  },
  {
    id: "A01-002-00004",
    title: "廣州",
    lang: "zh-tw"
  },
  {
    id: "A01-002-00051",
    title: "廣東",
    lang: "zh-tw"
  },
  {
    id: "A03-004-00013",
    title: "弗拉斯多夫",
    lang: "zh-tw"
  },
  {
    id: "A05-004-00003",
    title: "弗里波特",
    lang: "zh-tw"
  },
  {
    id: "A06-001-00017",
    title: "弗雷澤島",
    lang: "zh-tw"
  },
  {
    id: "A01-001-00011",
    title: "彰化",
    lang: "zh-tw"
  },
  {
    id: "A04-002-00004",
    title: "復活節島",
    lang: "zh-tw"
  },
  {
    id: "A03-004-00007",
    title: "德勒斯登",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00024",
    title: "德島",
    lang: "zh-tw"
  },
  {
    id: "A08-003-00003",
    title: "德爾班",
    lang: "zh-tw"
  },
  {
    id: "A01-004-00009",
    title: "忠清北道",
    lang: "zh-tw"
  },
  {
    id: "A01-004-00010",
    title: "忠清南道",
    lang: "zh-tw"
  },
  {
    id: "A01-009-00011",
    title: "怡保",
    lang: "zh-tw"
  },
  {
    id: "A03-009-00005",
    title: "愛丁堡",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00049",
    title: "愛媛",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00026",
    title: "愛知",
    lang: "zh-tw"
  },
  {
    id: "A06-001-00015",
    title: "愛麗斯泉",
    lang: "zh-tw"
  },
  {
    id: "A03-004-00005",
    title: "慕尼黑",
    lang: "zh-tw"
  },
  {
    id: "A01-004-00011",
    title: "慶尚北道",
    lang: "zh-tw"
  },
  {
    id: "A01-004-00006",
    title: "慶尚南道",
    lang: "zh-tw"
  },
  {
    id: "A01-004-00005",
    title: "慶州",
    lang: "zh-tw"
  },
  {
    id: "A05-001-00014",
    title: "懷俄明州",
    lang: "zh-tw"
  },
  {
    id: "A01-002-00003",
    title: "成都",
    lang: "zh-tw"
  },
  {
    id: "A01-001-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A01-002-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A01-003-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A01-004-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A01-005-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A01-006-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A01-007-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A01-008-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A01-009-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A01-010-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A01-011-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A01-012-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A01-013-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A01-014-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A01-015-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A03-001-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A03-002-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A03-003-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A03-004-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A03-005-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A03-006-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A03-007-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A03-008-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A03-009-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A03-010-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A03-011-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A03-012-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A03-013-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A03-014-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A03-015-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A03-016-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A03-017-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A03-018-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A03-019-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A03-020-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A03-021-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A03-022-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A03-023-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A03-024-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A03-025-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A03-026-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A03-027-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A04-002-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A05-001-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A05-002-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A05-003-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A06-001-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A06-002-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A06-003-99999",
    title: "所有城市",
    lang: "zh-tw"
  },
  {
    id: "A03-006-00006",
    title: "扎達爾",
    lang: "zh-tw"
  },
  {
    id: "A05-001-00006",
    title: "拉斯維加斯",
    lang: "zh-tw"
  },
  {
    id: "A09-005-00002",
    title: "拉福爾圖納",
    lang: "zh-tw"
  },
  {
    id: "A06-001-00022",
    title: "拜倫灣",
    lang: "zh-tw"
  },
  {
    id: "A01-010-00013",
    title: "拷叻",
    lang: "zh-tw"
  },
  {
    id: "A05-004-00002",
    title: "拿索",
    lang: "zh-tw"
  },
  {
    id: "A03-037-00001",
    title: "提比里斯",
    lang: "zh-tw"
  },
  {
    id: "A03-026-00001",
    title: "摩納哥",
    lang: "zh-tw"
  },
  {
    id: "A03-028-00002",
    title: "敖得薩",
    lang: "zh-tw"
  },
  {
    id: "A03-004-00009",
    title: "斯圖加特",
    lang: "zh-tw"
  },
  {
    id: "A03-025-00003",
    title: "斯塞新",
    lang: "zh-tw"
  },
  {
    id: "A03-022-00001",
    title: "斯德哥爾摩",
    lang: "zh-tw"
  },
  {
    id: "A01-013-00001",
    title: "新加坡",
    lang: "zh-tw"
  },
  {
    id: "A01-001-00006",
    title: "新北市",
    lang: "zh-tw"
  },
  {
    id: "A01-009-00008",
    title: "新山",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00021",
    title: "新潟",
    lang: "zh-tw"
  },
  {
    id: "A01-001-00009",
    title: "新竹",
    lang: "zh-tw"
  },
  {
    id: "A01-014-00005",
    title: "施亞努市",
    lang: "zh-tw"
  },
  {
    id: "A03-019-00002",
    title: "日内瓦",
    lang: "zh-tw"
  },
  {
    id: "A01-012-00005",
    title: "日惹",
    lang: "zh-tw"
  },
  {
    id: "A01-010-00002",
    title: "普吉島",
    lang: "zh-tw"
  },
  {
    id: "A05-003-00011",
    title: "普埃布拉",
    lang: "zh-tw"
  },
  {
    id: "A05-003-00010",
    title: "普拉亞德爾卡曼",
    lang: "zh-tw"
  },
  {
    id: "A03-029-00001",
    title: "普羅夫迪夫",
    lang: "zh-tw"
  },
  {
    id: "A01-014-00003",
    title: "暹粒",
    lang: "zh-tw"
  },
  {
    id: "A01-007-00003",
    title: "曼德勒",
    lang: "zh-tw"
  },
  {
    id: "A03-004-00012",
    title: "曼海姆",
    lang: "zh-tw"
  },
  {
    id: "A01-010-00001",
    title: "曼谷",
    lang: "zh-tw"
  },
  {
    id: "A01-011-00006",
    title: "會安",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00004",
    title: "札幌",
    lang: "zh-tw"
  },
  {
    id: "A03-004-00016",
    title: "杜塞道夫",
    lang: "zh-tw"
  },
  {
    id: "A03-006-00003",
    title: "杜布羅夫尼克",
    lang: "zh-tw"
  },
  {
    id: "A01-008-00008",
    title: "杜馬蓋地",
    lang: "zh-tw"
  },
  {
    id: "A01-002-00002",
    title: "杭州",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00001",
    title: "東京",
    lang: "zh-tw"
  },
  {
    id: "A01-007-00005",
    title: "東枝",
    lang: "zh-tw"
  },
  {
    id: "A03-004-00001",
    title: "柏林",
    lang: "zh-tw"
  },
  {
    id: "A05-001-00034",
    title: "查爾斯頓",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00012",
    title: "栃木",
    lang: "zh-tw"
  },
  {
    id: "A03-009-00006",
    title: "格拉斯哥",
    lang: "zh-tw"
  },
  {
    id: "A03-014-00018",
    title: "格拉納達",
    lang: "zh-tw"
  },
  {
    id: "A01-002-00005",
    title: "桂林",
    lang: "zh-tw"
  },
  {
    id: "A01-001-00008",
    title: "桃園",
    lang: "zh-tw"
  },
  {
    id: "A05-003-00009",
    title: "梅里達",
    lang: "zh-tw"
  },
  {
    id: "A03-008-00010",
    title: "棉堡",
    lang: "zh-tw"
  },
  {
    id: "A05-001-00029",
    title: "檀香山",
    lang: "zh-tw"
  },
  {
    id: "A01-009-00001",
    title: "檳城",
    lang: "zh-tw"
  },
  {
    id: "A03-012-00014",
    title: "比薩",
    lang: "zh-tw"
  },
  {
    id: "A01-004-00013",
    title: "水原",
    lang: "zh-tw"
  },
  {
    id: "A01-006-00001",
    title: "永珍",
    lang: "zh-tw"
  },
  {
    id: "A01-004-00003",
    title: "江原道",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00013",
    title: "沖繩",
    lang: "zh-tw"
  },
  {
    id: "A01-011-00005",
    title: "沙壩",
    lang: "zh-tw"
  },
  {
    id: "A08-001-00010",
    title: "沙姆沙伊赫",
    lang: "zh-tw"
  },
  {
    id: "A01-009-00005",
    title: "沙巴-亞庇",
    lang: "zh-tw"
  },
  {
    id: "A01-009-00009",
    title: "沙巴-山打根",
    lang: "zh-tw"
  },
  {
    id: "A01-009-00014",
    title: "沙巴-斗湖、仙本那",
    lang: "zh-tw"
  },
  {
    id: "A01-011-00001",
    title: "河內",
    lang: "zh-tw"
  },
  {
    id: "A03-004-00002",
    title: "法蘭克福",
    lang: "zh-tw"
  },
  {
    id: "A04-003-00001",
    title: "波哥大",
    lang: "zh-tw"
  },
  {
    id: "A05-001-00002",
    title: "波士頓",
    lang: "zh-tw"
  },
  {
    id: "A03-023-00002",
    title: "波多",
    lang: "zh-tw"
  },
  {
    id: "A03-004-00014",
    title: "波昂",
    lang: "zh-tw"
  },
  {
    id: "A03-013-00009",
    title: "波爾多",
    lang: "zh-tw"
  },
  {
    id: "A05-001-00041",
    title: "波特蘭",
    lang: "zh-tw"
  },
  {
    id: "A03-004-00023",
    title: "波茨坦",
    lang: "zh-tw"
  },
  {
    id: "A05-003-00003",
    title: "洛斯卡沃斯",
    lang: "zh-tw"
  },
  {
    id: "A05-001-00007",
    title: "洛杉磯",
    lang: "zh-tw"
  },
  {
    id: "A03-019-00004",
    title: "洛迦諾",
    lang: "zh-tw"
  },
  {
    id: "A08-001-00002",
    title: "洪加達",
    lang: "zh-tw"
  },
  {
    id: "A03-004-00019",
    title: "海德堡",
    lang: "zh-tw"
  },
  {
    id: "A01-010-00004",
    title: "清萊",
    lang: "zh-tw"
  },
  {
    id: "A01-010-00003",
    title: "清邁",
    lang: "zh-tw"
  },
  {
    id: "A03-009-00004",
    title: "湖區",
    lang: "zh-tw"
  },
  {
    id: "A05-002-00001",
    title: "溫哥華",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00025",
    title: "滋賀",
    lang: "zh-tw"
  },
  {
    id: "A03-004-00004",
    title: "漢堡",
    lang: "zh-tw"
  },
  {
    id: "A01-001-00019",
    title: "澎湖",
    lang: "zh-tw"
  },
  {
    id: "A01-005-00002",
    title: "澳門",
    lang: "zh-tw"
  },
  {
    id: "A01-004-00012",
    title: "濟州島",
    lang: "zh-tw"
  },
  {
    id: "A01-012-00002",
    title: "烏布",
    lang: "zh-tw"
  },
  {
    id: "A06-001-00011",
    title: "烏魯魯",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00044",
    title: "熊本",
    lang: "zh-tw"
  },
  {
    id: "A03-009-00002",
    title: "牛津",
    lang: "zh-tw"
  },
  {
    id: "A03-014-00014",
    title: "特內里費島",
    lang: "zh-tw"
  },
  {
    id: "A03-006-00008",
    title: "特羅吉爾",
    lang: "zh-tw"
  },
  {
    id: "A03-024-00003",
    title: "特羅姆瑟",
    lang: "zh-tw"
  },
  {
    id: "A03-004-00011",
    title: "班堡",
    lang: "zh-tw"
  },
  {
    id: "A01-006-00002",
    title: "琅勃拉邦",
    lang: "zh-tw"
  },
  {
    id: "A04-001-00003",
    title: "瑪瑙斯",
    lang: "zh-tw"
  },
  {
    id: "A09-005-00001",
    title: "瓜納卡斯特",
    lang: "zh-tw"
  },
  {
    id: "A03-014-00006",
    title: "瓦倫西亞",
    lang: "zh-tw"
  },
  {
    id: "A03-020-00004",
    title: "瓦特納冰川國家公園",
    lang: "zh-tw"
  },
  {
    id: "A03-027-00001",
    title: "瓦萊塔",
    lang: "zh-tw"
  },
  {
    id: "A03-014-00023",
    title: "畢爾包",
    lang: "zh-tw"
  },
  {
    id: "A06-002-00001",
    title: "皇后鎮",
    lang: "zh-tw"
  },
  {
    id: "A08-001-00007",
    title: "盧克索",
    lang: "zh-tw"
  },
  {
    id: "A03-012-00011",
    title: "盧卡",
    lang: "zh-tw"
  },
  {
    id: "A03-035-00001",
    title: "盧比安納",
    lang: "zh-tw"
  },
  {
    id: "A03-013-00018",
    title: "盧爾德",
    lang: "zh-tw"
  },
  {
    id: "A03-009-00012",
    title: "直布羅陀",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00033",
    title: "石川",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00007",
    title: "神奈川",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00028",
    title: "神戶",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00034",
    title: "福井",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00040",
    title: "福岡",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00037",
    title: "福島",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00031",
    title: "秋田",
    lang: "zh-tw"
  },
  {
    id: "A03-021-00007",
    title: "科克",
    lang: "zh-tw"
  },
  {
    id: "A03-018-00012",
    title: "科斯島",
    lang: "zh-tw"
  },
  {
    id: "A05-001-00035",
    title: "科羅拉多泉",
    lang: "zh-tw"
  },
  {
    id: "A03-021-00008",
    title: "科芙",
    lang: "zh-tw"
  },
  {
    id: "A03-004-00003",
    title: "科隆",
    lang: "zh-tw"
  },
  {
    id: "A03-013-00013",
    title: "第戎",
    lang: "zh-tw"
  },
  {
    id: "A03-018-00007",
    title: "米科諾斯",
    lang: "zh-tw"
  },
  {
    id: "A03-012-00006",
    title: "米蘭",
    lang: "zh-tw"
  },
  {
    id: "A08-003-00002",
    title: "約翰尼斯堡",
    lang: "zh-tw"
  },
  {
    id: "A05-001-00040",
    title: "納什維爾",
    lang: "zh-tw"
  },
  {
    id: "A03-004-00022",
    title: "紐倫堡",
    lang: "zh-tw"
  },
  {
    id: "A05-001-00016",
    title: "紐奧良",
    lang: "zh-tw"
  },
  {
    id: "A05-001-00001",
    title: "紐約",
    lang: "zh-tw"
  },
  {
    id: "A03-029-00002",
    title: "索菲亞",
    lang: "zh-tw"
  },
  {
    id: "A01-001-00023",
    title: "綠島",
    lang: "zh-tw"
  },
  {
    id: "A03-005-00001",
    title: "維也納",
    lang: "zh-tw"
  },
  {
    id: "A05-002-00006",
    title: "維多利亞",
    lang: "zh-tw"
  },
  {
    id: "A03-003-00001",
    title: "維爾紐斯",
    lang: "zh-tw"
  },
  {
    id: "A03-018-00006",
    title: "羅得",
    lang: "zh-tw"
  },
  {
    id: "A05-001-00037",
    title: "羅德岱堡",
    lang: "zh-tw"
  },
  {
    id: "A05-004-00001",
    title: "羅斯島",
    lang: "zh-tw"
  },
  {
    id: "A01-010-00014",
    title: "羅永",
    lang: "zh-tw"
  },
  {
    id: "A03-017-00002",
    title: "羅瓦涅米",
    lang: "zh-tw"
  },
  {
    id: "A03-012-00001",
    title: "羅馬",
    lang: "zh-tw"
  },
  {
    id: "A01-011-00012",
    title: "美奈",
    lang: "zh-tw"
  },
  {
    id: "A01-009-00016",
    title: "美里",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00016",
    title: "群馬",
    lang: "zh-tw"
  },
  {
    id: "A05-003-00012",
    title: "聖克里斯托瓦爾-德拉斯卡薩斯",
    lang: "zh-tw"
  },
  {
    id: "A03-012-00015",
    title: "聖吉米尼亞諾",
    lang: "zh-tw"
  },
  {
    id: "A05-001-00010",
    title: "聖地牙哥",
    lang: "zh-tw"
  },
  {
    id: "A05-001-00013",
    title: "聖塔巴巴拉",
    lang: "zh-tw"
  },
  {
    id: "A09-003-00001",
    title: "聖多明哥",
    lang: "zh-tw"
  },
  {
    id: "A05-001-00015",
    title: "聖奧古斯丁",
    lang: "zh-tw"
  },
  {
    id: "A03-001-00002",
    title: "聖彼得堡",
    lang: "zh-tw"
  },
  {
    id: "A03-018-00003",
    title: "聖托里尼",
    lang: "zh-tw"
  },
  {
    id: "A09-002-00001",
    title: "聖胡安",
    lang: "zh-tw"
  },
  {
    id: "A03-014-00007",
    title: "聖賽巴斯蒂安",
    lang: "zh-tw"
  },
  {
    id: "A06-001-00014",
    title: "聖靈群島",
    lang: "zh-tw"
  },
  {
    id: "A01-011-00002",
    title: "胡志明",
    lang: "zh-tw"
  },
  {
    id: "A03-020-00003",
    title: "胡薩維克",
    lang: "zh-tw"
  },
  {
    id: "A05-001-00008",
    title: "舊金山",
    lang: "zh-tw"
  },
  {
    id: "A03-013-00006",
    title: "艾克斯普羅旺斯",
    lang: "zh-tw"
  },
  {
    id: "A08-001-00005",
    title: "艾爾古納",
    lang: "zh-tw"
  },
  {
    id: "A03-004-00017",
    title: "艾爾福特",
    lang: "zh-tw"
  },
  {
    id: "A05-001-00009",
    title: "芝加哥",
    lang: "zh-tw"
  },
  {
    id: "A01-010-00007",
    title: "芭達雅",
    lang: "zh-tw"
  },
  {
    id: "A01-001-00005",
    title: "花蓮",
    lang: "zh-tw"
  },
  {
    id: "A01-011-00009",
    title: "芽莊",
    lang: "zh-tw"
  },
  {
    id: "A01-001-00010",
    title: "苗栗",
    lang: "zh-tw"
  },
  {
    id: "A05-001-00027",
    title: "茂宜島",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00020",
    title: "茨城",
    lang: "zh-tw"
  },
  {
    id: "A03-006-00007",
    title: "茲拉林島",
    lang: "zh-tw"
  },
  {
    id: "A03-001-00001",
    title: "莫斯科",
    lang: "zh-tw"
  },
  {
    id: "A01-010-00008",
    title: "華欣",
    lang: "zh-tw"
  },
  {
    id: "A03-025-00001",
    title: "華沙",
    lang: "zh-tw"
  },
  {
    id: "A05-001-00005",
    title: "華盛頓特區",
    lang: "zh-tw"
  },
  {
    id: "A03-004-00020",
    title: "萊比錫",
    lang: "zh-tw"
  },
  {
    id: "A03-004-00025",
    title: "萊茵河畔綠德斯翰",
    lang: "zh-tw"
  },
  {
    id: "A01-006-00003",
    title: "萬榮市",
    lang: "zh-tw"
  },
  {
    id: "A08-005-00002",
    title: "蒙巴薩",
    lang: "zh-tw"
  },
  {
    id: "A09-005-00003",
    title: "蒙特維多",
    lang: "zh-tw"
  },
  {
    id: "A01-007-00006",
    title: "蒲甘",
    lang: "zh-tw"
  },
  {
    id: "A09-003-00002",
    title: "蓬塔卡納",
    lang: "zh-tw"
  },
  {
    id: "A01-008-00009",
    title: "薄荷島",
    lang: "zh-tw"
  },
  {
    id: "A05-001-00023",
    title: "薩凡納",
    lang: "zh-tw"
  },
  {
    id: "A03-005-00003",
    title: "薩爾斯堡",
    lang: "zh-tw"
  },
  {
    id: "A01-002-00023",
    title: "蘇州",
    lang: "zh-tw"
  },
  {
    id: "A01-010-00005",
    title: "蘇美島",
    lang: "zh-tw"
  },
  {
    id: "A03-012-00018",
    title: "蘇連多",
    lang: "zh-tw"
  },
  {
    id: "A03-019-00001",
    title: "蘇黎世",
    lang: "zh-tw"
  },
  {
    id: "A01-009-00002",
    title: "蘭卡威",
    lang: "zh-tw"
  },
  {
    id: "A01-010-00018",
    title: "蘭塔島",
    lang: "zh-tw"
  },
  {
    id: "A01-001-00022",
    title: "蘭嶼",
    lang: "zh-tw"
  },
  {
    id: "A01-002-00009",
    title: "西安",
    lang: "zh-tw"
  },
  {
    id: "A03-012-00017",
    title: "西恩納",
    lang: "zh-tw"
  },
  {
    id: "A05-001-00042",
    title: "西棕櫚灘",
    lang: "zh-tw"
  },
  {
    id: "A01-002-00039",
    title: "西藏",
    lang: "zh-tw"
  },
  {
    id: "A05-001-00012",
    title: "西雅圖",
    lang: "zh-tw"
  },
  {
    id: "A03-023-00005",
    title: "豐沙爾",
    lang: "zh-tw"
  },
  {
    id: "A01-010-00009",
    title: "象島",
    lang: "zh-tw"
  },
  {
    id: "A05-001-00019",
    title: "費城",
    lang: "zh-tw"
  },
  {
    id: "A05-001-00030",
    title: "賽多納",
    lang: "zh-tw"
  },
  {
    id: "A03-017-00001",
    title: "赫爾辛基",
    lang: "zh-tw"
  },
  {
    id: "A08-004-00002",
    title: "路易港",
    lang: "zh-tw"
  },
  {
    id: "A08-001-00004",
    title: "達哈布",
    lang: "zh-tw"
  },
  {
    id: "A06-001-00012",
    title: "達爾文",
    lang: "zh-tw"
  },
  {
    id: "A03-006-00004",
    title: "達爾馬提亞",
    lang: "zh-tw"
  },
  {
    id: "A03-004-00021",
    title: "邁森",
    lang: "zh-tw"
  },
  {
    id: "A03-018-00011",
    title: "邁泰奧拉",
    lang: "zh-tw"
  },
  {
    id: "A05-001-00004",
    title: "邁阿密",
    lang: "zh-tw"
  },
  {
    id: "A03-012-00013",
    title: "那不勒斯",
    lang: "zh-tw"
  },
  {
    id: "A03-021-00001",
    title: "都柏林",
    lang: "zh-tw"
  },
  {
    id: "A03-023-00001",
    title: "里斯本",
    lang: "zh-tw"
  },
  {
    id: "A03-013-00019",
    title: "里昂",
    lang: "zh-tw"
  },
  {
    id: "A04-001-00006",
    title: "里約熱內盧",
    lang: "zh-tw"
  },
  {
    id: "A03-009-00008",
    title: "里茲",
    lang: "zh-tw"
  },
  {
    id: "A01-004-00014",
    title: "金浦",
    lang: "zh-tw"
  },
  {
    id: "A01-014-00002",
    title: "金邊",
    lang: "zh-tw"
  },
  {
    id: "A01-004-00002",
    title: "釜山",
    lang: "zh-tw"
  },
  {
    id: "A01-004-00018",
    title: "鎮海",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00041",
    title: "長崎",
    lang: "zh-tw"
  },
  {
    id: "A01-008-00003",
    title: "長灘島",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00017",
    title: "長野",
    lang: "zh-tw"
  },
  {
    id: "A08-003-00001",
    title: "開普敦",
    lang: "zh-tw"
  },
  {
    id: "A08-001-00001",
    title: "開羅",
    lang: "zh-tw"
  },
  {
    id: "A01-009-00012",
    title: "關丹",
    lang: "zh-tw"
  },
  {
    id: "A06-003-00001",
    title: "關島",
    lang: "zh-tw"
  },
  {
    id: "A03-011-00001",
    title: "阿姆斯特丹",
    lang: "zh-tw"
  },
  {
    id: "A01-019-00002",
    title: "阿布達比",
    lang: "zh-tw"
  },
  {
    id: "A03-014-00016",
    title: "阿德赫",
    lang: "zh-tw"
  },
  {
    id: "A06-001-00009",
    title: "阿德雷得",
    lang: "zh-tw"
  },
  {
    id: "A03-008-00012",
    title: "阿拉尼亞",
    lang: "zh-tw"
  },
  {
    id: "A05-001-00032",
    title: "阿拉斯加",
    lang: "zh-tw"
  },
  {
    id: "A03-022-00003",
    title: "阿比斯庫",
    lang: "zh-tw"
  },
  {
    id: "A03-023-00003",
    title: "阿爾布費拉",
    lang: "zh-tw"
  },
  {
    id: "A03-008-00013",
    title: "阿瓦諾斯",
    lang: "zh-tw"
  },
  {
    id: "A03-012-00008",
    title: "阿西西",
    lang: "zh-tw"
  },
  {
    id: "A04-009-00001",
    title: "阿魯巴",
    lang: "zh-tw"
  },
  {
    id: "A03-018-00001",
    title: "雅典",
    lang: "zh-tw"
  },
  {
    id: "A06-001-00002",
    title: "雪梨",
    lang: "zh-tw"
  },
  {
    id: "A01-001-00013",
    title: "雲林",
    lang: "zh-tw"
  },
  {
    id: "A03-020-00002",
    title: "雷克雅維克",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00030",
    title: "青森",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00009",
    title: "靜岡",
    lang: "zh-tw"
  },
  {
    id: "A01-011-00008",
    title: "順化",
    lang: "zh-tw"
  },
  {
    id: "A01-004-00001",
    title: "首爾",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00038",
    title: "香川",
    lang: "zh-tw"
  },
  {
    id: "A01-005-00001",
    title: "香港",
    lang: "zh-tw"
  },
  {
    id: "A01-009-00010",
    title: "馬六甲",
    lang: "zh-tw"
  },
  {
    id: "A08-001-00008",
    title: "馬卡迪灣",
    lang: "zh-tw"
  },
  {
    id: "A01-008-00001",
    title: "馬尼拉",
    lang: "zh-tw"
  },
  {
    id: "A03-014-00002",
    title: "馬德里",
    lang: "zh-tw"
  },
  {
    id: "A03-014-00004",
    title: "馬拉加",
    lang: "zh-tw"
  },
  {
    id: "A08-002-00003",
    title: "馬拉喀什",
    lang: "zh-tw"
  },
  {
    id: "A03-008-00008",
    title: "馬爾馬里斯港",
    lang: "zh-tw"
  },
  {
    id: "A03-014-00010",
    title: "馬約卡島",
    lang: "zh-tw"
  },
  {
    id: "A08-001-00009",
    title: "馬薩阿拉姆",
    lang: "zh-tw"
  },
  {
    id: "A03-013-00002",
    title: "馬賽",
    lang: "zh-tw"
  },
  {
    id: "A03-021-00005",
    title: "高威",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00023",
    title: "高知",
    lang: "zh-tw"
  },
  {
    id: "A01-001-00004",
    title: "高雄",
    lang: "zh-tw"
  },
  {
    id: "A05-002-00005",
    title: "魁北克",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00050",
    title: "鳥取",
    lang: "zh-tw"
  },
  {
    id: "A05-001-00021",
    title: "鹽湖城",
    lang: "zh-tw"
  },
  {
    id: "A01-003-00046",
    title: "鹿兒島",
    lang: "zh-tw"
  },
  {
    id: "A05-001-00038",
    title: "麥爾茲堡",
    lang: "zh-tw"
  },
  {
    id: "A05-002-00004",
    title: "黃刀鎮",
    lang: "zh-tw"
  },
  {
    id: "A06-001-00007",
    title: "黃金海岸",
    lang: "zh-tw"
  },
  {
    id: "A08-004-00001",
    title: "黑河區",
    lang: "zh-tw"
  },
  {
    id: "A09-002-00002",
    title: "龐塞",
    lang: "zh-tw"
  },
  {
    id: "A03-012-00012",
    title: "龐貝",
    lang: "zh-tw"
  }
];

const settingsIsOn = [{ id: "00", name: "上架" }, { id: "10", name: "下架" }];

const languageFamily = [{ id: "zh-tw", name: "繁體中文" }];

const visibilityLabel = {
  guide: "Show in Guide APP",
  kkday: "Show in KKday Search",
  kkhome: "Show in KKday Home",
  kkorder: "Show in KKday Order"
};

const richTextInputToolbarOptions = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }, { header: 1 }, { header: 2 }],
  ["bold", "italic", "underline", "strike"],
  [{ list: "ordered" }, { list: "bullet" }],
  [
    {
      color: [
        "#3FCCE4",
        "#26BEC9",
        "#138184",
        "#008A00",
        "#006100",
        "#1672E3",
        "#0066CC",
        "#FEE269",
        "#FFCC33",
        "#FFFFFF",
        "#BBBBBB",
        "#999999",
        "#888888",
        "#666666",
        "#444444",
        "#333333",
        "#000000",
        "#EBD6FF",
        "#6B24B2",
        "#F18963",
        "#EF7A70",
        "#E88D85",
        "#E60000",
        "#A10000"
      ]
    },
    { background: [] }
  ],
  ["link", "video", "image"],
  ["clean"]
];

export {
  settingsIsOn,
  citiesLangOptions,
  languageFamily,
  countryCityUtility,
  visibilityLabel,
  richTextInputToolbarOptions
};
