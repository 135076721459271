import _ from "lodash";

export const Subscription = () => {
  const model = {
    subscribers: [],
    notify() {
      _.forEach(model.subscribers, fn => fn());
    },
    add(subscriber) {
      model.subscribers.push(subscriber);
    },
    remove(subscriber) {
      _.remove(model.subscribers, item => item === subscriber);
    }
  };
  return model;
};

export const DataSource = opt => {
  const { loadDataFn, subscription = Subscription(), loadOnNull = true } = opt;
  const store = { data: null };
  const dataSource = {
    subscription,
    get(param) {
      if (!store.data) {
        if (loadOnNull) {
          dataSource.load(param);
        }
      } else {
        return store.data;
      }
    },
    set(data) {
      store.data = data;
      subscription.notify();
    },
    load(param) {
      loadDataFn(param).then(data => dataSource.set(data));
    },
    refresh(param) {
      dataSource.set(null);
      dataSource.load(param);
    }
  };
  return dataSource;
};
