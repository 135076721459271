import React from "react";
import { ReferenceField, FunctionField, TextField } from "react-admin";
import { UserPhotoDisplayNameField } from "./UserFields";

const createElement = (record, props) => {
  if (record.target.type === "question") {
    return (
      <ReferenceField
        {...props}
        label="問題"
        source="target.question.id"
        reference="questions"
        sortable={false}
      >
        <TextField source="title" />
      </ReferenceField>
    );
  }
  if (record.target.type === "answer") {
    return (
      <ReferenceField
        {...props}
        label="回答"
        source="target.answer.id"
        reference="answers"
        sortable={false}
      >
        <TextField source="content.text" />
      </ReferenceField>
    );
  }
  if (record.target.type === "user") {
    return (
      <ReferenceField
        {...props}
        label="回答"
        source="target.user.id"
        reference="users"
        sortable={false}
      >
        <UserPhotoDisplayNameField />
      </ReferenceField>
    );
  }
  return null;
};

const ReportTargetField = props => (
  <FunctionField {...props} render={record => createElement(record, props)} />
);

ReportTargetField.defaultProps = {
  addLabel: true
};

export default ReportTargetField;
