import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Link,
  Show,
  SimpleShowLayout,
  FunctionField,
  TextField,
  DateField,
  BooleanField,
  ImageField,
  RichTextField,
  Create,
  Edit,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  LongTextInput,
  required,
  minLength,
  maxLength
} from "react-admin";
import { DateTimeInput } from "react-admin-date-inputs";
import {
  TaskAuthorReferenceField,
  BoardReferenceField
} from "../../../components/qa/ReferenceFields";
import { CreatorLinkField } from "../../../components/qa/LinkFields";
import { ContentImagesInputField } from "../../../components/qa/ImagesInputField";
import QuestionTagInputField from "../../../components/qa/QuestionTagInputField";
import { RunNowButton } from "./actions";

const style = {
  info: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "2em",
    minWidth: "8em"
  },
  booleanInfo: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "2em"
  }
};

const displayExecuteResult = record => {
  if (record.result.lastRun.result) {
    return record.result.lastRun.result.status === "success" ? (
      <Link to={`/questions/${record.result.lastRun.result.question.id}/show`}>
        {record.result.lastRun.result.status}
      </Link>
    ) : (
      `${record.result.lastRun.result.status}: ${
        record.result.lastRun.result.error
      }`
    );
  } else {
    return "";
  }
};

export const ScheduleShow = withStyles(style)(({ classes, ...props }) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" label="ID" />
      <DateField
        showTime
        source="time.created"
        label="建立時間"
        className={classes.info}
      />
      <DateField
        showTime
        source="time.updated"
        label="編輯時間"
        className={classes.info}
      />
      <CreatorLinkField label="建立者" size={30} />
      <DateField
        showTime
        source="schedule.time.time"
        label="排程時間"
        className={classes.info}
      />
      <BooleanField
        source="status.executed"
        label="已執行"
        className={classes.booleanInfo}
      />
      <DateField
        showTime
        source="result.lastRun.time.time"
        label="執行時間"
        className={classes.info}
      />
      <FunctionField
        label="執行結果"
        render={displayExecuteResult}
        className={classes.info}
      />
      <TextField source="task.type" label="類型" />
      <TaskAuthorReferenceField label="發表身份" className={classes.info} />
      <BoardReferenceField
        label="城市"
        source="task.payload.boardId"
        className={classes.info}
      />
      <TextField
        source="task.payload.tags[0].name"
        label="標籤"
        className={classes.info}
      />
      <TextField source="task.payload.title" label="標題" />
      <RichTextField source="task.payload.content" label="內容" />
      <ImageField
        source="task.payload.images"
        src="content"
        title="content"
        label="圖片"
      />
      <RunNowButton />
    </SimpleShowLayout>
  </Show>
));

const validateTitle = [required(), minLength(2), maxLength(30)];
const validateAuthor = required();
const validateBoard = required();
const validScheduleTime = required();

export const ScheduleCreate = withStyles(style)(({ classes, ...props }) => {
  return (
    <Create {...props}>
      <SimpleForm redirect="show" submitOnEnter={false}>
        <ReferenceInput
          label="作者"
          source="authorId"
          reference="users"
          perPage={1000}
          sort={{
            field: "displayName",
            order: "ASC"
          }}
          filter={{ internalUser: true, validUser: true }}
          validate={validateAuthor}
        >
          <SelectInput optionText="displayName" />
        </ReferenceInput>
        <ReferenceInput
          label="討論版"
          source="boardId"
          reference="boards"
          perPage={1000}
          sort={{
            field: "name",
            order: "ASC"
          }}
          validate={validateBoard}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <DateTimeInput
          source="scheduleDate"
          label="發表時間"
          options={{
            format: "yyyy/MM/dd, HH:mm:ss",
            disableFuture: false,
            minDate: new Date(),
            ampm: true,
            showTodayButton: true
          }}
          validate={validScheduleTime}
        />
        <QuestionTagInputField label="分類" source="tag" />
        <TextInput
          style={{ width: 544 }}
          label="標題"
          source="title"
          validate={validateTitle}
        />
        <LongTextInput label="內容" source="content" />
        <ContentImagesInputField source="images" label="相關圖片" />
      </SimpleForm>
    </Create>
  );
});

export const ScheduleEdit = withStyles(style)(({ classes, ...props }) => {
  return (
    <Edit {...props}>
      <SimpleForm redirect="show" submitOnEnter={false}>
        <ReferenceInput
          label="作者"
          source="task.payload.authorId"
          reference="users"
          perPage={1000}
          sort={{
            field: "displayName",
            order: "ASC"
          }}
          filter={{ internalUser: true }}
          validate={validateAuthor}
        >
          <SelectInput optionText="displayName" />
        </ReferenceInput>
        <ReferenceInput
          label="討論版"
          source="task.payload.boardId"
          reference="boards"
          perPage={1000}
          sort={{
            field: "name",
            order: "ASC"
          }}
          validate={validateBoard}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <DateTimeInput
          source="schedule.time.time"
          label="排程時間"
          options={{
            format: "yyyy/MM/dd, HH:mm:ss",
            disableFuture: false,
            minDate: new Date(),
            ampm: true,
            showTodayButton: true
          }}
          validate={validScheduleTime}
        />
        <QuestionTagInputField label="分類" source="task.payload.tags[0]" />
        <TextInput
          style={{ width: 544 }}
          label="標題"
          source="task.payload.title"
          validate={validateTitle}
        />
        <LongTextInput label="內容" source="task.payload.content" />
        <ContentImagesInputField
          source="task.payload.images"
          label="相關圖片"
        />
      </SimpleForm>
    </Edit>
  );
});
