import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  BooleanField,
  Create,
  Edit,
  SimpleForm,
  DisabledInput,
  LongTextInput,
  TextInput,
  ReferenceInput,
  AutocompleteInput
} from "react-admin";
import {
  UserLinkField,
  AddedByUserLinkField
} from "../../../components/qa/LinkFields";

const style = {
  info: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "2em",
    minWidth: "8em"
  },
  booleanInfo: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "2em"
  }
};

const AdminTitle = ({ record }) => {
  return <span>Admin {record ? record.displayName : ""}</span>;
};

export const AdminShow = withStyles(style)(({ classes, ...props }) => (
  <Show title={<AdminTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="id" label="ID" className={classes.info} />
        <DateField
          showTime
          source="time.created"
          label="建立時間"
          className={classes.info}
        />
        <DateField
          showTime
          source="time.updated"
          label="編輯時間"
          className={classes.info}
        />
        <UserLinkField label="User" />
        <AddedByUserLinkField label="Added by" />
        <TextField label="Note" source="note" />
        <BooleanField
          source="status.deleted"
          label="帳號已刪除"
          className={classes.booleanInfo}
        />
      </Tab>
    </TabbedShowLayout>
  </Show>
));

export const AdminEdit = props => (
  <Edit title={<AdminTitle />} {...props}>
    <SimpleForm>
      <DisabledInput label="Id" source="id" />
      <DisabledInput label="User" source="user.displayName" />
      <DisabledInput label="Added By" source="addedBy.displayName" />
      <LongTextInput source="note" />
    </SimpleForm>
  </Edit>
);

export const AdminCreate = props => (
  <Create title="Add Admin" {...props}>
    <SimpleForm defaultValue={{}}>
      <ReferenceInput
        label="User to add"
        source="targetUserId"
        reference="users"
      >
        <AutocompleteInput
          options={{
            fullWidth: true
          }}
          optionText={record =>
            `[${record.id}][${record.email.primary}][${record.displayName}]`
          }
        />
      </ReferenceInput>
      <TextInput source="magic" label="access key" />
      <LongTextInput source="note" label="note" />
    </SimpleForm>
  </Create>
);
