import React from "react";
import Icon from "@material-ui/icons/ViewModule";
import { Resource } from "react-admin";
import { TemplateBlockList } from "./template-blocks";
import {
  TemplateBlockShow,
  TemplateBlockEdit,
  TemplateBlockCreate
} from "./template-block";

const TemplateBlockResource = props => {
  return (
    <Resource
      {...props}
      list={TemplateBlockList}
      show={TemplateBlockShow}
      create={TemplateBlockCreate}
      edit={TemplateBlockEdit}
      icon={Icon}
    />
  );
};

export default TemplateBlockResource;
