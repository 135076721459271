import React from "react";
import _ from "lodash";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Show,
  TabbedShowLayout,
  Tab,
  BooleanField,
  TextField,
  DateField,
  ImageField,
  RichTextField,
  ReferenceManyField,
  Datagrid,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  LongTextInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  required,
  minLength,
  maxLength
} from "react-admin";
import { DateTimeInput } from "react-admin-date-inputs";
import {
  AuthorReferenceField,
  BoardReferenceField
} from "../../../components/qa/ReferenceFields";
import {
  AuthorLinkField,
  UserLinkField,
  LikeLinkField,
  ReportLinkField,
  ReporterLinkField,
  AnswerLinkField
} from "../../../components/qa/LinkFields";
import { ContentImagesInputField } from "../../../components/qa/ImagesInputField";
import QuestionTagInputField from "../../../components/qa/QuestionTagInputField";
import { SimpleList } from "../../../components/customize-react-admin";
import AddAnswerButton from "../../../components/qa/AddAnswerButton";

const style = {
  info: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "2em",
    minWidth: "8em"
  },
  booleanInfo: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "2em"
  }
};

export const QuestionShow = withStyles(style)(({ classes, ...props }) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="summary">
          <TextField source="id" label="ID" className={classes.info} />
          <DateField
            showTime
            source="time.created"
            label="建立時間"
            className={classes.info}
          />
          <DateField
            showTime
            source="time.updated"
            label="編輯時間"
            className={classes.info}
          />
          <TextField source="title" label="標題" />
          <AuthorReferenceField
            label="作者"
            size={30}
            className={classes.info}
          />
          <BoardReferenceField label="城市" className={classes.info} />
          <TextField source="tag.name" label="標籤" className={classes.info} />
          <TextField
            source="answer.count"
            label="回答數"
            className={classes.info}
          />
          <TextField
            source="like.total"
            label="按讚數"
            className={classes.info}
          />
          <TextField
            source="bookmark.count"
            label="收藏數"
            className={classes.info}
          />
          <RichTextField source="content.text" label="內容" />
          <ImageField
            source="content.images"
            src="content"
            title="content"
            label="圖片"
          />
          <BooleanField
            source="status.valid"
            label="顯示"
            className={classes.booleanInfo}
          />
          <BooleanField
            source="status.sticky"
            label="置頂"
            className={classes.booleanInfo}
          />
          <BooleanField
            source="status.archived"
            label="封存"
            className={classes.booleanInfo}
          />
          <BooleanField
            source="status.locked"
            label="鎖住"
            className={classes.booleanInfo}
          />
          <BooleanField
            source="status.deleted"
            label="刪除"
            className={classes.booleanInfo}
          />
        </Tab>
        <Tab label="answers" path="answers">
          <ReferenceManyField
            reference="answers"
            target="questionId"
            addLabel={false}
            perPage={100}
            sort={{
              field: "time.created",
              order: "ASC"
            }}
          >
            <Datagrid>
              <AnswerLinkField source="id" label="ID" sortable={false} />
              <AuthorLinkField label="作者" sortable={false} />
              <TextField source="content.text" label="內容" sortable={false} />
              <DateField showTime source="time.created" label="建立時間" />
            </Datagrid>
          </ReferenceManyField>
          <AddAnswerButton />
        </Tab>
        <Tab label="likes" path="likes">
          <ReferenceManyField
            reference="likes"
            target="targetId"
            addLabel={false}
            perPage={25}
            sort={{
              field: "time.created",
              order: "ASC"
            }}
          >
            <Datagrid>
              <LikeLinkField source="id" label="ID" sortable={false} />
              <UserLinkField label="按讚者" sortable={false} />
              <TextField source="type" label="讚類型" sortable={false} />
              <DateField showTime source="time.created" label="建立時間" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="reports" path="reports">
          <ReferenceManyField
            reference="reports"
            target="targetId"
            addLabel={false}
            perPage={25}
            sort={{
              field: "time.created",
              order: "DESC"
            }}
          >
            <Datagrid>
              <ReportLinkField source="id" label="ID" sortable={false} />
              <ReporterLinkField label="檢舉者" sortable={false} />
              <TextField source="content.text" label="原因" sortable={false} />
              <TextField source="status.code" label="狀態" sortable={false} />
              <BooleanField
                source="status.deleted"
                label="刪除"
                sortable={false}
              />
              <DateField showTime source="time.created" label="建立時間" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="events" path="event">
          <ReferenceManyField
            reference="events"
            target="actorId"
            addLabel={false}
            perPage={100}
          >
            <SimpleList
              primaryText={record => record.name || "n/a"}
              secondaryText={record =>
                JSON.stringify(_.last(record.trace), null, 2)
              }
              tertiaryText={record =>
                new Date(record.time.created).toLocaleString()
              }
            />
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
});

const validateTitle = [required(), minLength(2), maxLength(30)];
const validateAuthor = required();
const validateBoard = required();

const createStyle = {
  inline: {
    display: "inline-block"
    // verticalAlign: "top",
    // marginRight: "2em",
    // minWidth: "8em"
  },
  booleanInfo: {
    display: "inline-block"
    // verticalAlign: "top",
    // marginRight: "2em"
  }
};

export const QuestionCreate = withStyles(createStyle)(
  ({ classes, ...props }) => {
    return (
      <Create {...props}>
        <SimpleForm redirect="show" submitOnEnter={false}>
          <ReferenceInput
            label="作者"
            source="authorId"
            reference="users"
            perPage={1000}
            sort={{
              field: "displayName",
              order: "ASC"
            }}
            filter={{ internalUser: true }}
            validate={validateAuthor}
          >
            <SelectInput optionText="displayName" />
          </ReferenceInput>
          <ReferenceInput
            label="討論版"
            source="boardId"
            reference="boards"
            perPage={1000}
            sort={{
              field: "name",
              order: "ASC"
            }}
            validate={validateBoard}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <DateTimeInput
            source="publishDate"
            label="發表時間"
            options={{
              format: "yyyy/MM/dd, HH:mm:ss",
              disableFuture: true,
              minDate: new Date("2017-04-01"),
              ampm: true,
              showTodayButton: true
            }}
          />
          <QuestionTagInputField label="分類" source="tag" />
          <TextInput
            style={{ width: 544 }}
            label="標題"
            source="title"
            validate={validateTitle}
          />
          <LongTextInput label="內容" source="content" />
          <ContentImagesInputField source="images" label="相關圖片" />
        </SimpleForm>
      </Create>
    );
  }
);

export const QuestionEdit = withStyles(style)(({ classes, ...props }) => {
  return (
    <Edit {...props}>
      <SimpleForm redirect="show" submitOnEnter={false}>
        <TextField source="id" label="ID" className={classes.info} />
        <DateTimeInput
          source="time.created"
          label="建立時間"
          options={{
            format: "yyyy/MM/dd, HH:mm:ss",
            disableFuture: true,
            minDate: new Date("2017-04-01"),
            ampm: true,
            showTodayButton: true
          }}
        />
        <DateTimeInput
          source="time.updated"
          label="編輯時間"
          options={{
            format: "yyyy/MM/dd, HH:mm:ss",
            disableFuture: true,
            minDate: new Date("2017-04-01"),
            ampm: true,
            showTodayButton: true
          }}
        />
        <TextInput source="title" label="標題" />
        <AuthorReferenceField
          label="作者"
          size={30}
          formClassName={classes.info}
        />
        <BoardReferenceField label="城市" formClassName={classes.info} />
        <TextField
          source="answer.count"
          label="回答數"
          formClassName={classes.info}
        />
        <TextField
          source="like.total"
          label="按讚數"
          formClassName={classes.info}
        />
        <TextField
          source="bookmark.count"
          label="收藏數"
          formClassName={classes.info}
        />
        <QuestionTagInputField label="分類" source="tag" />
        <LongTextInput source="content.text" label="內容" />
        <ContentImagesInputField source="content.images" label="相關圖片" />
        <BooleanInput
          source="status.valid"
          label="顯示"
          formClassName={classes.booleanInfo}
        />
        <BooleanInput
          label="置頂"
          source="status.sticky"
          formClassName={classes.booleanInfo}
        />
        <BooleanInput
          label="封存"
          source="status.archived"
          formClassName={classes.booleanInfo}
        />
        <BooleanInput
          label="鎖住"
          source="status.locked"
          formClassName={classes.booleanInfo}
        />
      </SimpleForm>
    </Edit>
  );
});
