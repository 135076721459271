import React from "react";
import Icon from "@material-ui/icons/ThumbUp";
import { Resource } from "react-admin";
import { LikeList } from "./likes";
import { LikeShow } from "./like";

const LikeResource = props => {
  return (
    <Resource
      {...props}
      list={LikeList}
      show={LikeShow}
      edit={LikeShow}
      icon={Icon}
    />
  );
};

export default LikeResource;
