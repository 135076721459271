import { stringify } from "query-string";
import * as _ from "lodash";
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  DELETE,
  DELETE_MANY
} from "react-admin";
import { RUN_SCHEDULE, PROCESS_SCHEDULES } from "../../actions";
import { auth, GetCurrentFireBaseUser } from "../../firebase";
import { QA_API_ERROR } from "../../util";

const ScheduleProvider = opt => {
  const { config, httpClient } = opt;
  if (!config) {
    throw new Error("Missing config");
  }
  if (!httpClient) {
    throw new Error("Missing httpClient");
  }
  const provider = {
    config,
    handle(type, params) {
      switch (type) {
        case GET_LIST:
          return provider.list(params);
        case GET_ONE:
          return provider.get(params);
        case GET_MANY:
          return provider.getMany(params);
        case GET_MANY_REFERENCE:
          return provider.list(params);
        case CREATE:
          return provider.create(params);
        case UPDATE:
          return provider.update(params);
        case DELETE:
          return provider.delete(params);
        case DELETE_MANY:
          return provider.deleteMany(params);
        case RUN_SCHEDULE:
          return provider.runSchedule(params);
        case PROCESS_SCHEDULES:
          return provider.processSchedules(params);
        default:
          throw new Error(`ScheduleProvider unsupported action type: ${type}`);
      }
    },
    get(params) {
      return httpClient
        .get(`/api/admin/schedule/${params.id}`)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("ScheduleProvider.get Error", result, params);
          }
          let response = {
            data: result.schedule
          };
          return response;
        });
    },
    getMany(params) {
      const query = {
        ids: params.ids
      };
      return httpClient
        .get(`/api/admin/schedules?${stringify(query)}`)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR(
              "ScheduleProvider.getMany Error",
              result,
              params
            );
          }
          const response = {
            data: result.schedules || null
          };
          return response;
        });
    },
    list(params) {
      return GetCurrentFireBaseUser()
        .then(firebaseUser => firebaseUser.getIdToken(false))
        .then(token => {
          const query = {
            token,
            order: params.sort.field,
            sort: params.sort.order.toLowerCase(),
            limit: params.pagination.perPage
          };
          // pagination
          if (typeof params.pagination.page === "string") {
            const [
              pageNumber,
              afterOrBefore,
              id
            ] = params.pagination.page.split("_");
            if (afterOrBefore === "after") {
              query.after = id;
            } else if (afterOrBefore === "before") {
              query.before = id;
            }
            console.log("pageNumber: ", pageNumber);
          }
          return httpClient.get(`/api/admin/schedules?${stringify(query)}`);
        })
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("ScheduleProvider.list Error", result, params);
          }
          let response = {
            data: result.schedules || null,
            total: 1000
          };
          if (!response.data) {
            response.total = 0;
          }
          return response;
        });
    },
    create(params) {
      return auth.currentUser
        .getIdToken(false)
        .then(token => {
          const payload = {
            token,
            task: {
              type: "newQuestion",
              payload: {
                title: params.data.title,
                boardId: params.data.boardId,
                authorId: params.data.authorId
              }
            }
          };
          if (params.data.tag) {
            payload.task.payload.tags = [params.data.tag];
          }
          if (params.data.content) {
            payload.task.payload.content = params.data.content;
          }
          if (params.data.images && params.data.images.length) {
            payload.task.payload.images = params.data.images;
          }
          if (params.data.scheduleDate) {
            payload.scheduleTime = params.data.scheduleDate.getTime();
          }
          return httpClient.post(`/api/admin/schedules`, payload);
        })
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("ScheduleProvider.create Error", result, params);
          }
          const response = {
            data: result.schedule
          };
          return response;
        });
    },
    update(params) {
      return auth.currentUser
        .getIdToken(false)
        .then(token => {
          const taskPayload = {
            authorId: params.data.task.payload.authorId || null,
            boardId: params.data.task.payload.boardId || null,
            title: params.data.task.payload.title || null,
            content: params.data.task.payload.content || null
          };
          if (params.data.task.payload.tags) {
            taskPayload.tags = params.data.task.payload.tags;
          }
          if (params.data.task.payload.images) {
            taskPayload.images = params.data.task.payload.images;
          }
          const payload = {
            token,
            task: {
              payload: taskPayload
            }
          };
          const scheduleTime = _.get(params.data, "schedule.time.time");
          if (scheduleTime) {
            payload.scheduleTime = new Date(scheduleTime).getTime();
          }
          return httpClient.put(`/api/admin/schedule/${params.id}`, payload);
        })
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("ScheduleProvider.update Error", result, params);
          }
          const response = {
            data: result.schedule
          };
          return response;
        });
    },
    delete(params) {
      return auth.currentUser
        .getIdToken(false)
        .then(token => {
          const payload = {
            token
          };
          return httpClient.delete(`/api/admin/schedule/${params.id}`, {
            data: payload
          });
        })
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("ScheduleProvider.delete Error", result, params);
          }
          const response = {
            data: result.schedule
          };
          return response;
        });
    },
    deleteMany(params) {
      return auth.currentUser
        .getIdToken(false)
        .then(token => {
          const payload = {
            token,
            ids: params.ids
          };
          return httpClient.delete(`/api/admin/schedules`, { data: payload });
        })
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR(
              "ScheduleProvider.deleteMany Error",
              result,
              params
            );
          }
          const response = {
            data: result.deletedIds
          };
          return response;
        });
    },
    runSchedule(params) {
      return auth.currentUser
        .getIdToken(false)
        .then(token => {
          const payload = {
            token
          };
          return httpClient.post(
            `/api/admin/schedule/${params.id}/run`,
            payload
          );
        })
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR(
              "ScheduleProvider.runSchedule Error",
              result,
              params
            );
          }
          const response = {
            data: result.schedule
          };
          return response;
        });
    },
    processSchedules(params) {
      return auth.currentUser
        .getIdToken(false)
        .then(token => {
          const payload = {
            token,
            magic: "hip pop forever"
          };
          return httpClient.post(`/api/admin/schedules/process`, payload);
        })
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR(
              "ScheduleProvider.processSchedules Error",
              result,
              params
            );
          }
          const response = {
            data: result.processed
          };
          return response;
        });
    }
  };
  return provider;
};

export default ScheduleProvider;
