import React from "react";
import { Datagrid, TextField, DateField, Link } from "react-admin";
import { List } from "../../../components/customize-react-admin";
import Pagination from "../../../components/qa/Pagination";

const IDLinkField = props => (
  <Link to={`/coupon-stores/${props.record.id}/show`}>
    <TextField {...props} />
  </Link>
);

export const CouponList = props => {
  const perPage = 1000;
  const defaultSort = {
    field: "updated",
    order: "DESC"
  };
  return (
    <List
      {...props}
      title="Coupon Stroe List"
      perPage={perPage}
      sort={defaultSort}
      pagination={<Pagination />}
    >
      <Datagrid>
        <IDLinkField source="name" label="Coupon Stroe Name" sortable={false} />
        <DateField
          showTime
          source="updated"
          label="Last Modify"
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};
