import React from "react";
import _ from "lodash";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  Edit,
  SimpleForm,
  DisabledInput,
  TextInput,
  Create,
  required,
  minLength,
  maxLength
} from "react-admin";
import {
  TemplateBlockItemsField,
  TemplateBlockItemsInputField
} from "../../../components/guide/TemplateBlockItemsField";
import {
  TemplateBlockLayoutField,
  TemplateBlockLayoutInputField
} from "../../../components/guide/TemplateBlockLayoutFields";
import { TemplateBlockPreviewField } from "../../../components/guide/TemplateBlockPreviewFields";

const style = {
  info: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "2em",
    minWidth: "8em"
  },
  booleanInfo: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "2em"
  }
};

const Title = ({ record }) => {
  return (
    <span>Template-block {record ? `"${_.truncate(record.title)}"` : ""}</span>
  );
};

export const TemplateBlockShow = withStyles(style)(({ classes, ...props }) => {
  return (
    <Show title={<Title />} {...props}>
      <TabbedShowLayout>
        <Tab label="summary">
          <TextField source="id" label="ID" />
          <TextField source="title" label="標題" />
          <DateField
            showTime
            source="create_date"
            label="建立時間"
            className={classes.info}
          />
          <DateField
            showTime
            source="modify_date"
            label="編輯時間"
            className={classes.info}
          />
          <TemplateBlockLayoutField />
          <TemplateBlockItemsField label="Items" />
        </Tab>
        <Tab label="preview">
          <TemplateBlockPreviewField label="preview" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
});

const validateTemplateName = [required(), minLength(2), maxLength(50)];

export const TemplateBlockEdit = props => (
  <Edit {...props}>
    <SimpleForm redirect="show">
      <DisabledInput label="ID" source="id" />
      <TextInput label="標題" source="title" validate={validateTemplateName} />
      <TemplateBlockLayoutInputField />
      <TemplateBlockItemsInputField label="Items" source="items" />
    </SimpleForm>
  </Edit>
);

export const TemplateBlockCreate = props => (
  <Create {...props}>
    <SimpleForm
      redirect="show"
      defaultValue={{
        items: {},
        layout: 1
      }}
    >
      <TextInput label="標題" source="title" validate={validateTemplateName} />
      <TemplateBlockLayoutInputField />
      <TemplateBlockItemsInputField label="Items" source="items" />
    </SimpleForm>
  </Create>
);
