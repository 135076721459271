import _ from "lodash";
import { stringify } from "query-string";
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  UPDATE,
  CREATE,
  DELETE
} from "react-admin";
// import { auth } from "../../firebase";
import { QA_API_ERROR } from "../../util";

const CouponStoreProvider = opt => {
  const { config, httpClient } = opt;
  if (!config) {
    throw new Error("Missing config");
  }
  if (!httpClient) {
    throw new Error("Missing httpClient");
  }
  const provider = {
    handle(type, params) {
      console.log(type);
      switch (type) {
        case GET_LIST:
          return provider.list(params);
        // case GET_MANY_REFERENCE:
        //   return provider.list(params);
        case GET_ONE:
          return provider.get(params);
        case GET_MANY:
          return provider.getMany(params);
        case CREATE:
          return provider.create(params);
        case UPDATE:
          return provider.update(params);
        case DELETE:
          throw new Error(`Can't delete a store`);
        // case DELETE_MANY:
        //   return provider.deleteMany(params);
        default:
          throw new Error(`Unsupported action type: ${type}`);
      }
    },
    get(params) {
      return httpClient
        .get(`/api/coupon-stores/${params.id}`)
        .then(res => res.data)
        .then(result => {
          console.log(result);
          if (result.status !== "success") {
            throw QA_API_ERROR("KkblogProvider.get Error", result, params);
          }
          // something happen
          // object array convert
          let response = {
            data: result.couponStore
          };
          return response;
        });
    },
    list(params) {
      const query = {
        order: params.sort.field,
        sort: params.sort.order.toLowerCase(),
        limit: params.pagination.perPage
      };
      // filter active
      if (params.filter.active) {
        query.active = params.filter.active;
        query.active = "active";
      }
      // default
      return httpClient
        .get(`/api/coupon-stores?${stringify(query)}`)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("KkblogProvider.list Error", result, params);
          }
          result.couponStores = _.chain(result.couponStores)
            .orderBy(["updated", "title"], ["desc", "asc"])
            .value();
          return result;
        })
        .then(result => {
          let response = {
            data: result.couponStores || null,
            total: result.couponStores.length || 0
          };
          return response;
        });
    },
    getMany(params) {
      const query = {
        ids: params.ids
      };
      return httpClient
        .get(`/api/coupon-stores/many?${stringify(query)}`)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("CatoryProvider.getMany Error", result, params);
          }
          const response = {
            data: result.couponStores || null
          };
          return response;
        });
    },
    update(params) {
      const payload = {
        name: params.data.name,
        logo: params.data.logo,
        website: params.data.website,
        status: {
          active: params.data.status.active
        }
      };

      return httpClient
        .put(`/api/coupon-stores/${params.id}`, payload)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("CatoryProvider.update Error", result, params);
          }
          const response = {
            data: result.couponStore
          };
          return response;
        });
    },
    create(params) {
      const payload = {
        name: params.data.name,
        website: params.data.website,
        logo: params.data.logo,
        status: {
          active: params.data.status.active
        }
      };

      return httpClient
        .post(`/api/coupon-stores`, payload)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("CatoryProvider.create Error", result, params);
          }
          const response = {
            data: result.couponStore
          };
          return response;
        });
    }
  };
  return provider;
};

export default CouponStoreProvider;
