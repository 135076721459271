import React from "react";
import {
  Datagrid,
  TextField,
  DateField,
  Filter,
  ReferenceInput,
  SelectInput,
  TextInput,
  Link
} from "react-admin";
import { List } from "../../../components/customize-react-admin";
import Pagination from "../../../components/qa/Pagination";
import {
  AuthorLinkField,
  BoardLinkField
} from "../../../components/qa/LinkFields";
import { AuthorAutoCompleteInput } from "../../../components/qa/SearchFields";

const IDLinkField = props => (
  <Link to={`/questions/${props.record.id}/show`}>
    <TextField {...props} />
  </Link>
);

const QuestionFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <ReferenceInput
      label="討論版"
      source="board.id"
      reference="boards"
      sort={{ field: "time.created", order: "ASC" }}
      allowEmpty
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      label="作者"
      source="author.id"
      reference="users"
      allowEmpty
      alwaysOn
      resettable
    >
      <AuthorAutoCompleteInput />
    </ReferenceInput>
  </Filter>
);

export const QuestionList = props => {
  const perPage = 50;
  const defaultSort = {
    field: "time.created",
    order: "DESC"
  };
  return (
    <List
      {...props}
      title="問題列表"
      perPage={perPage}
      sort={defaultSort}
      pagination={<Pagination />}
      filters={<QuestionFilter />}
      filterDefaultValues={{ "board.id": null }}
    >
      <Datagrid>
        <IDLinkField source="title" label="標題" sortable={false} />
        <BoardLinkField source="board.name" label="討論版" sortable={false} />
        <AuthorLinkField label="作者" size={30} sortable={false} />
        <TextField source="answer.count" label="回答數" />
        <DateField showTime source="time.created" label="建立時間" />
      </Datagrid>
    </List>
  );
};
