import React from "react";
import { Link, TextField } from "react-admin";
import { UserPhotoDisplayNameField } from "./UserFields";

const QuestionLinkField = props => (
  <Link to={`/questions/${props.record.id}/show`}>
    <TextField {...props} />
  </Link>
);

const AnswerLinkField = props => (
  <Link to={`/answers/${props.record.id}/show`}>
    <TextField {...props} />
  </Link>
);

const BoardLinkField = props => (
  <Link to={`/boards/${props.record.board.id}/show`}>
    <TextField {...props} />
  </Link>
);

const LikeLinkField = props => (
  <Link to={`/likes/${props.record.id}/show`}>
    <TextField {...props} />
  </Link>
);

const ReportLinkField = props => (
  <Link to={`/reports/${props.record.id}/show`}>
    <TextField {...props} />
  </Link>
);

const ReporterLinkField = props => (
  <Link to={`/users/${props.record.reporter.id}/show`}>
    <UserPhotoDisplayNameField record={props.record.reporter} />
  </Link>
);

const AuthorLinkField = props => (
  <Link to={`/users/${props.record.author.id}/show`}>
    <UserPhotoDisplayNameField record={props.record.author} />
  </Link>
);

AuthorLinkField.defaultProps = {
  addLabel: true
};

const UserLinkField = props => (
  <Link to={`/users/${props.record.user.id}/show`}>
    <UserPhotoDisplayNameField record={props.record.user} />
  </Link>
);

UserLinkField.defaultProps = {
  addLabel: true
};

const CreatorLinkField = props => (
  <Link to={`/users/${props.record.creator.id}/show`}>
    <UserPhotoDisplayNameField record={props.record.creator} />
  </Link>
);

CreatorLinkField.defaultProps = {
  addLabel: true
};

const ScheduleLinkField = props => (
  <Link to={`/schedules/${props.record.id}/show`}>
    <TextField {...props} />
  </Link>
);

const TaskAuthorLinkField = props => (
  <Link to={`/users/${props.record.task.user.id}/show`}>
    <UserPhotoDisplayNameField record={props.record.task.user} />
  </Link>
);

TaskAuthorLinkField.defaultProps = {
  addLabel: true
};

const AddedByUserLinkField = props => (
  <Link to={`/users/${props.record.addedBy.id}/show`}>
    <UserPhotoDisplayNameField record={props.record.addedBy} />
  </Link>
);

AddedByUserLinkField.defaultProps = {
  addLabel: true
};

export {
  QuestionLinkField,
  AnswerLinkField,
  BoardLinkField,
  LikeLinkField,
  ReportLinkField,
  ReporterLinkField,
  AuthorLinkField,
  UserLinkField,
  CreatorLinkField,
  ScheduleLinkField,
  TaskAuthorLinkField,
  AddedByUserLinkField
};
