import React from "react";
import Icon from "@material-ui/icons/Settings";
import { Resource } from "react-admin";
import { SettingList } from "./settings";
import { SettingShow, SettingEdit, SettingCreate } from "./setting";

const SettingResource = props => {
  return (
    <Resource
      {...props}
      list={SettingList}
      show={SettingShow}
      create={SettingCreate}
      edit={SettingEdit}
      icon={Icon}
    />
  );
};

export default SettingResource;
