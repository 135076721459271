import React, { Component, Fragment } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { change, submit, isSubmitting } from "redux-form";
import { fetchEnd, fetchStart, showNotification, Button } from "react-admin";
import IconContentAdd from "@material-ui/icons/Add";
import IconCancel from "@material-ui/icons/Cancel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import { stringify } from "query-string";
import { DataSource } from "../../dashboard/util";
import { httpClient } from "../../services/data-provider/guide";

const ArticlesDS = DataSource({
  loadDataFn: () => {
    const query = {
      limit: 1000,
      order: "modify_date",
      sort: "DESC"
    };
    return httpClient
      .get(`/api/articles?${stringify(query)}`)
      .then(res => res.data)
      .then(result => {
        if (result.status !== "success") {
          throw new Error(`Error get latest articles: ${result.error.message}`);
        }
        return result.articles;
      });
  }
});

const VideosDS = DataSource({
  loadDataFn: () => {
    const query = {
      limit: 1000,
      order: "modify_date",
      sort: "DESC"
    };
    return httpClient
      .get(`/api/videos?${stringify(query)}`)
      .then(res => res.data)
      .then(result => {
        if (result.status !== "success") {
          throw new Error(`Error get latest videos: ${result.error.message}`);
        }
        return result.videos;
      });
  }
});

const CitiesDS = DataSource({
  loadDataFn: () => {
    const query = {
      limit: 1000
    };
    return httpClient
      .get(`/api/cities?${stringify(query)}`)
      .then(res => res.data)
      .then(result => {
        if (result.status !== "success") {
          throw new Error(`Error get latest cities: ${result.error.message}`);
        }
        return result.cities;
      });
  }
});

const renderStatus = status => {
  switch (status) {
    case "00":
      return "上架";
    case "10":
      return "下架";
    default:
      return status;
  }
};

const isOffShelf = status => {
  return status === "10" ? true : false;
};

const renderDate = dateValue => {
  const date = new Date(dateValue);
  return date.toLocaleDateString();
};

class ArticlesTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: ArticlesDS.get()
    };
  }
  componentDidMount() {
    ArticlesDS.subscription.add(this.handleChange);
  }
  componentWillUnmount() {
    ArticlesDS.subscription.remove(this.handleChange);
  }
  handleChange = () => {
    this.setState({
      articles: ArticlesDS.get()
    });
  };
  handleSearch = searchTerm => {
    if (_.isEmpty(searchTerm)) {
      this.setState({
        articles: ArticlesDS.get()
      });
    } else {
      const articles = ArticlesDS.get();
      const filtered = _.filter(articles, article => {
        return (
          article.title.includes(searchTerm) || article.id.includes(searchTerm)
        );
      });
      this.setState({ articles: filtered });
    }
  };
  handleCheck = id => {
    this.props.onCheck({ id, format: "article" });
  };
  isSelected = id => (this.props.isSelected[id] ? true : false);
  render() {
    const rows = this.state.articles || [];
    return (
      <Fragment>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center"
          }}
        >
          <SearchIcon />
          <TextField
            placeholder="search"
            margin="dense"
            onChange={event => {
              this.handleSearch(event.target.value);
            }}
          />
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Select</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>modified</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 && (
              <TableRow key={0}>
                <TableCell padding="checkbox">讀取中</TableCell>
                <TableCell component="th" scope="row" />
                <TableCell />
                <TableCell />
              </TableRow>
            )}
            {rows.map(row => {
              const isAdded = this.props.isAdded(row.id);
              const checked = this.isSelected(row.id) || isAdded;
              const disabled = isAdded || isOffShelf(row.post_status);
              return (
                <TableRow key={row.id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      disabled={disabled}
                      checked={checked}
                      onChange={() => this.handleCheck(row.id)}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.title}
                  </TableCell>
                  <TableCell>{renderStatus(row.post_status)}</TableCell>
                  <TableCell>{renderDate(row.modify_date)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Fragment>
    );
  }
}

ArticlesTables.propTypes = {
  isAdded: PropTypes.func,
  isSelected: PropTypes.object,
  onCheck: PropTypes.func
};

class VideosTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videos: VideosDS.get()
    };
  }
  componentDidMount() {
    VideosDS.subscription.add(this.handleChange);
  }
  componentWillUnmount() {
    VideosDS.subscription.remove(this.handleChange);
  }
  handleChange = () => {
    this.setState({
      videos: VideosDS.get()
    });
  };
  handleSearch = searchTerm => {
    if (_.isEmpty(searchTerm)) {
      this.setState({
        videos: VideosDS.get()
      });
    } else {
      const videos = VideosDS.get();
      const filtered = _.filter(videos, video => {
        return (
          video.title.includes(searchTerm) || video.id.includes(searchTerm)
        );
      });
      this.setState({ videos: filtered });
    }
  };
  handleCheck = id => {
    this.props.onCheck({ id, format: "video" });
  };
  isSelected = id => (this.props.isSelected[id] ? true : false);
  render() {
    const rows = this.state.videos || [];
    return (
      <Fragment>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center"
          }}
        >
          <SearchIcon />
          <TextField
            placeholder="search"
            margin="dense"
            onChange={event => {
              this.handleSearch(event.target.value);
            }}
          />
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Select</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>modified</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 && (
              <TableRow key={0}>
                <TableCell padding="checkbox">讀取中</TableCell>
                <TableCell component="th" scope="row" />
                <TableCell />
                <TableCell />
              </TableRow>
            )}
            {rows.map(row => {
              const isAdded = this.props.isAdded(row.id);
              const checked = this.isSelected(row.id) || isAdded;
              const disabled = isAdded || isOffShelf(row.post_status);
              return (
                <TableRow key={row.id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      disabled={disabled}
                      checked={checked}
                      onChange={() => this.handleCheck(row.id)}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.title}
                  </TableCell>
                  <TableCell>{renderStatus(row.post_status)}</TableCell>
                  <TableCell>{renderDate(row.modify_date)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Fragment>
    );
  }
}

VideosTables.propTypes = {
  isAdded: PropTypes.func,
  isSelected: PropTypes.object,
  onCheck: PropTypes.func
};

class CitiesTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cities: CitiesDS.get()
    };
  }
  componentDidMount() {
    CitiesDS.subscription.add(this.handleChange);
  }
  componentWillUnmount() {
    CitiesDS.subscription.remove(this.handleChange);
  }
  handleChange = () => {
    this.setState({
      cities: CitiesDS.get()
    });
  };
  handleSearch = searchTerm => {
    if (_.isEmpty(searchTerm)) {
      this.setState({
        cities: CitiesDS.get()
      });
    } else {
      const cities = CitiesDS.get();
      const filtered = _.filter(cities, city => {
        return (
          city.name.includes(searchTerm) ||
          city.id.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
      this.setState({ cities: filtered });
    }
  };
  handleCheck = id => {
    this.props.onCheck({ id, format: "city" });
  };
  isSelected = id => (this.props.isSelected[id] ? true : false);
  render() {
    const rows = this.state.cities || [];
    return (
      <Fragment>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center"
          }}
        >
          <SearchIcon />
          <TextField
            placeholder="search"
            margin="dense"
            onChange={event => {
              this.handleSearch(event.target.value);
            }}
          />
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Select</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 && (
              <TableRow key={0}>
                <TableCell padding="checkbox">讀取中</TableCell>
                <TableCell component="th" scope="row" />
                <TableCell />
              </TableRow>
            )}
            {rows.map(row => {
              const isAdded = this.props.isAdded(row.id);
              const checked = this.isSelected(row.id) || isAdded;
              return (
                <TableRow key={row.id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={checked}
                      onChange={() => this.handleCheck(row.id)}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell>{row.id}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Fragment>
    );
  }
}

CitiesTables.propTypes = {
  isAdded: PropTypes.func,
  isSelected: PropTypes.object,
  onCheck: PropTypes.func
};

class AddTemplateBlockItemButton extends Component {
  state = {
    error: false,
    showDialog: false,
    isSelected: {},
    currentTab: 0
  };
  handleClick = () => {
    this.setState({ showDialog: true });
  };
  handleCloseClick = () => {
    this.closeDialog();
  };
  handleAddClick = () => {
    const selectedItems = Object.values(this.state.isSelected).reduce(
      (accu, value) => {
        console.log("handleAddClick loop value: ", value);
        if (value) {
          accu.push(value);
        }
        return accu;
      },
      []
    );
    this.props.onAddSelected(selectedItems);
    this.closeDialog();
  };
  handleCheck = ({ id, format }) => {
    const copied = Object.assign({}, this.state.isSelected);
    copied[id] = this.isSelected(id) ? false : { id, format };
    this.setState({ isSelected: copied });
  };
  handleTabChange = (event, value) => {
    this.setState({ currentTab: value });
  };
  handleRefreshClick = () => {
    ArticlesDS.refresh();
    VideosDS.refresh();
    CitiesDS.refresh();
  };
  closeDialog = () => {
    this.setState({ showDialog: false, isSelected: {} });
  };
  isSelected = id => (this.state.isSelected[id] ? true : false);
  isAdded = id => (this.props.itemsMap[id] ? true : false);
  render() {
    const { showDialog } = this.state;
    return (
      <Fragment>
        <Button onClick={this.handleClick}>
          <IconContentAdd />
        </Button>
        <Dialog
          fullWidth
          open={showDialog}
          onClose={this.handleCloseClick}
          aria-label="選擇"
        >
          {showDialog && (
            <Fragment>
              <DialogTitle>選擇</DialogTitle>
              <DialogContent style={{ height: 600 }}>
                <Tabs
                  value={this.state.currentTab}
                  onChange={this.handleTabChange}
                >
                  <Tab label="Articles" />
                  <Tab label="Videos" />
                  <Tab label="Cities" />
                </Tabs>
                {this.state.currentTab === 0 && (
                  <ArticlesTables
                    isAdded={this.isAdded}
                    isSelected={this.state.isSelected}
                    onCheck={this.handleCheck}
                  />
                )}
                {this.state.currentTab === 1 && (
                  <VideosTables
                    isAdded={this.isAdded}
                    isSelected={this.state.isSelected}
                    onCheck={this.handleCheck}
                  />
                )}
                {this.state.currentTab === 2 && (
                  <CitiesTables
                    isAdded={this.isAdded}
                    isSelected={this.state.isSelected}
                    onCheck={this.handleCheck}
                  />
                )}
              </DialogContent>
              <DialogActions>
                <IconButton
                  color="primary"
                  aria-label="refresh"
                  onClick={this.handleRefreshClick}
                >
                  <RefreshIcon />
                </IconButton>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: 10 }}
                  onClick={this.handleAddClick}
                >
                  Add Selected
                </Button>
                <Button
                  label="ra.action.cancel"
                  onClick={this.handleCloseClick}
                >
                  <IconCancel />
                </Button>
              </DialogActions>
            </Fragment>
          )}
        </Dialog>
      </Fragment>
    );
  }
}

AddTemplateBlockItemButton.propTypes = {
  itemsMap: PropTypes.object,
  onAddSelected: PropTypes.func
};

const mapStateToProps = state => ({
  isSubmitting: isSubmitting("post-quick-create")(state)
});

const mapDispatchToProps = {
  change,
  fetchEnd,
  fetchStart,
  showNotification,
  submit
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddTemplateBlockItemButton);
