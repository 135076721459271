import React from "react";
import _ from "lodash";
import { FunctionField, ReferenceField, TextField } from "react-admin";

const CategoryField = props => (
  <FunctionField
    {...props}
    render={record => {
      const category = _.chain(record)
        .get("category_data")
        .keys()
        .map(id => ({ id }))
        .first()
        .value();
      return (
        category && (
          <ReferenceField
            record={category}
            source="id"
            linkType={false}
            reference="categories"
            basePath="/categories"
          >
            <TextField source="name" />
          </ReferenceField>
        )
      );
    }}
  />
);

CategoryField.defaultProps = {
  addLabel: true
};

export default CategoryField;
