import React from "react";
import { FunctionField, Datagrid, TextField, DateField } from "react-admin";

const NotificationEventList = ({ record }) => {
  const { notifications = [] } = record;
  const ids = notifications.map(n => n.id);
  const data = notifications.reduce((accu, curr) => {
    accu[curr.id] = curr;
    return accu;
  }, {});
  const defaultSort = {
    field: "time.created",
    order: "DESC"
  };
  return (
    <Datagrid
      ids={ids}
      data={data}
      currentSort={defaultSort}
      isLoading={false}
      total={notifications.length}
    >
      <TextField source="type" label="type" sortable={false} />
      <TextField
        source="pushNotification.message"
        label="message"
        sortable={false}
      />
      {/* <FunctionField
        label="問題"
        render={record => {question.}}
      />
      <FunctionField
        label="回答"
        render={record => <NotificationEventList record={record} />}
      />
      <FunctionField
        label="按讚者"
        render={record => <NotificationEventList record={record} />}
      /> */}
      <DateField
        showTime
        source="time.created"
        label="建立時間"
        sortable={false}
      />
    </Datagrid>
  );
};

const NotificationEventListField = props => (
  <FunctionField
    {...props}
    render={record => <NotificationEventList record={record} />}
  />
);

NotificationEventListField.defaultProps = {
  label: "通知紀錄",
  sortable: false
};

export default NotificationEventListField;
