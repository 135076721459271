import firebase from "firebase/app";
import "firebase/auth";

const devConfig = {
  apiKey: "AIzaSyAsPJ0mpDaT685d86mO_hLCsYC5idpZrc0",
  authDomain: "test-d3aa1.firebaseapp.com",
  databaseURL: "https://test-d3aa1.firebaseio.com",
  projectId: "test-d3aa1",
  storageBucket: "test-d3aa1.appspot.com",
  messagingSenderId: "7347947732"
};

const prodConfig = {
  apiKey: "AIzaSyAvWUfHds2h3bRGaxUYj72CTHi8x88L9Vs",
  authDomain: "kkday-guide-qa-production.firebaseapp.com",
  databaseURL: "https://kkday-guide-qa-production.firebaseio.com",
  projectId: "kkday-guide-qa-production",
  storageBucket: "kkday-guide-qa-production.appspot.com",
  messagingSenderId: "694417470728"
};

if (!firebase.apps.length) {
  if (process.env.REACT_APP_ENV === "prod") {
    firebase.initializeApp(prodConfig);
  } else {
    firebase.initializeApp(devConfig);
  }
}

const auth = firebase.auth();

const CurrentUserPromise = new Promise((resolve, reject) => {
  if (auth.currentUser) {
    return resolve(auth.currentUser);
  }
  auth.onAuthStateChanged(user => {
    console.log(
      `auth.onAuthStateChanged: user is ${user ? "signed in" : "signed out"}`
    );
    return resolve(auth.currentUser);
  }, reject);
});

const GetCurrentFireBaseUser = () => {
  return CurrentUserPromise;
};

const signIn = (email, password) => {
  return auth
    .signInWithEmailAndPassword(email, password)
    .then(userCredential => {
      console.log("signInWithEmailAndPassword successful!", userCredential);
      return userCredential;
    })
    .catch(error => {
      console.log("signInWithEmailAndPassword failure!", error);
      throw error;
    });
};

export { auth, GetCurrentFireBaseUser, signIn };
