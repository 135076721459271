import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  RichTextField,
  ReferenceManyField,
  BooleanField,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextInput,
  LongTextInput,
  BooleanInput,
  required,
  minLength,
  ImageField,
  maxLength
} from "react-admin";
import Pagination from "../../../components/qa/Pagination";
import {
  QuestionLinkField,
  AuthorLinkField
} from "../../../components/qa/LinkFields";
import { CoverImageInputField } from "../../../components/qa/ImagesInputField";

const style = {
  info: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "2em",
    minWidth: "8em"
  },
  booleanInfo: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "2em"
  }
};

const BoardTitle = ({ record }) => {
  return <span>Board {record ? record.name : ""}</span>;
};

export const BoardShow = withStyles(style)(({ classes, ...props }) => {
  const perPage = 25;
  const defaultSort = {
    field: "time.created",
    order: "DESC"
  };
  return (
    <Show title={<BoardTitle />} {...props}>
      <TabbedShowLayout>
        <Tab label="summary">
          <TextField source="id" label="ID" />
          <DateField
            showTime
            source="time.created"
            label="建立時間"
            className={classes.info}
          />
          <DateField
            showTime
            source="time.updated"
            label="編輯時間"
            className={classes.info}
          />
          <TextField source="name" label="名稱" />
          <TextField source="info.country" label="地區" />
          <ImageField source="info.coverImage.content" label="圖片" />
          <TextField source="type" label="類型" className={classes.info} />
          <TextField
            source="question.count"
            label="問題數"
            className={classes.info}
          />
          <TextField
            source="score.default"
            label="分數"
            className={classes.info}
          />
          <TextField source="info.description" label="簡介" />
          <RichTextField source="info.summary" label="內文" />
          <BooleanField
            source="status.open"
            label="開放"
            className={classes.booleanInfo}
          />
          <BooleanField
            source="status.deleted"
            label="已刪除"
            className={classes.booleanInfo}
          />
          <BooleanField
            source="status.locked"
            label="鎖住"
            className={classes.booleanInfo}
          />
        </Tab>
        <Tab label="問題" path="questions">
          <ReferenceManyField
            reference="questions"
            target="boardId"
            addLabel={false}
            perPage={perPage}
            sort={defaultSort}
            pagination={<Pagination />}
          >
            <Datagrid>
              <QuestionLinkField source="id" label="ID" sortable={false} />
              <TextField source="board.name" label="討論版" sortable={false} />
              <AuthorLinkField label="作者" size={30} sortable={false} />
              <TextField source="title" label="標題" sortable={false} />
              <TextField
                source="answer.count"
                label="回答數"
                sortable={false}
              />
              <DateField showTime source="time.created" label="建立時間" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
});

const validateBoardId = [required(), minLength(2), maxLength(20)];
const validateBoardName = [required(), minLength(2), maxLength(20)];
const validateBoardSummary = [minLength(2), maxLength(150)];
const validateBoardDescription = [minLength(2), maxLength(1500)];

export const BoardCreate = withStyles(style)(({ classes, ...props }) => {
  return (
    <Create {...props}>
      <SimpleForm redirect="show" submitOnEnter={false}>
        <TextInput label="ID" source="boardId" validate={validateBoardId} />
        <TextInput label="名稱" source="name" validate={validateBoardName} />
        <TextInput label="地區" source="info.country" />
        <CoverImageInputField source="info.coverImage" label="Cover Image" />
        <TextInput
          label="短介紹"
          source="info.summary"
          validate={validateBoardSummary}
        />
        <LongTextInput
          label="長介紹"
          source="info.description"
          validate={validateBoardDescription}
        />
        <BooleanInput
          source="status.open"
          label="開放"
          defaultValue={true}
          formClassName={classes.booleanInfo}
        />
        <BooleanInput
          source="status.locked"
          label="鎖住"
          formClassName={classes.booleanInfo}
        />
      </SimpleForm>
    </Create>
  );
});

export const BoardEdit = withStyles(style)(({ classes, ...props }) => {
  return (
    <Edit {...props}>
      <SimpleForm redirect="show" submitOnEnter={false}>
        <TextField source="id" label="ID" className={classes.info} />
        <TextInput source="name" label="名稱" />
        <TextInput source="info.country" label="地區" />
        <CoverImageInputField source="info.coverImage" label="Cover Image" />
        <TextInput
          label="短介紹"
          source="info.summary"
          validate={validateBoardSummary}
        />
        <LongTextInput
          label="長介紹"
          source="info.description"
          validate={validateBoardDescription}
        />
        <BooleanInput
          source="status.open"
          label="開放"
          formClassName={classes.booleanInfo}
        />
        <BooleanInput
          source="status.locked"
          label="鎖住"
          formClassName={classes.booleanInfo}
        />
        <BooleanInput
          source="status.deleted"
          label="刪除"
          formClassName={classes.booleanInfo}
        />
      </SimpleForm>
    </Edit>
  );
});
