import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { processSchedules as processSchedulesAction } from "./actions";

class ProcessNowButton extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = () => {
    const { processSchedules } = this.props;
    processSchedules();
  };

  render() {
    return (
      <Button variant="contained" color="primary" onClick={this.handleClick}>
        PROCESS
      </Button>
    );
  }
}

ProcessNowButton.propTypes = {
  processSchedules: PropTypes.func
};

export default connect(
  null,
  {
    processSchedules: processSchedulesAction
  }
)(ProcessNowButton);
