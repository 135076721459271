import _ from "lodash";

export const delay = delayByInMs => {
  const fn = input => {
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve(input), delayByInMs);
    });
  };
  return fn;
};

export const QA_API_ERROR = (name, result, params) => {
  const message = _.get(result, "error.message") || "QA API ERROR";
  const error = new Error(message);
  error.name = name || null;
  error.params = params || null;
  return error;
};

export const objectKey2Array = (inObject, inName) => {
  const tmpList = [];
  _.forEach(inObject, function(value, key) {
    const tmpObj = {};
    tmpObj[inName] = key;
    tmpList.push(tmpObj);
  });
  return tmpList;
};

export const arrayInput2Object = (inArray, inFieldName) => {
  let tmpCat = [];

  _.forEach(inArray, function(value) {
    const tmpValue = _.get(value, inFieldName);
    if (
      tmpValue !== "" &&
      tmpValue !== undefined &&
      _.findIndex(tmpCat, tmpValue) < 0
    ) {
      tmpCat.push(tmpValue);
    }
  });

  return _.reduce(
    tmpCat,
    function(result, value, key) {
      if (!_.isUndefined(value)) {
        result[value] || (result[value] = true);
        return result;
      }
    },
    {}
  );
};

export const getSourceData = inObject => {
  let { source = "", source_id = "" } = inObject;
  const temp_source = source_id.replace(/ /g, "").split("_");
  const count_source = temp_source.length;
  const returnValue = { source: "", source_id: "" };

  source = source.replace(/ /g, "");

  if (
    (count_source === 1 && temp_source[0] !== "") ||
    (count_source === 2 && temp_source[1] !== "")
  ) {
    switch (temp_source.length) {
      case 1:
        returnValue.source = source === "" ? "kkblog" : source;
        returnValue.source_id = `${returnValue.source}_${temp_source[0]}`;
        break;

      case 2:
        returnValue.source = source === "" ? temp_source[0] : source;
        returnValue.source_id = `${returnValue.source}_${temp_source[1]}`;
        break;

      default:
        break;
    }
  }

  return returnValue;
};

export const isValidUrl = string => {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
};
