import React from "react";
import Icon from "@material-ui/icons/HowToVote";
import { Resource } from "react-admin";
import { CouponList } from "./coupons";
import { CouponShow, CouponEdit, CouponCreate } from "./coupon";

const CouponResource = props => {
  return (
    <Resource
      {...props}
      list={CouponList}
      show={CouponShow}
      edit={CouponEdit}
      create={CouponCreate}
      icon={Icon}
    />
  );
};

export default CouponResource;
