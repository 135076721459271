import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import UpIcon from "@material-ui/icons/ArrowUpward";
import DownIcon from "@material-ui/icons/ArrowDownward";
import { Field } from "redux-form";
import { TextField, ReferenceField } from "react-admin";
import AddTemplateBlockItemButton from "./AddTemplateBlockItemButton";

const styles = {
  paper: {
    backgroundColor: "rgb(250, 250, 250)",
    padding: 10,
    marginTop: 10,
    display: "flex"
  },
  paperAdd: {
    backgroundColor: "rgb(250, 250, 250)",
    padding: 10,
    marginTop: 10,
    display: "flex",
    justifyContent: "center"
  },
  itemOrder: {
    display: "inline-block",
    width: 30,
    alignSelf: "center"
  },
  itemFormat: {
    display: "inline-block",
    width: 70,
    alignSelf: "center"
  },
  itemName: {
    display: "inline-flex",
    alignItems: "center",
    height: "100%",
    width: "500px"
  }
};

const TemplateBlockItem = ({
  item,
  record,
  options,
  moveUp,
  moveDown,
  remove,
  ...props
}) => {
  let reference = null;
  let source = null;
  let basePath = null;
  if (item.format === "article") {
    reference = "articles";
    source = "title";
    basePath = "/articles";
  } else if (item.format === "video") {
    reference = "videos";
    source = "title";
    basePath = "/videos";
  } else if (item.format === "city") {
    reference = "cities";
    source = "name";
    basePath = "/cities";
  }
  if (!reference) {
    return <div>{`not supported format:${item.format}`}</div>;
  }
  let optionUI = null;
  if (options) {
    optionUI = (
      <div>
        {options.move && (
          <React.Fragment>
            <IconButton aria-label="Move up" onClick={() => moveUp(item)}>
              <UpIcon />
            </IconButton>
            <IconButton aria-label="Move down" onClick={() => moveDown(item)}>
              <DownIcon />
            </IconButton>
          </React.Fragment>
        )}
        {options.remove && (
          <IconButton aria-label="Remove" onClick={() => remove(item)}>
            <DeleteIcon />
          </IconButton>
        )}
      </div>
    );
  }
  return (
    <Paper style={styles.paper}>
      <span style={styles.itemOrder}>{item.sort}.</span>
      <span style={styles.itemFormat}>{item.format}</span>
      <ReferenceField
        record={item}
        source="id"
        linkType="show"
        reference={reference}
        basePath={basePath}
        style={styles.itemName}
      >
        <TextField source={source} style={styles.itemName} />
      </ReferenceField>
      {optionUI}
    </Paper>
  );
};

const TemplateBlockAddItem = ({ itemsMap, onAddSelected, ...props }) => {
  return (
    <Paper style={styles.paperAdd}>
      <AddTemplateBlockItemButton
        itemsMap={itemsMap}
        onAddSelected={onAddSelected}
      />
    </Paper>
  );
};

TemplateBlockAddItem.propTypes = {
  itemsMap: PropTypes.object,
  onAddSelected: PropTypes.func
};

const TemplateBlockItemsField = props => {
  const itemsList = _.orderBy(
    Object.values(props.record.items),
    ["sort"],
    ["asc"]
  );
  const templateBlockItems = itemsList.map((item, index) => (
    <TemplateBlockItem item={item} key={index} {...props} />
  ));
  return <div>{templateBlockItems}</div>;
};

TemplateBlockItemsField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object
};

TemplateBlockItemsField.defaultProps = {
  addLabel: true
};

class TemplateBlockItemsInput extends React.Component {
  constructor(props) {
    super(props);
    this.add = this.add.bind(this);
    this.remove = this.remove.bind(this);
    this.moveUp = this.moveUp.bind(this);
    this.moveDown = this.moveDown.bind(this);
  }
  moveUp(item) {
    console.log("move up item: ", item);
    const {
      input: { value, onChange }
    } = this.props;
    const newItemsList = value.slice();
    const curIndex = _.findIndex(newItemsList, i => i.id === item.id);
    const newIndex = _.max([curIndex - 1, 0]);
    console.log(`move up index ${curIndex} -> ${newIndex}`);
    newItemsList.splice(newIndex, 0, newItemsList.splice(curIndex, 1)[0]);
    console.log("move up newItemsList: ", newItemsList);
    onChange(newItemsList);
  }
  moveDown(item) {
    console.log("move down item: ", item);
    const {
      input: { value, onChange }
    } = this.props;
    const newItemsList = value.slice();
    const curIndex = _.findIndex(newItemsList, i => i.id === item.id);
    const newIndex = _.min([curIndex + 1, newItemsList.length - 1]);
    console.log(`move down index ${curIndex} -> ${newIndex}`);
    newItemsList.splice(newIndex, 0, newItemsList.splice(curIndex, 1)[0]);
    console.log("move down newItemsList: ", newItemsList);
    onChange(newItemsList);
  }
  remove(item) {
    console.log("remove item: ", item);
    const {
      input: { value, onChange }
    } = this.props;
    const newItemsList = [];
    value.forEach(i => {
      if (i.id !== item.id) {
        newItemsList.push(i);
        i.sort = newItemsList.length;
      }
    });
    console.log("move down newItemsList: ", newItemsList);
    onChange(newItemsList);
  }
  add({ id, format }) {
    console.log(`add new item id:${id} format:${format}`);
    const {
      input: { value, onChange }
    } = this.props;
    const newItemsList = value.slice();
    const newItem = {
      id,
      format,
      sort: newItemsList.length
    };
    const notInList =
      _.findIndex(newItemsList, i => i.id === newItem.id) === -1;
    if (notInList) {
      newItemsList.push(newItem);
    }
    onChange(newItemsList);
  }
  addBulk = items => {
    const {
      input: { value, onChange }
    } = this.props;
    const newItemsList = value.slice();
    items.forEach(({ id, format }) => {
      console.log(`add new item id:${id} format:${format}`);
      const newItem = {
        id,
        format,
        sort: newItemsList.length
      };
      const notInList =
        _.findIndex(newItemsList, i => i.id === newItem.id) === -1;
      if (notInList) {
        newItemsList.push(newItem);
      }
    });
    onChange(newItemsList);
  };
  handleAddSelected = selectedItems => {
    this.addBulk(selectedItems);
  };
  render() {
    console.log("TemplateBlockItemsInput props: ", this.props);
    const options = { move: true, remove: true };
    const itemsList = this.props.input.value;
    const itemsMap = itemsList.reduce((accu, item) => {
      accu[item.id] = item;
      return accu;
    }, {});
    const templateBlockItems = itemsList.map((item, index) => (
      <TemplateBlockItem
        item={item}
        key={index}
        options={options}
        moveUp={this.moveUp}
        moveDown={this.moveDown}
        remove={this.remove}
        {...this.props}
      />
    ));
    templateBlockItems.push(
      <TemplateBlockAddItem
        itemsMap={itemsMap}
        onAddSelected={this.handleAddSelected}
        key="add"
      />
    );
    return <div>{templateBlockItems}</div>;
  }
}

TemplateBlockItemsInput.defaultProps = {
  onChange: () => {}
};

const TemplateBlockItemsInputField = props => (
  <Field
    name={props.source}
    component={TemplateBlockItemsInput}
    label={props.label}
    format={(items = []) => {
      const itemsList = _.orderBy(Object.values(items), ["sort"], ["asc"]);
      return itemsList;
    }}
    parse={itemsList => {
      const items = itemsList.reduce((accu, cur, idx) => {
        cur.sort = idx + 1;
        accu[cur.id] = cur;
        return accu;
      }, {});
      return items;
    }}
  />
);

TemplateBlockItemsInputField.defaultProps = {
  addLabel: true
};

export { TemplateBlockItemsField, TemplateBlockItemsInputField };
