import React from "react";
import Icon from "@material-ui/icons/PermMedia";
import { Resource } from "react-admin";
import { ArticleList } from "./articles";
import { ArticleShow, ArticleEdit, ArticleCreate } from "./article";

const ArticlesResource = props => {
  return (
    <Resource
      {...props}
      list={ArticleList}
      show={ArticleShow}
      edit={ArticleEdit}
      create={ArticleCreate}
      icon={Icon}
    />
  );
};

export default ArticlesResource;
