import _ from "lodash";
import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
// import { withStyles } from "@material-ui/core/styles";
import { Field } from "redux-form";
import { FunctionField } from "react-admin";
import Select from "react-select";
import { countryCityUtility } from "../../config/guide/consts";

const {
  countryCitiesMap,
  countryCitiesList,
  countryCitiesIdName
} = countryCityUtility;

// const styles = theme => ({
//   root: {
//     ...theme.mixins.gutters(),
//     paddingTop: theme.spacing.unit * 2,
//     paddingBottom: theme.spacing.unit * 2
//   }
// });

class CountryCitySelectComponet extends Component {
  handleChange = selectedOption => {
    const inputVale = [...this.props.input.value]; // make a copy
    const selectValue = selectedOption.value;

    if (selectValue && inputVale.indexOf(selectValue) < 0) {
      inputVale.push(selectValue);
      this.props.input.onChange(inputVale);
    } else {
      console.log("City duplicated !!");
    }
  };

  handleDelete = idx => {
    const inputVale = [...this.props.input.value]; // make a copy
    inputVale.splice(idx, 1);
    this.props.input.onChange(inputVale);
  };

  render() {
    const selectedOptions = [...this.props.input.value];
    return (
      <div style={{ minWidth: "300px" }}>
        <Paper>
          <div>
            {selectedOptions.map((tagValue, idx) => (
              <Chip
                key={idx}
                style={{ margin: "4px", width: "200" }}
                label={countryCitiesList[tagValue]}
                onDelete={() => {
                  this.handleDelete(idx);
                }}
              />
            ))}
          </div>
        </Paper>

        <Select
          onChange={this.handleChange}
          options={countryCitiesMap}
          value=""
          label="City"
        />
      </div>
    );
  }
}

const CountryCitySelectInput = props => (
  <Field
    name={props.source}
    component={CountryCitySelectComponet}
    label={props.label}
    format={area_data => {
      const values = _.map(area_data, item => {
        return item.city;
      });
      return values;
    }}
    parse={selectedOptions => {
      return _.map(selectedOptions, city => {
        return { city: city };
      });
    }}
  />
);
CountryCitySelectInput.defaultProps = {
  addLabel: true
};

// show field
const CountryCitySelectField = props => (
  <FunctionField
    {...props}
    render={() => {
      const selectedItems = Array.isArray(props.record[props.source])
        ? props.record[props.source]
        : [];
      return (
        <div style={{ minWidth: "300px" }}>
          {selectedItems.map((item, idx) => (
            <Chip
              key={idx}
              style={{ margin: "4px", padding: "4px" }}
              label={_.find(countryCitiesMap, { value: item.city }).label}
            />
          ))}
        </div>
      );
    }}
  />
);
CountryCitySelectField.defaultProps = { addLabel: true };

export { CountryCitySelectInput, CountryCitySelectField, countryCitiesIdName };
