import React from "react";
import { Resource } from "react-admin";
import { BoardList } from "./boards";
import { BoardShow, BoardEdit, BoardCreate } from "./board";

const BoardResource = props => {
  return (
    <Resource
      {...props}
      list={BoardList}
      show={BoardShow}
      edit={BoardEdit}
      create={BoardCreate}
    />
  );
};

export default BoardResource;
