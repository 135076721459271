import React from "react";
import { Link } from "react-router-dom";
import Icon from "@material-ui/icons/FontDownload";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "react-admin";
import { stringify } from "query-string";

const styles = {
  button: {
    marginTop: "1em"
  }
};

const AddAnswerButton = ({ classes, record }) => (
  <Button
    className={classes.button}
    variant="raised"
    component={Link}
    to={{
      pathname: "/answers/create",
      search: stringify({
        questionId: record.id
      })
    }}
    label="Add Answer"
    title="Add Answer"
  >
    <Icon />
  </Button>
);

export default withStyles(styles)(AddAnswerButton);
