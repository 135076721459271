import React from "react";
import { Resource } from "react-admin";
import { ScheduleList } from "./schedules";
import { ScheduleShow, ScheduleCreate, ScheduleEdit } from "./schedule";
import ScheduleIcon from "@material-ui/icons/Schedule";

const ScheduleResource = props => {
  return (
    <Resource
      {...props}
      list={ScheduleList}
      show={ScheduleShow}
      edit={ScheduleEdit}
      create={ScheduleCreate}
      icon={ScheduleIcon}
    />
  );
};

export default ScheduleResource;
