import React from "react";
import { FunctionField, SelectField, SelectInput } from "react-admin";

const arrayList = [
  { id: true, name: "Active" },
  { id: false, name: "Inactive" }
];

const ActiveStatusField = props => (
  <FunctionField
    {...props}
    render={() => <SelectField choices={arrayList} {...props} />}
  />
);
ActiveStatusField.defaultProps = {
  addLabel: true
};

const ActiveStatusInput = props => (
  <FunctionField
    {...props}
    render={() => <SelectInput choices={arrayList} {...props} />}
  />
);
ActiveStatusInput.defaultProps = {};

export { ActiveStatusField, ActiveStatusInput };
