import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  ImageField,
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  SelectInput,
  SelectField,
  BooleanField,
  BooleanInput
} from "react-admin";

// import { DateTimeInput } from "react-admin-date-inputs";
import { UserPhotoUrlInputField } from "../../../components/qa/ImagesInputField";

const style = {
  info: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "2em",
    minWidth: "8em"
  },
  booleanInfo: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "2em"
  },
  width100pa: {
    width: "100%"
  }
};

export const CityShow = withStyles(style)(({ classes, ...props }) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="id" label="ID" />
        <TextField source="name" label="Name" />
        <TextField source="brief_guide" label="brief_guide" />
        <TextField source="language" label="Language" />
        <TextField source="religion" label="Religion" />
        <TextField source="voltage" label="Voltage" />
        <TextField source="currency" label="Currency" />
        <TextField source="time_zone" label="time_zone" />
        <TextField source="sort" label="Sort" />
        <SelectField
          source="lang_setting"
          label="Language family"
          choices={[{ id: "zh-tw", name: "繁體中文" }]}
        />
        <DateField
          source="create_date"
          label="Create date"
          className={classes.info}
          showTime
        />
        <DateField
          source="modify_date"
          label="Last Modify"
          className={classes.info}
          showTime
        />
      </Tab>

      <Tab label="images" path="images">
        <TextField source="id" label="Id" />
        <TextField source="name" label="Name" />
        <TextField source="image_data.ios_image" label="iOS image" />
        <ImageField source="image_data.ios_image" label="iOS mage" />
        <TextField source="image_data.android_image" label="Android image" />
        <ImageField source="image_data.android_image" label="Android image" />
        <TextField source="image_data.mobile_image" label="Mobile image" />
        <ImageField source="image_data.mobile_image" label="Mobile image" />
        <TextField source="image_data.web_image" label="Web image" />
        <ImageField source="image_data.web_image" label="Web image" />
      </Tab>

      <Tab label="seo meta" path="seo">
        <TextField
          source="seo_data.open_graph_title"
          label="Open Graph Title"
        />
        <TextField source="seo_data.meta_desc" label="Meta Desc" />
        <TextField source="seo_data.html_title" label="Html Title" />
        <TextField source="seo_data.open_graph_desc" label="Open Graph Desc" />
        <TextField
          source="seo_data.open_graph_image"
          label="Open Graph Image"
        />
      </Tab>
      <Tab label="openweather meta" path="openweather">
        <BooleanField source="openweatherMeta.isSync" label="Open Weather Sync" />
        <TextField source="openweatherMeta.name" label="English Name" />
        <TextField source="openweatherMeta.id" label="Open Weather Id" />
        <TextField source="openweatherMeta.coordinate.lat" label="Latitude" />
        <TextField source="openweatherMeta.coordinate.lon" label="Longitude" />

        <TextField source="openweatherMeta.country" label="Country Code" />
      </Tab>
    </TabbedShowLayout>
  </Show>
));

export const CityEdit = withStyles(style)(({ classes, ...props }) => (
  <Edit {...props}>
    <TabbedForm redirect="show" submitOnEnter={false}>
      <FormTab label="summary">
        <TextField source="id" label="ID" />
        <TextField source="name" label="Name" />
        <TextInput source="brief_guide" label="Brief guide" />
        <TextInput source="language" label="Language" />
        <TextInput source="religion" label="Religion" />
        <TextInput source="voltage" label="Voltage" />
        <TextInput source="currency" label="Currency" />
        <TextInput source="time_zone" label="Time zone" />
        <TextInput source="sort" label="sort" />
        <SelectInput
          source="lang_setting"
          options={{ label: "Language family" }}
          choices={[{ id: "", name: "" }, { id: "zh-tw", name: "繁體中文" }]}
        />
      </FormTab>
      <FormTab label="images" path="images">
        <TextField source="id" label="Id" />
        <TextField source="name" label="Name" />
        <UserPhotoUrlInputField
          source="image_data.ios_image"
          label="iOS image"
        />
        <UserPhotoUrlInputField
          source="image_data.android_image"
          label="Android image"
        />
        <UserPhotoUrlInputField
          source="image_data.mobile_image"
          label="Mobile image"
        />
        <UserPhotoUrlInputField
          source="image_data.web_image"
          label="Web image"
        />
      </FormTab>
      <FormTab label="seo meta">
        <TextInput
          source="seo_data.open_graph_title"
          label="Open Graph Title"
        />
        <TextInput source="seo_data.meta_desc" label="Meta Desc" />
        <TextInput source="seo_data.html_title" label="Html Title" />
        <TextInput source="seo_data.open_graph_desc" label="Open Graph Desc" />
        <TextInput
          source="seo_data.open_graph_image"
          label="Open Graph Image"
        />
      </FormTab>
      <FormTab label="openweather meta">
        <BooleanInput source="openweatherMeta.isSync" label="Open Weather Sync" />
        <TextInput source="openweatherMeta.name" label="English Name" />
        <TextInput source="openweatherMeta.id" label="Open Weather Id" />
        <TextInput source="openweatherMeta.coordinate.lat" label="Latitude" />
        <TextInput source="openweatherMeta.coordinate.lon" label="Longitude" />
        <TextInput source="openweatherMeta.country" label="Country Code" />
      </FormTab>
    </TabbedForm>
  </Edit>
));
