import React from "react";
import _ from "lodash";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Show,
  TabbedShowLayout,
  Tab,
  BooleanField,
  TextField,
  DateField,
  ReferenceManyField
} from "react-admin";
import { UserReferenceField } from "../../../components/qa/ReferenceFields";
import NotificationEventListField from "../../../components/qa/NotificationEventListField";
import { SimpleList } from "../../../components/customize-react-admin";

const style = {
  info: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "2em",
    minWidth: "8em"
  },
  booleanInfo: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "2em"
  }
};

export const NotificationShow = withStyles(style)(({ classes, ...props }) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="summary">
          <TextField source="id" label="ID" className={classes.info} />
          <DateField
            showTime
            source="time.created"
            label="建立時間"
            className={classes.info}
          />
          <DateField
            showTime
            source="time.updated"
            label="編輯時間"
            className={classes.info}
          />
          <TextField source="title" label="標題" />
          <UserReferenceField
            label="使用者"
            size={30}
            className={classes.info}
          />
          <TextField
            source="notifications.length"
            label="通知數量"
            className={classes.info}
          />
          <BooleanField
            source="config.NEW_ANSWER.enabled"
            label="新回答通知"
            className={classes.booleanInfo}
          />
          <BooleanField
            source="config.BEST_ANSWER.enabled"
            label="最佳答案通知"
            className={classes.booleanInfo}
          />
          <BooleanField
            source="config.NEW_LIKE_FOR_QUESTION.enabled"
            label="問題按讚通知"
            className={classes.booleanInfo}
          />
          <BooleanField
            source="config.NEW_LIKE_FOR_ANSWER.enabled"
            label="回答按讚通知"
            className={classes.booleanInfo}
          />
        </Tab>
        <Tab label="通知細項" path="answers">
          <NotificationEventListField />
        </Tab>
        <Tab label="events" path="event">
          <ReferenceManyField
            reference="events"
            target="actorId"
            addLabel={false}
            perPage={100}
          >
            <SimpleList
              primaryText={record => record.name || "n/a"}
              secondaryText={record =>
                JSON.stringify(_.last(record.trace), null, 2)
              }
              tertiaryText={record =>
                new Date(record.time.created).toLocaleString()
              }
            />
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
});
