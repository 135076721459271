import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Show, SimpleShowLayout, TextField, DateField } from "react-admin";
import LikeTargetField from "../../../components/qa/LikeTargetField";
import { UserLinkField } from "../../../components/qa/LinkFields";

const style = {
  info: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "2em",
    minWidth: "8em"
  },
  booleanInfo: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "2em"
  }
};

export const LikeShow = withStyles(style)(({ classes, ...props }) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" label="ID" />
      <DateField
        showTime
        source="time.created"
        label="建立時間"
        className={classes.info}
      />
      <UserLinkField label="按讚者" size={30} />
      <TextField
        source="target.type"
        label="目標類型"
        className={classes.info}
      />
      <TextField source="type" label="讚類型" className={classes.info} />
      <LikeTargetField label="按讚目標" />
    </SimpleShowLayout>
  </Show>
));
