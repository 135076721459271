import React from "react";
import _ from "lodash";
import ReactJson from "react-json-view";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Show,
  Create,
  Edit,
  TabbedShowLayout,
  Tab,
  Datagrid,
  TextField,
  DateField,
  ImageField,
  RichTextField,
  FunctionField,
  ReferenceManyField,
  BooleanField,
  SimpleForm,
  TextInput,
  DisabledInput
} from "react-admin";
import ReportTargetField from "../../../components/qa/ReportTargetField";
import {
  QuestionLinkField,
  // UserLinkField,
  // LikeLinkField,
  ReportLinkField,
  AnswerLinkField,
  BoardLinkField
} from "../../../components/qa/LinkFields";
import { QuestionReferenceField } from "../../../components/qa/ReferenceFields";
import { UserPhotoUrlInputField } from "../../../components/qa/ImagesInputField";
import { SimpleList } from "../../../components/customize-react-admin";

const style = {
  info: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "2em",
    minWidth: "8em"
  },
  booleanInfo: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "2em"
  }
};

const UserTitle = ({ record }) => {
  return <span>User {record ? record.displayName : ""}</span>;
};

export const UserShow = withStyles(style)(({ classes, ...props }) => (
  <Show title={<UserTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="id" label="ID" className={classes.info} />
        <DateField
          showTime
          source="time.created"
          label="建立時間"
          className={classes.info}
        />
        <DateField
          showTime
          source="time.updated"
          label="編輯時間"
          className={classes.info}
        />
        <TextField source="displayName" label="顯示名稱" />
        <TextField
          source="email.primary"
          label="Email"
          className={classes.info}
        />
        <TextField
          source="question.count"
          label="問題數"
          className={classes.info}
        />
        <TextField
          source="answer.count"
          label="回答數"
          className={classes.info}
        />
        <TextField
          source="bookmark.count"
          label="收藏問題數"
          className={classes.info}
        />
        <TextField
          source="score.default"
          label="分數"
          className={classes.info}
        />
        <ImageField
          source="photoUrl"
          label="大頭貼"
          title="大頭貼"
          style={{ width: 30 }}
        />
        <TextField source="bio.motto" label="短自介" />
        <RichTextField source="bio.description" label="長自介" />
        <BooleanField
          source="meta.internalUser"
          label="假帳號"
          className={classes.booleanInfo}
        />
        <BooleanField
          source="status.valid"
          label="帳號狀態正常"
          className={classes.booleanInfo}
        />
        <BooleanField
          source="status.deleted"
          label="帳號已刪除"
          className={classes.booleanInfo}
        />
        <BooleanField
          source="status.suspended"
          label="帳號已停權"
          className={classes.booleanInfo}
        />
      </Tab>
      <Tab label="raw" path="raw">
        <FunctionField
          label="JSON"
          style={{
            width: 900,
            overflowX: "scroll"
          }}
          render={record => (
            <ReactJson src={record} theme="rjv-default" indentWidth={2} />
          )}
        />
      </Tab>
      <Tab label="questions" path="questions">
        <ReferenceManyField
          reference="questions"
          target="authorId"
          addLabel={false}
          perPage={25}
          sort={{
            field: "time.created",
            order: "DESC"
          }}
        >
          <Datagrid>
            <QuestionLinkField source="id" label="ID" sortable={false} />
            <BoardLinkField
              source="board.name"
              label="討論版"
              sortable={false}
            />
            <TextField
              source="author.displayName"
              label="作者"
              sortable={false}
            />
            <TextField source="title" label="標題" sortable={false} />
            <TextField source="answer.count" label="回答數" />
            <TextField source="like.total" label="按讚數" />
            <TextField source="bookmark.count" label="收藏數" />
            <DateField showTime source="time.created" label="建立時間" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="answers" path="answers">
        <ReferenceManyField
          reference="answers"
          target="authorId"
          addLabel={false}
          perPage={25}
          sort={{
            field: "time.created",
            order: "DESC"
          }}
        >
          <Datagrid>
            <AnswerLinkField source="id" label="ID" sortable={false} />
            <QuestionReferenceField label="所屬問題" sortable={false} />
            <TextField
              source="author.displayName"
              label="作者"
              sortable={false}
            />
            <TextField source="content.text" label="內容" sortable={false} />
            <DateField showTime source="time.created" label="建立時間" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      {/* <Tab label="likes" path="likes">
        <ReferenceManyField
          reference="likes"
          target="userId"
          addLabel={false}
          perPage={25}
          sort={{
            field: "time.created",
            order: "DESC"
          }}
        >
          <Datagrid>
            <LikeLinkField source="id" label="ID" sortable={false} />
            <UserLinkField label="按讚者" sortable={false} />
            <TextField source="type" label="讚類型" sortable={false} />
            <DateField showTime source="time.created" label="建立時間" />
          </Datagrid>
        </ReferenceManyField>
      </Tab> */}
      <Tab label="reports" path="reports">
        <ReferenceManyField
          reference="reports"
          target="reporterId"
          addLabel={false}
          perPage={25}
          sort={{
            field: "time.created",
            order: "DESC"
          }}
        >
          <Datagrid>
            <ReportLinkField source="id" label="ID" sortable={false} />
            <TextField
              source="target.type"
              label="檢舉目標類型"
              sortable={false}
            />
            <ReportTargetField label="檢舉目標" sortable={false} />
            <TextField source="content.text" label="原因" sortable={false} />
            <TextField source="status.code" label="狀態" sortable={false} />
            <BooleanField
              source="status.deleted"
              label="刪除"
              sortable={false}
            />
            <DateField showTime source="time.created" label="建立時間" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="events" path="event">
        <ReferenceManyField
          reference="events"
          target="actorId"
          addLabel={false}
        >
          <SimpleList
            primaryText={record => record.name || "n/a"}
            secondaryText={record =>
              JSON.stringify(_.last(record.trace), null, 2)
            }
            tertiaryText={record =>
              new Date(record.time.created).toLocaleString()
            }
          />
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
));

export const UserCreate = props => (
  <Create {...props}>
    <SimpleForm
      defaultValue={{
        password: "kkday88888"
      }}
      redirect="show"
    >
      <TextInput label="email" source="email" type="email" />
      <TextInput label="顯示名稱" source="displayName" />
      <UserPhotoUrlInputField source="photoUrl" label="大頭貼" />
    </SimpleForm>
  </Create>
);

export const UserEdit = props => (
  <Edit {...props}>
    <SimpleForm redirect="show">
      <DisabledInput label="ID" source="id" />
      <DisabledInput label="email" source="email.primary" type="email" />
      <TextInput label="顯示名稱" source="displayName" />
      <UserPhotoUrlInputField source="photoUrl" label="大頭貼" />
    </SimpleForm>
  </Edit>
);
