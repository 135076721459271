import _ from "lodash";
import { stringify } from "query-string";
import { GET_LIST, GET_ONE, GET_MANY, UPDATE, CREATE } from "react-admin";
// import { auth } from "../../firebase";
import { QA_API_ERROR } from "../../util";

const CategoryProvider = opt => {
  const { config, httpClient } = opt;
  if (!config) {
    throw new Error("Missing config");
  }
  if (!httpClient) {
    throw new Error("Missing httpClient");
  }
  const provider = {
    handle(type, params) {
      console.log(type);
      switch (type) {
        case GET_LIST:
          return provider.list(params);
        // case GET_MANY_REFERENCE:
        //   return provider.list(params);
        case GET_ONE:
          return provider.get(params);
        case GET_MANY:
          return provider.getMany(params);
        case CREATE:
          return provider.create(params);
        case UPDATE:
          return provider.update(params);
        // case DELETE:
        //   return provider.delete(params);
        // case DELETE_MANY:
        //   return provider.deleteMany(params);
        default:
          throw new Error(`Unsupported action type: ${type}`);
      }
    },
    get(params) {
      return httpClient
        .get(`/api/categories/${params.id}`)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("KkblogProvider.get Error", result, params);
          }
          // something happen
          // object array convert
          let response = {
            data: result.category
          };
          return response;
        });
    },
    list(params) {
      const query = {
        order: params.sort.field,
        sort: params.sort.order.toLowerCase(),
        limit: params.pagination.perPage
      };
      // filter type
      if (params.filter.type) {
        query.type = params.filter.type;
      }
      // filter Language family
      if (params.filter.lang) {
        query.lang = params.filter.lang;
      }
      // default
      return httpClient
        .get(`/api/categories?${stringify(query)}`)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("KkblogProvider.list Error", result, params);
          }
          result.categories = _.chain(result.categories)
            .orderBy(["lang", "type", "name"], ["desc", "asc", "asc"])
            .value();
          return result;
        })
        .then(result => {
          let response = {
            data: result.categories || null,
            total: result.categories.length || 0
          };
          return response;
        });
    },
    getMany(params) {
      const query = {
        ids: params.ids
      };
      return httpClient
        .get(`/api/categories/many?${stringify(query)}`)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("CatoryProvider.getMany Error", result, params);
          }
          const response = {
            data: result.categories || null
          };
          return response;
        });
    },
    update(params) {
      const payload = {
        name: params.data.name,
        lang: params.data.lang,
        type: params.data.type
      };

      return httpClient
        .put(`/api/categories/${params.id}`, payload)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("CatoryProvider.update Error", result, params);
          }
          const response = {
            data: result.category
          };
          return response;
        });
    },
    create(params) {
      const payload = {
        name: params.data.name,
        lang: params.data.lang,
        type: params.data.type
      };

      return httpClient
        .post(`/api/categories`, payload)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("CatoryProvider.create Error", result, params);
          }
          const response = {
            data: result.category
          };
          return response;
        });
    }
  };
  return provider;
};

export default CategoryProvider;
