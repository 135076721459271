import React from "react";
import _ from "lodash";
import { List, SimpleList } from "../../../components/customize-react-admin";
import Pagination from "../../../components/qa/Pagination";

export const EventList = props => {
  const perPage = 50;
  const defaultSort = {
    field: "time.created",
    order: "DESC"
  };
  return (
    <List
      {...props}
      title="事件列表"
      perPage={perPage}
      sort={defaultSort}
      pagination={<Pagination />}
    >
      <SimpleList
        primaryText={record => record.name || "n/a"}
        secondaryText={record => JSON.stringify(_.last(record.trace), null, 2)}
        tertiaryText={record => new Date(record.time.created).toLocaleString()}
      />
    </List>
  );
};
