import React, { Component } from "react";
import { FunctionField } from "react-admin";
import _ from "lodash";
import { DataSource } from "../../dashboard/util";
import { httpClient } from "../../services/data-provider/guide";
import FlyIcon from "../../assets/images/icon-fly@3x.png";
import GoToIcon from "../../assets/images/circle-icon-goto@3x.png";

const QuotePanel = props => {
  return (
    <div
      style={{
        width: 355,
        boxSizing: "border-box",
        borderRadius: 8,
        backgroundColor: "#1CCDDD",
        boxShadow: "0 2px 4px 0 rgba(15,14,17,0.1)",
        marginBottom: 10,
        padding: "20px 20px 20px 20px"
      }}
    >
      {props.children}
    </div>
  );
};

const FromToRow = props => {
  const fromToTextSyle = {
    color: "#FFFFFF",
    fontFamily: "PingFangTC,sans-serif",
    fontSize: 24,
    fontWeight: 600
  };
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={fromToTextSyle}>{props.from}</div>
      <div>
        <img
          style={{
            width: 24,
            height: 12,
            margin: "5px 15px 0 15px"
          }}
          src={FlyIcon}
          alt="fly logo"
        />
      </div>
      <div style={fromToTextSyle}>{props.to}</div>
      <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
        <a href={props.url} target="_blank" rel="noreferrer noopener">
          <img
            style={{
              width: 30,
              height: 30
            }}
            src={GoToIcon}
            alt="go to logo"
          />
        </a>
      </div>
    </div>
  );
};

const Divider = () => {
  const style = {
    boxSizing: "border-box",
    height: 2,
    border: "1px solid rgba(255,255,255,0.4)",
    margin: "10px 0 15px 0"
  };
  return <div style={style} />;
};

const PriceRow = props => {
  const priceStyle = {
    color: "#FFFFFF",
    fontFamily: "Maven Pro,PingFangTC,sans-serif",
    fontSize: 28,
    fontWeight: 900
  };
  return <div style={priceStyle}>{props.price}</div>;
};

const Quote = props => {
  const { from, to, price, url } = props;
  return (
    <QuotePanel>
      <FromToRow from={from} to={to} url={url} />
      <Divider />
      <PriceRow price={price} />
    </QuotePanel>
  );
};

class FlightQuotesToCity extends Component {
  constructor(props) {
    super(props);
    const FlightQuotesDS = DataSource({
      loadDataFn: cityId => {
        return httpClient
          .get(`/api/thirdparty/flight/quotes/${cityId}`)
          .then(res => res.data)
          .then(result => {
            console.log("FlightQuotesDS result: ", result);
            if (result.status !== "success") {
              throw new Error(
                `Error get flight quotes for city ${cityId} : ${
                  result.error.message
                }`
              );
            }
            return result.quotes;
          });
      }
    });
    this.state = {
      quotes: FlightQuotesDS.get(props.cityId)
    };
    this.FlightQuotesDS = FlightQuotesDS;
  }
  componentDidMount() {
    this.FlightQuotesDS.subscription.add(this.handleChange);
  }
  componentWillUnmount() {
    this.FlightQuotesDS.subscription.remove(this.handleChange);
  }
  handleChange = () => {
    this.setState({
      quotes: this.FlightQuotesDS.get(this.props.cityId)
    });
  };
  render() {
    const quotes = this.state.quotes || [];
    const items = quotes.map((q, idx) => (
      <Quote
        key={idx}
        from={q.from}
        to={q.to}
        price={q.formattedPrice}
        url={q.url}
      />
    ));
    return <div>{items.length ? items : "讀取中"}</div>;
  }
}

const FlightQuotesToCityField = props => {
  console.log("FlightQuotesToCityField props: ", props);
  return (
    <FunctionField
      {...props}
      render={record => {
        const firstCityId = _.chain(record)
          .get("area_data")
          .head()
          .get("city")
          .value();
        return firstCityId && <FlightQuotesToCity cityId={firstCityId} />;
      }}
    />
  );
};

FlightQuotesToCityField.defaultProps = {
  addLabel: true
};

export { FlightQuotesToCityField };
