import React from "react";
import ReactJson from "react-json-view";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  FunctionField,
  Edit,
  SimpleForm,
  DisabledInput,
  TextInput,
  LongTextInput,
  Create,
  required,
  minLength,
  maxLength
} from "react-admin";

const style = {
  info: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "2em",
    minWidth: "8em"
  },
  booleanInfo: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "2em"
  }
};

export const SettingShow = withStyles(style)(({ classes, ...props }) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="summary">
          <TextField source="id" label="ID" />
          <DateField
            showTime
            source="create_date"
            label="建立時間"
            className={classes.info}
          />
          <DateField
            showTime
            source="modify_date"
            label="編輯時間"
            className={classes.info}
          />
          <TextField source="name" label="name" />
          <FunctionField
            label="content"
            style={{
              width: 900,
              overflowX: "scroll"
            }}
            render={record => {
              let content = {};
              try {
                content = JSON.parse(record.content);
              } catch (e) {
                console.log("error parsing setting content string", e.message);
              }
              return (
                <ReactJson
                  src={content}
                  name={false}
                  displayObjectSize={false}
                  displayDataTypes={false}
                  enableClipboard={false}
                  theme="rjv-default"
                  indentWidth={2}
                />
              );
            }}
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
});

const INVALID_JSON = "invalid json";

const validateId = [required(), minLength(2), maxLength(100)];
const validateSettingName = [required(), minLength(2), maxLength(100)];
const validateContent = [
  required(),
  value => {
    try {
      JSON.parse(value);
    } catch (e) {
      return INVALID_JSON;
    }
  }
];

export const SettingEdit = props => (
  <Edit {...props}>
    <SimpleForm redirect="show">
      <DisabledInput label="ID" source="id" />
      <TextInput label="名稱" source="name" validate={validateSettingName} />
      <LongTextInput
        label="內容(in json)"
        source="content"
        validate={validateContent}
      />
    </SimpleForm>
  </Edit>
);

export const SettingCreate = props => (
  <Create {...props}>
    <SimpleForm
      redirect="show"
      defaultValue={{
        id: null,
        name: null,
        content: null
      }}
    >
      <TextInput label="ID" source="id" validate={validateId} />
      <TextInput label="名稱" source="name" validate={validateSettingName} />
      <LongTextInput
        label="內容(in json)"
        source="content"
        validate={validateContent}
      />
    </SimpleForm>
  </Create>
);
