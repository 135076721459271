import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { runSchedule as runScheduleAction } from "./actions";

class RunNowButton extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = () => {
    const { runSchedule, record } = this.props;
    runSchedule(record.id, record);
    // how about push and showNotification?
  };

  render() {
    return (
      <Button variant="contained" color="primary" onClick={this.handleClick}>
        執行
      </Button>
    );
  }
}

RunNowButton.propTypes = {
  runSchedule: PropTypes.func,
  record: PropTypes.object
};

export default connect(
  null,
  {
    runSchedule: runScheduleAction
  }
)(RunNowButton);
