import _ from "lodash";
import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
// import { withStyles } from "@material-ui/core/styles";
import Select from "react-select";

import { httpClient } from "../../services/data-provider/guide";

const categories = categoryType => {
  return httpClient
    .get(`/api/categories`)
    .then(res => res.data)
    .then(result => {
      if (result.status !== "success") {
        throw new Error(
          `Error get latest template blocks: ${result.error.message}`
        );
      }
      return result.categories;
    })
    .then(categories => {
      const newCategories = _.filter(categories, { type: categoryType });
      return _.flatMap(newCategories, item => {
        return {
          value: item.id,
          label: `${item.lang} / ${item.name}`,
          lang: item.lang
        };
      });
    })
    .then(categories => {
      return _.chain(categories)
        .orderBy(["lang", "label"], ["desc", "asc"])
        .value();
    });
};

class CategoryComponentInput extends Component {
  state = {
    categoryList: [],
    categoryType: this.props.categoryType
  };

  componentDidMount() {
    categories(this.state.categoryType).then(categories => {
      this.setState({ categoryList: categories });
    });
  }

  handleChange = selectedOption => {
    const inputVale = [...this.props.input.value]; // make a copy
    const selectValue = selectedOption.value;

    if (selectValue && inputVale.indexOf(selectValue) < 0) {
      inputVale.push(selectValue);
      this.props.input.onChange(inputVale);
    } else {
      console.log("Item duplicated !!");
    }
  };

  handleDelete = idx => {
    const inputVale = [...this.props.input.value]; // make a copy
    inputVale.splice(idx, 1);
    this.props.input.onChange(inputVale);
  };

  render() {
    const selectedOptions = [...this.props.input.value];
    const categoryList = this.state.categoryList;

    return (
      <div style={{ minWidth: "300px" }}>
        <Paper>
          <div>
            {selectedOptions.map((tagValue, idx) => (
              <Chip
                key={idx}
                style={{ margin: "4px", width: "200" }}
                label={_.get(
                  _.find(categoryList, { value: tagValue }),
                  "label"
                )}
                onDelete={() => {
                  this.handleDelete(idx);
                }}
              />
            ))}
          </div>
        </Paper>

        <Select
          onChange={this.handleChange}
          options={categoryList}
          value=""
          label="Category"
        />
      </div>
    );
  }
}

class CategoryComponent extends Component {
  state = {
    categoryList: [],
    categoryType: this.props.categoryType
  };

  componentDidMount() {
    categories(this.state.categoryType).then(categories => {
      this.setState({ categoryList: categories });
    });
  }

  render() {
    const selectedOptions = this.props.value || [];

    return (
      <div style={{ minWidth: "300px" }}>
        {selectedOptions.map((tagValue, idx) => (
          <Chip
            key={idx}
            style={{ margin: "4px", width: "200" }}
            label={_.get(
              _.find(this.state.categoryList, { value: tagValue.category }),
              "label"
            )}
          />
        ))}
      </div>
    );
  }
}

export { CategoryComponent, CategoryComponentInput };
