import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import { Field } from "redux-form";
import { Link } from "react-admin";

class KKdayPostLoadButtonComponet extends Component {
  constructor(props) {
    super(props);
    this.state = { toId: "", lang: props.lang };
  }

  handleChange = blogIdInput => {
    const blogIdValue = blogIdInput.target.value;
    this.setState({ toId: blogIdValue });
  };

  render() {
    const lang = this.state.lang ? this.state.lang : "";
    const tmpFix = lang === "zh-tw" || lang === "" ? "" : `-${lang}`;
    const toUrl = `/kkblogs${tmpFix}/${this.state.toId}/show`;
    return (
      <div style={{ minWidth: "300px" }}>
        <input onChange={this.handleChange} value={this.state.toId} />
        <Paper>
          <Link to={toUrl}>
            {this.state.toId ? "get Post: " : ""} {this.state.toId}
          </Link>
        </Paper>
      </div>
    );
  }
}

const KKdayPostLoadButton = props => (
  <Field
    name={props.source}
    component={KKdayPostLoadButtonComponet}
    label={props.label}
    lang={props.lang}
  />
);
KKdayPostLoadButton.defaultProps = {
  addLabel: true
};

export { KKdayPostLoadButton };
