import React from "react";
import { Resource } from "react-admin";
import { AdminList } from "./admins";
import { AdminShow, AdminEdit, AdminCreate } from "./admin";
import AdminIcon from "@material-ui/icons/SupervisorAccount";

const AdminResource = props => {
  return (
    <Resource
      {...props}
      list={AdminList}
      show={AdminShow}
      edit={AdminEdit}
      create={AdminCreate}
      icon={AdminIcon}
    />
  );
};

export default AdminResource;
