import React from "react";
import * as _ from "lodash";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import { Field } from "redux-form";
import { Labeled } from "react-admin";
import { convertFileToBase64, uploadImage } from "../../services/image";

class FileInput extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.fileInput = React.createRef();
  }
  handleChange() {
    const file = this.fileInput.current.files[0];
    this.props.onFileChanged(file);
  }
  render() {
    return (
      <input type="file" ref={this.fileInput} onChange={this.handleChange} />
    );
  }
}

class FileImageInput extends React.Component {
  constructor(props) {
    super(props);
    this.clearImage = this.clearImage.bind(this);
    this.onFileChanged = this.onFileChanged.bind(this);
    this.onTextFieldChanged = this.onTextFieldChanged.bind(this);
  }
  onFileChanged(file) {
    this.props.onChange("請稍候", this.props.index);
    convertFileToBase64(file)
      .then(uploadImage())
      .then(url => {
        this.props.onChange(url, this.props.index);
      });
  }
  onTextFieldChanged(event) {
    const newVal = event.target.value;
    this.props.onChange(newVal, this.props.index);
  }
  clearImage() {
    this.props.onChange("", this.props.index);
  }
  render() {
    return (
      <Card
        style={{
          maxWidth: 250,
          display: "inline-block",
          marginRight: 10,
          marginBottom: 10
        }}
      >
        <CardHeader
          subheader={this.props.title}
          action={
            this.props.image.length ? (
              <Button color="secondary" onClick={this.clearImage}>
                刪除
              </Button>
            ) : null
          }
        />
        {this.props.image ? (
          <CardMedia
            image={this.props.image}
            style={{
              height: 0,
              paddingTop: "56.25%"
            }}
          />
        ) : null}
        <CardContent>
          <TextField
            fullWidth={true}
            label="url"
            value={this.props.image}
            onChange={this.onTextFieldChanged}
          />
        </CardContent>
        <CardActions>
          <FileInput onFileChanged={this.onFileChanged} />
        </CardActions>
      </Card>
    );
  }
}

FileImageInput.defaultProps = {
  image: "",
  title: "",
  index: 0,
  onChange: () => {}
};

class ContentImagesInput extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(newImageUrl, index) {
    const {
      input: { value, onChange }
    } = this.props;
    const newVal = Object.assign({}, value);
    newVal[index].content = newImageUrl;
    onChange(newVal);
  }
  render() {
    const style = {
      display: "flex"
    };
    const { input } = this.props;
    const inputs = Object.values(input.value).map((model, index) => {
      return (
        <FileImageInput
          key={index}
          image={model.content}
          title={`圖片${index + 1}`}
          index={index}
          onChange={this.handleChange}
        />
      );
    });
    return (
      <Labeled label={this.props.label}>
        <div style={{ style }}>{inputs}</div>
      </Labeled>
    );
  }
}

ContentImagesInput.defaultProps = {
  index: 0,
  onChange: () => {}
};

const ContentImagesInputField = props => (
  <Field
    name={props.source}
    component={ContentImagesInput}
    label={props.label}
    format={(images = []) => {
      const dictImages = {};
      let notNullImages = [];
      if (images) {
        notNullImages = images;
      }
      notNullImages.forEach((image, index) => (dictImages[index] = image));
      for (let i = notNullImages.length; i < 5; i++) {
        dictImages[i] = {
          content: "",
          type: "url"
        };
        break;
      }
      return dictImages;
    }}
    parse={dictImages => {
      const parsed = Object.values(dictImages).filter(
        i => !_.isEmpty(i.content)
      );
      return parsed;
    }}
  />
);

ContentImagesInputField.defaultProps = {
  source: "content.images",
  label: "相關圖片"
};

const CoverImageInputField = props => (
  <Field
    name={props.source}
    component={ContentImagesInput}
    label={props.label}
    format={(images = {}) => {
      const emptyImage = {
        content: "",
        type: "url"
      };
      return _.isEmpty(images.content) ? [emptyImage] : [images];
    }}
    parse={dictImages => {
      const parsed = Object.values(dictImages).filter(
        i => !_.isEmpty(i.content)
      );
      return parsed[0] || {};
    }}
  />
);

CoverImageInputField.defaultProps = {
  source: "info.coverImage",
  label: "Cover Image"
};

class UserPhotoUrlInput extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(newImageUrl) {
    const {
      input: { onChange }
    } = this.props;
    onChange(newImageUrl);
  }
  render() {
    return (
      <Labeled label={this.props.label}>
        <FileImageInput
          image={this.props.input.value}
          title={this.props.title}
          onChange={this.handleChange}
        />
      </Labeled>
    );
  }
}

UserPhotoUrlInput.defaultProps = {
  title: "",
  onChange: () => {}
};

const UserPhotoUrlInputField = props => (
  <Field
    name={props.source}
    component={UserPhotoUrlInput}
    label={props.label}
  />
);

UserPhotoUrlInputField.defaultProps = {
  source: "content.images",
  label: "大頭貼"
};

export {
  FileImageInput,
  ContentImagesInputField,
  UserPhotoUrlInputField,
  CoverImageInputField
};
