import Raven from "raven-js";
import { version } from "../../../../package.json";

const initRaven = () => {
  return Raven.config(
    "https://11659beff8a44992bdf41ff2dd1d6f5a@sentry.io/1267292",
    {
      name: "guide admin",
      release: version,
      environment: process.env.REACT_APP_ENV
    }
  ).install();
};

const client = initRaven();

export default client;
