import React from "react";
import { Datagrid, TextField, DateField, Link } from "react-admin";
import { List } from "../../../components/customize-react-admin";
import Pagination from "../../../components/qa/Pagination";

const IDLinkField = props => (
  <Link to={`/guide-settings/${props.record.id}/show`}>
    <TextField {...props} />
  </Link>
);

export const SettingList = props => {
  const perPage = 50;
  const defaultSort = {
    field: "create_date",
    order: "DESC"
  };
  return (
    <List
      {...props}
      title="Guide Settings 列表"
      perPage={perPage}
      sort={defaultSort}
      pagination={<Pagination />}
    >
      <Datagrid>
        <IDLinkField source="id" label="ID" sortable={false} />
        <TextField source="name" label="名稱" />
        <DateField showTime source="create_date" label="建立時間" />
        <DateField showTime source="modify_date" label="編輯時間" />
      </Datagrid>
    </List>
  );
};
