import { stringify } from "query-string";
import { GET_LIST, GET_ONE, GET_MANY, GET_MANY_REFERENCE } from "react-admin";
import { QA_API_ERROR } from "../../util";

const EventProvider = opt => {
  const { config, httpClient } = opt;
  if (!config) {
    throw new Error("Missing config");
  }
  if (!httpClient) {
    throw new Error("Missing httpClient");
  }
  const provider = {
    handle(type, params) {
      switch (type) {
        case GET_LIST:
          return provider.list(params);
        case GET_ONE:
          return provider.get(params);
        case GET_MANY:
          return provider.getMany(params);
        case GET_MANY_REFERENCE:
          return provider.list(params);
        default:
          throw new Error(`Unsupported action type: ${type}`);
      }
    },
    get(params) {
      return httpClient
        .get(`/api/event/${params.id}`)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("EventProvider.get Error", result, params);
          }
          let response = {
            data: result.event
          };
          return response;
        });
    },
    getMany(params) {
      const query = {
        ids: params.ids
      };
      return httpClient
        .get(`/api/events?${stringify(query)}`)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("EventProvider.getMany Error", result, params);
          }
          let response = {
            data: result.events || null,
            total: 1000
          };
          return response;
        });
    },
    list(params) {
      const query = {
        order: params.sort.field,
        sort: params.sort.order.toLowerCase(),
        limit: params.pagination.perPage
      };
      // target
      if (params.target === "actorId") {
        query.actorId = params.id;
      }
      // pagination
      if (typeof params.pagination.page === "string") {
        const [pageNumber, afterOrBefore, id] = params.pagination.page.split(
          "_"
        );
        if (afterOrBefore === "after") {
          query.after = id;
        } else if (afterOrBefore === "before") {
          query.before = id;
        }
        console.log("pageNumber: ", pageNumber);
      }
      return httpClient
        .get(`/api/events?${stringify(query)}`)
        .then(res => res.data)
        .then(result => {
          if (result.status !== "success") {
            throw QA_API_ERROR("EventProvider.list Error", result, params);
          }
          let response = {
            data: result.events || null,
            total: 1000
          };
          return response;
        });
    }
  };
  return provider;
};

export default EventProvider;
