import React from "react";
import { FunctionField, SelectInput } from "react-admin";

const LAYOUT_OPTIONS = [
  { id: 0, name: "0 - Invisible" },
  { id: 1, name: "1 - Large image" },
  { id: 2, name: "2 - Small image" },
  { id: 3, name: "3 - Card" },
  { id: 4, name: "4 - List" }
];

const LayoutOptionsMap = LAYOUT_OPTIONS.reduce((accu, cur) => {
  accu[cur.id] = cur;
  return accu;
}, {});

const TemplateBlockLayoutField = props => {
  return (
    <FunctionField
      {...props}
      label="Layout"
      render={record => {
        return (
          (record.layout &&
            LayoutOptionsMap[record.layout] &&
            LayoutOptionsMap[record.layout].name) ||
          (record.layout || "")
        );
      }}
    />
  );
};

TemplateBlockLayoutField.defaultProps = {
  addLabel: true,
  label: "Layout"
};

const TemplateBlockLayoutInputField = props => {
  return <SelectInput {...props} choices={LAYOUT_OPTIONS} />;
};

TemplateBlockLayoutInputField.defaultProps = {
  addLabel: false,
  source: "layout",
  label: "Layout"
};

export { TemplateBlockLayoutField, TemplateBlockLayoutInputField };
