import React from "react";
import { Resource } from "react-admin";
import { EventShow } from "./event";
import { EventList } from "./events";
import TimelineIcon from "@material-ui/icons/Timeline";

const EventResource = props => {
  return (
    <Resource
      {...props}
      list={EventList}
      show={EventShow}
      edit={EventShow}
      icon={TimelineIcon}
    />
  );
};

export default EventResource;
