import React from "react";
import {
  Datagrid,
  TextField,
  Link,
  BooleanField,
  Filter,
  TextInput,
  DateField
} from "react-admin";
import { List } from "../../../components/customize-react-admin";
import Pagination from "../../../components/qa/Pagination";
import {
  PostStatusField,
  PostStatusInput
} from "../../../components/guide/PostStatus";
import { CountryCitySelectInput } from "../../../components/guide/CountryCitySelect";
import {
  LanguageFamilyField,
  LanguageFamilyInput
} from "../../../components/guide/LanguageFamily";

const IDLinkField = props => (
  <Link to={`/videos/${props.record.id}`}>
    <TextField {...props} />
  </Link>
);

const VideoFilter = props => (
  <Filter {...props}>
    <TextInput label="Search Title" source="q" alwaysOn />
    <LanguageFamilyInput source="lang" label="Language family" alwaysOn />
    <PostStatusInput source="post_status" label="Status" />
    <CountryCitySelectInput source="area_data" label="City" />
  </Filter>
);

export const VideoList = props => {
  const perPage = 50;
  const defaultSort = {
    field: "begin_date",
    order: "DESC"
  };
  return (
    <List
      {...props}
      title="Videos list"
      perPage={perPage}
      sort={defaultSort}
      pagination={<Pagination />}
      filters={<VideoFilter />}
    >
      <Datagrid>
        <IDLinkField source="title" label="Title" sortable={false} />
        <LanguageFamilyField source="lang" label="Language family" />
        <PostStatusField source="post_status" label="Status" />
        <DateField
          showTime
          source="begin_date"
          label="Start Date"
          sortable={false}
        />
        <BooleanField
          source="visibility.guide"
          label="Guide"
          sortable={false}
        />
        <BooleanField
          source="visibility.kkday"
          label="Search"
          sortable={false}
        />
        <BooleanField
          source="visibility.kkhome"
          label="Home"
          sortable={false}
        />
        <BooleanField
          source="visibility.kkorder"
          label="Order"
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};
