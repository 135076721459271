import debounce from "lodash/debounce";
import React, { Component } from "react";
import PropTypes from "prop-types";
import Quill from "quill";
import { addField } from "react-admin";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import { httpClient } from "../../services/data-provider/guide";
import _ from "lodash";

import styles from "./styles";

const convertContentToString = setting => {
  const clone = Object.assign({}, setting);
  clone.content = JSON.stringify(setting.content);
  return clone;
};

const processSetting = setting => {
  return convertContentToString(setting);
};

export class RichTextInput extends Component {
  static propTypes = {
    addLabel: PropTypes.bool.isRequired,
    classes: PropTypes.object,
    input: PropTypes.object,
    label: PropTypes.string,
    meta: PropTypes.object,
    options: PropTypes.object,
    source: PropTypes.string,
    toolbar: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    fullWidth: PropTypes.bool
  };

  static defaultProps = {
    addLabel: true,
    options: {},
    record: {},
    toolbar: true,
    fullWidth: true
  };

  componentDidMount() {
    const {
      input: { value },
      toolbar
    } = this.props;

    httpClient
      .get(`/api/settings/QuillColors`)
      .then(res => res.data)
      .then(result => {
        if (result.status !== "success") {
          return { color: [] };
        }
        return JSON.parse(processSetting(result.setting).content);
      })
      .then(seetingColors => {
        if (_.size(seetingColors.color) > 0) {
          _.forEach(toolbar, item => {
            const indexColor = _.findIndex(item, "color");
            if (indexColor > -1) {
              item[indexColor] = seetingColors;
            }
          });
        }

        this.quill = new Quill(this.divRef, {
          modules: {
            toolbar: {
              container: toolbar,
              handlers: {
                image: this.imageHandler
              }
            }
          },
          theme: "snow"
        });

        this.quill.setContents(this.quill.clipboard.convert(value));
        this.editor = this.divRef.querySelector(".ql-editor");
        this.quill.on("text-change", debounce(this.onTextChange, 500));
      });
  }

  componentWillUnmount() {
    this.quill.off("text-change", this.onTextChange);
    this.quill = null;
  }

  onTextChange = () => {
    const value =
      this.editor.innerHTML === "<p><br></p>" ? "" : this.editor.innerHTML;
    this.props.input.onChange(value);
  };

  updateDivRef = ref => {
    this.divRef = ref;
  };

  imageHandler = () => {
    var range = this.quill.getSelection();
    var value = prompt("image URL");
    if (value) {
      this.quill.insertEmbed(range.index, "image", value, Quill.sources.USER);
    }
  };

  render() {
    const { error, helperText = false } = this.props.meta;
    return (
      <FormControl
        error={error}
        fullWidth={false}
        className="ra-rich-text-input"
      >
        <div ref={this.updateDivRef} />
        {error && <FormHelperText>{error}</FormHelperText>}
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  }
}

const RichRextInputWithField = addField(withStyles(styles)(RichTextInput));

RichRextInputWithField.defaultProps = {
  addLabel: true,
  fullWidth: true
};
export default RichRextInputWithField;
