import React from "react";
import { stringify } from "query-string";
import timeago from "timeago.js";
import compose from "recompose/compose";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import RefreshIcon from "@material-ui/icons/Refresh";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { translate } from "react-admin";
import { UserPhotoDisplayNameField } from "../../components/qa/UserFields";
import { httpClient } from "../../services/data-provider/qa";
import { DataSource } from "../util";

const LatestReportsDS = DataSource({
  loadDataFn: () => {
    const query = {
      limit: 5,
      order: "time.created",
      sort: "DESC"
    };
    return httpClient
      .get(`/api/reports?${stringify(query)}`)
      .then(res => res.data)
      .then(result => {
        if (result.status !== "success") {
          throw new Error(`Error get latest reports: ${result.error.message}`);
        }
        return result.reports;
      });
  }
});

const style = theme => ({
  root: {
    flex: 1
  },
  date: {
    marginRight: "1em",
    color: theme.palette.text.primary
  },
  board: {
    marginRight: "1em",
    textAlign: "right"
  }
});

const timeagoInstance = timeago();

const LatestReports = ({ reports, translate, classes }) => {
  let listItems = (
    <ListItem key="loading">
      <ListItemText primary="讀取中" />
    </ListItem>
  );
  if (reports) {
    listItems = reports.map(report => (
      <ListItem
        key={report.id}
        button
        component={Link}
        to={`/reports/${report.id}/show`}
      >
        <ListItemText
          disableTypography={true}
          primary={
            <Typography variant="subheading">{report.content.text}</Typography>
          }
          secondary={
            <UserPhotoDisplayNameField record={report.reporter} size={30} />
          }
        />
        <ListItemSecondaryAction>
          <span className={classes.date}>
            {timeagoInstance.format(report.time.created, "zh_TW")}
          </span>
        </ListItemSecondaryAction>
      </ListItem>
    ));
  }
  return (
    <Card className={classes.root}>
      <CardHeader
        title={translate("qaAdmin.dashboard.LatestReports")}
        action={
          <IconButton>
            <RefreshIcon
              onClick={() => {
                LatestReportsDS.refresh();
              }}
            />
          </IconButton>
        }
      />
      <List dense={true}>{listItems}</List>
    </Card>
  );
};

const enhance = compose(
  withStyles(style),
  translate
);

const EnhancedLatestReports = enhance(LatestReports);

class LatestReportsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      reports: LatestReportsDS.get()
    };
  }
  componentDidMount() {
    LatestReportsDS.subscription.add(this.handleChange);
  }
  componentWillUnmount() {
    LatestReportsDS.subscription.remove(this.handleChange);
  }
  handleChange() {
    this.setState({
      reports: LatestReportsDS.get()
    });
  }
  render() {
    return <EnhancedLatestReports reports={this.state.reports} />;
  }
}

export default LatestReportsComponent;
