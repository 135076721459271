import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  Create,
  ImageField,
  SimpleForm
} from "react-admin";
import { UserPhotoUrlInputField } from "../../../components/qa/ImagesInputField";
import {
  ActiveStatusField,
  ActiveStatusInput
} from "../../../components/guide/ActiveStatus";

const style = {
  info: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "2em",
    minWidth: "8em"
  },
  booleanInfo: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "2em"
  },
  width100pa: {
    width: "100%"
  }
};

export const CouponShow = withStyles(style)(({ classes, ...props }) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="id" label="ID" />
        <TextField source="name" label="Name" />
        <TextField source="website" label="Website" />
        <ActiveStatusField source="status.active" label="Active" />
        <ImageField source="logo" label="Logo image" />
        <DateField
          source="created"
          label="Create date"
          className={classes.info}
          showTime
        />
        <DateField
          source="updated"
          label="Last Modify"
          className={classes.info}
          showTime
        />
      </Tab>
    </TabbedShowLayout>
  </Show>
));

const validateCouponEdit = values => {
  const errors = { status: {} };
  if (!values.name) {
    errors.name = ["This is required"];
  }
  if (!values.status || !values.status.active) {
    errors.status.active = ["This is required active"];
  }
  // publish check
  if (values.status && values.status.active === true) {
    if (!values.logo) {
      errors.status.active = ["Coupon image required"];
    }
  }
  return errors;
};
export const CouponEdit = withStyles(style)(({ classes, ...props }) => (
  <Edit {...props}>
    <TabbedForm
      redirect="show"
      submitOnEnter={false}
      validate={validateCouponEdit}
    >
      <FormTab label="summary">
        <TextField source="id" label="ID" />
        <TextInput source="name" label="Name" />
        <TextInput source="website" label="Website" />
        <ActiveStatusInput source="status.active" label="Active" />
        <UserPhotoUrlInputField source="logo" label="Logo Image" />
      </FormTab>
    </TabbedForm>
  </Edit>
));

const createStyle = {
  inline: {
    display: "inline-block"
    // verticalAlign: "top",
    // marginRight: "2em",
    // minWidth: "8em"
  },
  booleanInfo: {
    display: "inline-block"
    // verticalAlign: "top",
    // marginRight: "2em"
  }
};
const validateCouponCreate = values => {
  const errors = { status: {} };
  if (!values.name) {
    errors.name = ["This is required"];
  }
  if (!values.status || !values.status.active) {
    errors.status.active = ["This is required active"];
  }
  // publish check
  if (values.status && values.status.active === true) {
    if (!values.logo) {
      errors.status.active = ["Coupon image required"];
    }
  }
  return errors;
};
export const CouponCreate = withStyles(createStyle)(({ classes, ...props }) => {
  return (
    <Create {...props}>
      <SimpleForm
        redirect="show"
        submitOnEnter={false}
        validate={validateCouponCreate}
      >
        <TextInput source="name" label="Name" />
        <TextInput source="website" label="Website" />
        <ActiveStatusInput source="status.active" label="Active" />
        <UserPhotoUrlInputField source="logo" label="Logo Image" />
      </SimpleForm>
    </Create>
  );
});
