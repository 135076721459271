import React, { Component } from "react";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {
  ViewTitle,
  SimpleForm,
  TextInput,
  required,
  minLength,
  maxLength,
  showNotification
} from "react-admin";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import { httpClient } from "../../services/data-provider/qa";
import { auth } from "../../services/firebase";

const validatePassword = [required(), minLength(5), maxLength(100)];

const validatePasswordChange = values => {
  const errors = {};
  if (!values.newPassword) {
    errors.newPassword = ["新密碼 is required"];
  }
  if (!values.newPasswordAgain) {
    errors.newPasswordAgain = ["新密碼(再一次) is required"];
  }
  if (values.newPassword !== values.newPasswordAgain) {
    errors.newPasswordAgain = ["新密碼(再一次) 不一致"];
  }
  return errors;
};

const ChangePasswordForm = props => (
  <Card>
    <ViewTitle title="變更密碼" />
    <CardContent>
      <SimpleForm
        validate={validatePasswordChange}
        save={props.save}
        saving={props.saving}
        submitOnEnter={false}
      >
        <TextInput
          label="新密碼"
          source="newPassword"
          type="password"
          validate={validatePassword}
        />
        <TextInput
          label="新密碼(再一次)"
          source="newPasswordAgain"
          type="password"
          validate={validatePassword}
        />
      </SimpleForm>
    </CardContent>
  </Card>
);

ChangePasswordForm.propTypes = {
  save: PropTypes.func,
  saving: PropTypes.bool
};

class ChangePasswordComponent extends Component {
  constructor(props) {
    super(props);
    this.save = this.save.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.state = {
      saving: false
    };
  }
  changePassword(newPassword) {
    return auth.currentUser
      .getIdToken(false)
      .then(token => {
        const payload = {
          token,
          password: newPassword
        };
        return httpClient.post("/api/admin/admin/me/password", payload);
      })
      .then(res => res.data)
      .then(result => {
        if (result.status !== "success") {
          throw new Error(
            `Error change admin password: ${result.error.message}`
          );
        }
        return true;
      });
  }
  save(value) {
    const { push, showNotification } = this.props;
    this.setState({ saving: true });
    showNotification("saving");
    this.changePassword(value.newPassword)
      .then(() => {
        this.setState({ saving: false });
        showNotification("password updated!");
      })
      .then(() => {
        push("/");
      })
      .catch(error => {
        this.setState({ saving: false });
        console.log("change password error: ", error);
        showNotification("Error: ", error.message);
      });
  }
  render() {
    return <ChangePasswordForm save={this.save} saving={this.state.saving} />;
  }
}

ChangePasswordComponent.propTypes = {
  push: PropTypes.func,
  showNotification: PropTypes.func
};

export default connect(
  null,
  {
    showNotification,
    push
  }
)(ChangePasswordComponent);
